<div class="c-card">
    <div class="c-card__header">
        @if (type === 'patient') {
            <div class="c-card__ribbon" class="flex end">
                <div class="c-card__item mb-2">
                    <div
                        *ngIf="{
                            getPatientAccessRequestId: getPatientAccessRequestId$ | async,
                            inviteToPatientPortal: inviteToPatientPortal$ | async,
                            cancelInvitation: cancelInvitation$ | async,
                            hasActiveAccessRequest: hasActiveAccessRequest$ | async,
                            transferPatientToAnotherSite: transferToAnotherSite$ | async,
                            isPatientInPatientPortal: isPatientInPatientPortal$ | async,
                            transferToAnotherSitePredicate: transferToAnotherSitePredicate$ | async,
                            canInviteToPatientPortal: canInviteToPatientPortal$ | async,
                            isPatientExistsInClinTrak: isPatientExistsInClinTrak$ | async,
                        } as vm"
                        [ngClass]="
                            vm.isPatientInPatientPortal || vm.isPatientExistsInClinTrak
                                ? 'flex space-between'
                                : 'flex end'
                        "
                    >
                        <div id="chips" class="chips">
                            @if (vm.isPatientInPatientPortal) {
                                <div id="pp-chip">
                                    <mds-chip [chips]="[patientPortalChip]" chipListType="basic"></mds-chip>
                                </div>
                            }
                            @if (vm.isPatientExistsInClinTrak) {
                                <div id="clintrak-chip" [class]="vm.isPatientInPatientPortal ? 'clintrak-chip' : ''">
                                    <mds-chip [chips]="[clinTrakChip]" chipListType="basic"></mds-chip>
                                </div>
                            }
                        </div>

                        <div class="buttons">
                            <ng-container *superAdmin>
                                <mds-button
                                    *ngIf="vm.transferToAnotherSitePredicate"
                                    [ngClass]="{
                                        disabled: patientData?.statusId === AllPatientStatusesEnum.Removed,
                                    }"
                                    (clickEvent)="transferToAnotherSite()"
                                    label="Transfer to another site"
                                    id="transferToAnotherSiteButton"
                                    [variant]="'outline'"
                                ></mds-button>
                            </ng-container>
                            <ng-container>
                                @if (!vm.isPatientInPatientPortal) {
                                    <mds-button
                                        *ngIf="vm.canInviteToPatientPortal && !vm.hasActiveAccessRequest"
                                        [ngClass]="{
                                            disabled: patientData?.statusId === AllPatientStatusesEnum.Removed,
                                        }"
                                        (clickEvent)="inviteToPatientPortalClick()"
                                        label="Invite to PatientPACE"
                                        [variant]="'outline'"
                                    ></mds-button>
                                }
                            </ng-container>
                            <mds-button
                                *ngIf="vm.hasActiveAccessRequest"
                                [ngClass]="{ disabled: patientData?.statusId === AllPatientStatusesEnum.Removed }"
                                (clickEvent)="cancelInvitation()"
                                label="Cancel PatientPACE Invitation"
                                [variant]="'primary'"
                                [color]="'warn'"
                            ></mds-button>
                            <ng-container [ngTemplateOutlet]="info_btn" />
                        </div>
                    </div>
                </div>
            </div>
            @if (!!patientData) {
                <div class="flex end">
                    <div class="c-card__item mb-2">
                        <div class="sub-header" style="display: flex; flex-direction: row">
                            <div
                                class="sub-header-item"
                                [matTooltip]="
                                    showPatientDetailsTooltip(patientData) && patientData.sponsor.length > 50
                                        ? patientData.sponsor
                                        : ''
                                "
                            >
                                {{ getStudyDetailsTitle(patientData, patientData.sponsor) }}
                            </div>
                            <div
                                class="sub-header-item"
                                [matTooltip]="
                                    showPatientDetailsTooltip(patientData) && patientData.protocol.length > 50
                                        ? patientData.protocol
                                        : ''
                                "
                            >
                                {{ getStudyDetailsTitle(patientData, patientData.protocol) }}
                            </div>
                            <div
                                class="sub-header-item"
                                [matTooltip]="
                                    showPatientDetailsTooltip(patientData) && patientData.siteNumber.length > 50
                                        ? patientData.siteNumber
                                        : ''
                                "
                            >
                                {{ getStudyDetailsTitle(patientData, patientData.siteNumber) }}
                            </div>
                        </div>
                    </div>
                </div>
            }
        } @else {
            <div class="c-card__ribbon" class="flex end">
                <div class="c-card__item mb-2">
                    <div class="flex end">
                        @if (type === 'site') {
                            <mds-button
                                *ngIf="hasAccessToDetail()"
                                (clickEvent)="inviteCRCUserClick()"
                                label="Invite CRC User"
                                [variant]="'outline'"
                                [disabled]="!inviteCRCButtonVisibility"
                                [matTooltip]="siteTooltipText"
                            ></mds-button>
                        }
                        <ng-container [ngTemplateOutlet]="info_btn" />
                    </div>
                </div>
            </div>
        }
    </div>
    <div class="c-card__content">
        <div *ngIf="!!data; else spinner">
            <div class="c-info" *ngIf="type === 'study'">
                <div class="c-info__item c-info__item--image">
                    <img *ngIf="url" [src]="url" alt="" class="image-placement" />
                </div>
                <div class="c-info__item {{ type }}" *ngFor="let x of contentHeaders">
                    <div class="c-info__block">
                        <h5>{{ x }}</h5>
                        <span>{{ mapFields(x) }}</span>
                    </div>
                </div>
            </div>
            <div class="c-info" *ngIf="type === 'site'">
                <div
                    class="c-info__item {{ getLowerCaseNameWithoutSpacesAndSpecialCharacters(x) }}"
                    *ngFor="let x of contentHeaders"
                >
                    <div class="c-info__block">
                        <h5>{{ x }}</h5>
                        <span>{{ mapFields(x) }}</span>
                    </div>
                </div>

                <div class="c-info__item {{ type + '-primarycrc' }}">
                    <div class="c-info__block">
                        <h5>ACTIVE PRIMARY CRC</h5>
                        <span>{{ additionalContent }}</span>
                    </div>
                </div>
            </div>
            <div class="c-info" *ngIf="type === 'patient'">
                <div *ngIf="patientData.statusName" class="c-info__item patient-status">
                    <div class="c-info__block">
                        <h5>Status</h5>
                        <span>{{ patientData.statusName }}</span>
                    </div>
                </div>
                <div class="c-info__item patient-patientid">
                    <div class="c-info__block">
                        <h5>Patient Id</h5>
                        <span>{{ patientData.patientIdentification.patientId }}</span>
                    </div>
                </div>
                <div class="c-info__item patient-patientid">
                    <div class="c-info__block">
                        <h5>PCS ID</h5>
                        <span>{{ patientData.studyCode }}-{{ patientData.id }}</span>
                    </div>
                </div>
                <div class="c-info__item">
                    <div class="c-info__block">
                        <h5>Patient Name</h5>
                        <span *ngIf="patientData.statusId !== AllPatientStatusesEnum.Removed"
                            >{{ patientData.patientIdentification.firstName }}&nbsp;{{
                                patientData.patientIdentification.lastName
                            }}</span
                        >
                        <span *ngIf="patientData.statusId === AllPatientStatusesEnum.Removed">Removed</span>
                    </div>
                </div>
                <div class="c-info__item patient-country">
                    <div class="c-info__block">
                        <h5>Country</h5>
                        <span>{{ patientData.country }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #spinner>
        <div class="spinnerOverlay">
            <medpace-loading-card></medpace-loading-card>
        </div>
    </ng-template>
</div>

<ng-template #info_btn>
    <mds-button
        *ngIf="type !== 'patient' && isAdmin"
        [id]="'clintrakDataBtn'"
        (clickEvent)="openDialog()"
        label="ClinTrak data"
        [variant]="'outline'"
    ></mds-button>
    <mds-button
        *ngIf="hasAccessToDetail()"
        [id]="'additionalInfoBtn'"
        (clickEvent)="clickNavButton()"
        label="{{ inputCTA }}"
        [variant]="'outline'"
    ></mds-button>
</ng-template>
