import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MedpaceDeletePatientModalComponent } from '@app/admin/manage/modals/delete-patient-modal.component';
import { MedpaceDataRedirectorComponent } from '@components/atoms/data-redirector/data-redirector.component';
import { HistoryTimelineComponent } from '@components/atoms/history-timeline/history-timeline.component';
import { AccessRequestActionsCardComponent } from '@components/molecules/access-request-actions-card/access-request-actions-card.component';
import { CreateAccessRequestCardComponent } from '@components/molecules/create-access-request-card/create-access-request-card.component';
import { MedpaceCaregiverInfoCardComponent } from '@components/molecules/forms/caregiver-info-card/caregiver-info-card.component';
import { MedpaceCaregiverInfoDialogComponent } from '@components/molecules/forms/caregiver-info-card/caregiver-info-dialog/caregiver-info-dialog.component';
import { StudySitesCardComponent } from '@components/molecules/forms/study-sites-card/study-sites-card.component';
import { MedpaceMessageModalComponent } from '@components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { GlobalContactNumbersComponent } from '@components/organisms/global-contact-numbers/global-contact-numbers.component';
import { AccessGroupEnum } from '@models/accessGroup';
import { AdminDashboardComponent } from './admin/dashboard/admin-dashboard.component';
import { MedpaceAdditionalDetailsModalComponent } from './admin/manage/modals/additional-details-modal.component';
import { MedpaceDeleteRequestModalComponent } from './admin/manage/modals/delete-request-modal.component';
import { MedpacePatientTravelAirlineModalComponent } from './admin/manage/modals/patient-travel/patient-travel-airline-modal/patient-travel-airline-modal.component';
import { MedpacePatientTravelGroundModalComponent } from './admin/manage/modals/patient-travel/patient-travel-ground-modal/patient-travel-ground-modal.component';
import { MedpacePatientTravelLodgingModalComponent } from './admin/manage/modals/patient-travel/patient-travel-lodging-modal/patient-travel-lodging-modal.component';
import { MedpacePatientTravelRentalCarModalComponent } from './admin/manage/modals/patient-travel/patient-travel-rental-car-modal/patient-travel-rental-car-modal.component';
import { MedpacePatientTravelTrainModalComponent } from './admin/manage/modals/patient-travel/patient-travel-train-modal/patient-travel-train-modal.component';
import { MedpaceRequestChangeModalComponent } from './admin/manage/modals/request-change-modal.component';
import { MedpaceRequestErrorModalComponent } from './admin/manage/modals/request-error-modal.component';
import { MedpaceUserManagementModalComponent } from './admin/manage/modals/user-management/user-management-modal.component';
import { MedpaceViewHistoryModalComponent } from './admin/manage/modals/view-history-modal.component';
import { MedpaceSuperAdminDashboardComponent } from './admin/manage/super-admin-dashboard.component';
import { PatientTravelPreferenceBaseComponent } from './base-classes/patient-travel-preference-base.component';
import { MedpacePatientTravelPreferencesModalBaseComponent } from './base-classes/patient-travel-preferences-modal-base.component';
import { MedpaceLogoUploadComponent } from './components/atoms/logo-upload/logo-upload.component';
import {
    MedpaceCardComponent,
    MedpaceCardContentDirective,
    MedpaceCardHeaderDirective,
} from './components/atoms/medpace-card/medpace-card.component';
import { SelectedCustomDatePickerComponent } from './components/atoms/selected/selected-custom-datepicker.component';
import { SelectedCustomDropDownComponent } from './components/atoms/selected/selected-custom-dropdown.component';
import { SelectedCustomComponent } from './components/atoms/selected/selected-custom.component';
import { CRCInviteCardComponent } from './components/molecules/forms/crc-invite-card/crc-invite-card.component';
import { MedpacePatientAccessiblityCardComponent } from './components/molecules/forms/patient-accessibility-card/accessibility-card.component';
import { MedpacePatientConsentCardComponent } from './components/molecules/forms/patient-consent-card/consent-card.component';
import { MedpaceKycCardComponent } from './components/molecules/forms/patient-kyc-card/kyc-card.component';
import { MedpaceKYCDeleteModalComponent } from './components/molecules/forms/patient-kyc-card/kyc-delete-modal.component';
import { MedpaceBankDetailsComponent } from './components/molecules/forms/patient-payment-card/bank-details/bank-details.component';
import { MedpacePatientPaymentCardComponent } from './components/molecules/forms/patient-payment-card/payment-card.component';
import { MedpacePatientTravelPreferencesCardComponent } from './components/molecules/forms/patient-travel-card/travel-card.component';
import { MedpacePersonalInformationCardComponent } from './components/molecules/forms/personal-info-card/personal-info-card.component';
import { MedpaceAdditionalTravelInfoCardComponent } from './components/molecules/forms/request-additional-information-card/additional-travel-info.component';
import { MedpaceRequestDashboardCardComponent } from './components/molecules/forms/request-dashboard-card/request-dashboard.component';
import { MedpaceRequestTypeSelectCardComponent } from './components/molecules/forms/request-type-card/request-type-card.component';
import { MedpaceSiteAddressComponent } from './components/molecules/forms/site-address/site-address.component';
import { MedpaceSiteInfoComponent } from './components/molecules/forms/site-info/site-info-card.component';
import { MedpaceSiteServicesCardComponent } from './components/molecules/forms/site-services-card/site-services-card.component';
import { MedpaceSiteShippingAddressComponent } from './components/molecules/forms/site-shipping-address/site-shipping-address.component';
import { MedpaceStudyDetailsCardComponent } from './components/molecules/forms/study-details/study-details.component';
import { MedpaceStudyInfoCardComponent } from './components/molecules/forms/study-info/study-info-card.component';
import { TravelerRequestCaregiverComponent } from './components/molecules/forms/traveler-request-caregiver/traveler-request-caregiver.component';
import { VerticalStepperLinkComponent } from './components/molecules/forms/vertical-stepper-link/vertical-stepper-link.component';
import { MedpaceTableComponent } from './components/molecules/table/table.component';
import { MedpaceBannerComponent } from './components/organisms/banner/banner.component';
import { MedpacePatientComponent } from './components/organisms/create-patient/patient.component';
import { MedpaceRequestComponent } from './components/organisms/create-request/request.component';
import { MedpaceCreateSiteComponent } from './components/organisms/create-site/site.component';
import { MedpaceStudyComponent } from './components/organisms/create-study/study.component';
import { MedpacefooterComponent } from './components/organisms/footer/footer.component';
import { MedpaceInfoCardComponent } from './components/organisms/info-card/info-card.component';
import { MedpaceInputCardComponent } from './components/organisms/input-card/input-card.component';
import { MedpaceLoginComponent } from './components/organisms/login/login.component';
import { MedpaceLogoutComponent } from './components/organisms/logout/logout.component';
import { VerticalStepperComponent } from './components/organisms/stepper/vertical-stepper.component';
import { MedpaceUserAccountComponent } from './components/organisms/user-account/user-account.component';
import { CRCDashboardComponent } from './crc/dashboard/crc-dashboard.component';
import { PatientCreateEditComponent } from './patient/create-edit/patient-add-edit.component';
import { MedpacePatientOverviewComponent } from './patient/overview/patient-overview.component';
import { MedpacePatientTableComponent } from './patient/table-view/patient-table.component';
import { MedpaceCreateEditPaymentTravelRequestComponent } from './request/create-edit-requests/create-edit-request.component';
import { MedpaceRequestTableViewComponent } from './request/table-view/request-table.component';
import { ForcedLoginAuthGuard } from './services/guards/auth.guard';
import { CreateEditSiteComponent } from './site/create-edit/create-edit-site.component';
import { MedpaceSiteOverviewComponent } from './site/overview/site-overview.component';
import { MedpaceSiteTableComponent } from './site/table-view/site-table.component';
import { StudyComponent } from './study/create-edit/new-edit-study.component';
import { MedpaceBulkSiteModalComponent } from './study/modals/bulk-site/site-upload-modal.component';
import { MedpaceNewSiteModalComponent } from './study/modals/new-site/new-site-modal.component';
import { MedpaceStudyOverviewComponent } from './study/overview/study-overview.component';
import { MedpaceStudyTableComponent } from './study/table-view/study-table.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', component: MedpaceLoginComponent, canActivate: [ForcedLoginAuthGuard] },
    {
        path: 'superadmin/manage',
        pathMatch: 'full',
        component: MedpaceSuperAdminDashboardComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            accessRestriction: [AccessGroupEnum.SuperAdmin],
        },
    },
    {
        path: 'admin/dashboard',
        pathMatch: 'full',
        component: AdminDashboardComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            accessRestriction: [AccessGroupEnum.SuperAdmin, AccessGroupEnum.Admin],
        },
    },
    {
        path: 'admin/newstudy',
        pathMatch: 'full',
        component: StudyComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            accessRestriction: [AccessGroupEnum.SuperAdmin, AccessGroupEnum.Admin],
        },
    },
    {
        path: 'admin/studies',
        pathMatch: 'full',
        component: MedpaceStudyTableComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            accessRestriction: [AccessGroupEnum.SuperAdmin, AccessGroupEnum.Admin],
        },
    },
    {
        path: 'admin/patients',
        pathMatch: 'full',
        component: MedpacePatientTableComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            accessRestriction: [AccessGroupEnum.SuperAdmin, AccessGroupEnum.Admin],
        },
    },
    {
        path: 'admin/patients/editrequest',
        pathMatch: 'full',
        component: MedpaceDataRedirectorComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            content: {
                expandEditRequest: true,
            },
            redirectTo: 'admin/patients',
            accessRestriction: [AccessGroupEnum.SuperAdmin, AccessGroupEnum.Admin],
        },
    },
    {
        path: 'admin/requests',
        pathMatch: 'full',
        component: MedpaceRequestTableViewComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            accessRestriction: [AccessGroupEnum.SuperAdmin, AccessGroupEnum.Admin],
        },
    },
    {
        path: 'admin/requests/notification',
        pathMatch: 'full',
        component: MedpaceDataRedirectorComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            content: {
                status: ['Submitted'],
            },
            redirectTo: 'admin/requests',
            accessRestriction: [AccessGroupEnum.SuperAdmin, AccessGroupEnum.Admin],
        },
    },
    {
        path: 'admin/sites',
        pathMatch: 'full',
        component: MedpaceSiteTableComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            accessRestriction: [AccessGroupEnum.SuperAdmin, AccessGroupEnum.Admin],
        },
    },
    {
        path: 'crc/sites',
        pathMatch: 'full',
        component: MedpaceSiteTableComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'studies/:id',
        pathMatch: 'full',
        component: MedpaceStudyOverviewComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'studies/:id/edit',
        pathMatch: 'full',
        component: StudyComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'studies/:id/newsite',
        pathMatch: 'full',
        component: CreateEditSiteComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'studies/:id/sites/:siteId',
        pathMatch: 'full',
        component: MedpaceSiteOverviewComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'studies/:id/sites/:siteId/patients/new',
        pathMatch: 'full',
        component: PatientCreateEditComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'studies/:id/sites/:siteId/patients/:patientId',
        pathMatch: 'full',
        component: MedpacePatientOverviewComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'studies/:id/sites/:siteId/patients/:patientId/edit',
        pathMatch: 'full',
        component: PatientCreateEditComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'studies/:id/sites/:siteId/patients/:patientId/newrequest',
        pathMatch: 'full',
        component: MedpaceCreateEditPaymentTravelRequestComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            isUpdate: false,
        },
    },
    {
        path: 'studies/:id/sites/:siteId/patients/:patientId/requests/:requestId/edit',
        pathMatch: 'full',
        component: MedpaceCreateEditPaymentTravelRequestComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            isUpdate: true,
        },
    },
    {
        path: 'sites/:siteId/edit',
        pathMatch: 'full',
        component: CreateEditSiteComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'crc/dashboard',
        pathMatch: 'full',
        component: CRCDashboardComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'crc/requests',
        pathMatch: 'full',
        component: MedpaceRequestTableViewComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'crc/requests/notification',
        pathMatch: 'full',
        component: MedpaceDataRedirectorComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            content: {
                status: ['Submitted'],
            },
            redirectTo: 'crc/requests',
        },
    },
    {
        path: 'crc/sites/notification',
        pathMatch: 'full',
        component: MedpaceDataRedirectorComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            content: {
                status: ['PreRegistered'],
            },
            redirectTo: 'crc/sites',
        },
    },
    {
        path: 'logout',
        pathMatch: 'full',
        component: MedpaceLogoutComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'account',
        pathMatch: 'full',
        component: MedpaceUserAccountComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'global-contact-numbers',
        pathMatch: 'full',
        component: GlobalContactNumbersComponent,
    },
    {
        path: 'access-request',
        pathMatch: 'full',
        component: CreateAccessRequestCardComponent,
    },
    {
        path: 'access-request/logged',
        pathMatch: 'full',
        component: CreateAccessRequestCardComponent,
        canActivate: [ForcedLoginAuthGuard],
    },
    {
        path: 'actions',
        pathMatch: 'full',
        component: AccessRequestActionsCardComponent,
    },
    {
        path: 'superadmin/manage/notification',
        pathMatch: 'full',
        component: MedpaceDataRedirectorComponent,
        canActivate: [ForcedLoginAuthGuard],
        data: {
            content: {
                open: ['pendingAccessRequest'],
            },
            redirectTo: 'superadmin/manage',
            accessRestriction: [AccessGroupEnum.SuperAdmin],
        },
    },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            onSameUrlNavigation: 'reload',
        }),
    ],
    declarations: [],
    exports: [RouterModule],
})
export class AppRoutingModule {}

export const registeredComponents = [
    CRCDashboardComponent,
    AdminDashboardComponent,
    MedpaceSuperAdminDashboardComponent,
    MedpaceSiteTableComponent,
    StudyComponent,
    PatientCreateEditComponent,
    MedpaceCreateEditPaymentTravelRequestComponent,
    MedpaceRequestTableViewComponent,
    MedpaceMessageModalComponent,
    MedpaceStudyTableComponent,
    MedpaceNewSiteModalComponent,
    MedpaceBulkSiteModalComponent,
    MedpaceStudyOverviewComponent,
    CreateEditSiteComponent,
    MedpaceSiteOverviewComponent,
    MedpacePatientOverviewComponent,
    MedpacePatientTableComponent,
    MedpaceUserManagementModalComponent,
    MedpaceInputCardComponent,
    MedpaceInfoCardComponent,
    MedpaceBannerComponent,
    MedpacePatientComponent,
    VerticalStepperComponent,
    VerticalStepperLinkComponent,
    MedpaceStudyComponent,
    MedpaceCreateSiteComponent,
    MedpacefooterComponent,
    MedpaceTableComponent,
    MedpaceRequestComponent,
    MedpaceStudyInfoCardComponent,
    MedpaceSiteAddressComponent,
    CRCInviteCardComponent,
    MedpaceSiteShippingAddressComponent,
    MedpaceSiteInfoComponent,
    MedpaceStudyDetailsCardComponent,
    MedpacePersonalInformationCardComponent,
    MedpacePatientConsentCardComponent,
    MedpacePatientPaymentCardComponent,
    MedpacePatientTravelPreferencesCardComponent,
    MedpaceRequestDashboardCardComponent,
    MedpaceSiteServicesCardComponent,
    MedpaceKycCardComponent,
    MedpaceRequestErrorModalComponent,
    MedpaceRequestChangeModalComponent,
    MedpaceDeleteRequestModalComponent,
    MedpaceDeletePatientModalComponent,
    MedpaceViewHistoryModalComponent,
    MedpaceKYCDeleteModalComponent,
    MedpaceAdditionalDetailsModalComponent,
    PatientTravelPreferenceBaseComponent,
    MedpacePatientAccessiblityCardComponent,
    MedpaceRequestTypeSelectCardComponent,
    MedpaceAdditionalTravelInfoCardComponent,
    MedpaceLogoUploadComponent,
    MedpaceCaregiverInfoCardComponent,
    MedpaceCaregiverInfoDialogComponent,
    TravelerRequestCaregiverComponent,
    SelectedCustomComponent,
    SelectedCustomDropDownComponent,
    SelectedCustomDatePickerComponent,
    MedpaceLogoutComponent,
    MedpaceUserAccountComponent,
    MedpaceLoginComponent,
    MedpaceCardComponent,
    MedpaceCardContentDirective,
    MedpaceCardHeaderDirective,
    StudySitesCardComponent,
    MedpacePatientTravelGroundModalComponent,
    MedpacePatientTravelRentalCarModalComponent,
    MedpacePatientTravelPreferencesModalBaseComponent,
    MedpacePatientTravelTrainModalComponent,
    MedpacePatientTravelAirlineModalComponent,
    MedpacePatientTravelLodgingModalComponent,
    MedpaceBankDetailsComponent,
    AccessRequestActionsCardComponent,
    HistoryTimelineComponent,
];
