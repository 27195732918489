@if (!loaderSpinner) {
    <div class="o-grid">
        @if (!!request?.requestPseudoId) {
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Request ID</h5>
                        <span>{{ request?.requestPseudoId }}</span>
                    </div>
                </div>
            </div>
        }
        <div class="o-grid__row" *ngIf="isNew()">
            <div class="o-grid__item">
                <span>
                    What are you requesting?
                    <span class="required-asterisk">*</span>
                </span>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <ng-container [ngTemplateOutlet]="request_type_radio" />
            </div>
        </div>
    </div>
} @else {
    <div class="o-grid">
        <medpace-loading-card></medpace-loading-card>
    </div>
}

<ng-template #unsupported_tier_message>
    @if (travelDisabled || paymentDisabled) {
        <div class="unsupported-tier-message">
            <div *ngIf="travelDisabled">
                <span>
                    Your site is not set up or is unable to utilize Medpace PCS travel services. Please contact
                    {{ emailAddress }} or your CRA with any questions or concerns.
                </span>
            </div>
            <div *ngIf="paymentDisabled">
                <span>
                    Your site is not set up or is unable to utilize Medpace PCS payment services. Please contact
                    {{ emailAddress }} or your CRA with any questions or concerns.
                </span>
            </div>
        </div>
    }
</ng-template>

<ng-template #request_type_radio>
    @if (isUpdate) {
        <div id="requestType" class="c-info__block">
            @if (isNew()) {
            } @else {
                <h5>Request Type</h5>
            }
            <span>{{ getRequestTypeValue(request?.type) }}</span>
        </div>
    } @else {
        <mds-radio-group
            id="requestType"
            [buttons]="requestTypeOptions"
            [formCtrl]="requestTypeFormGroup.controls.requestTypeGroupControl"
            (changeEvent)="selectRequestType($event.value, { id: 'requestType' })"
        />
    }
    <ng-container [ngTemplateOutlet]="unsupported_tier_message" />
</ng-template>
