import { dateFormatPipe } from '@app/pipes/datepipe';
import { dateArrayTransform, dateTransform } from '@utility/utility';
import { IUser } from './interfaces/iUser';

export function updateSiteProperties<T>(event: T, object: T): T {
    if (!object) {
        object = <T>{};
    }
    if (object.hasOwnProperty(Object.keys(event)[0])) {
        object[Object.keys(event)[0]] = event[Object.keys(event)[0]];
    }
    return object;
}

export function createNewSite(): Site {
    return {
        id: undefined,
        studyId: undefined,
        patients: undefined,
        status: {
            statusId: undefined,
            statusName: undefined,
        },
        primaryCrcEmailToInvite: undefined,
        isReviewedByCRC: undefined,
        region: undefined,
        info: {
            siteNumber: undefined,
            name: undefined,
            pilastName: undefined,
            clintrakStatus: undefined,
        },
        address: {
            line1: undefined,
            line2: undefined,
            city: undefined,
            stateOrProvince: undefined,
            postalCode: undefined,
            country: undefined,
            region: undefined,
        },
        isShippingAddressSameAsPrimary: undefined,
        shippingAddress: {
            line1: undefined,
            line2: undefined,
            city: undefined,
            stateOrProvince: undefined,
            postalCode: undefined,
            country: undefined,
            region: undefined,
        },
        services: {
            travel: undefined,
            reimbursement: undefined,
            stipend: undefined,
            other: undefined,
            readyForPayment: undefined,
            kycDocumentation: undefined,
            stipendDetails: undefined,
            otherDetails: undefined,
            allowPpinvitations: undefined,
        },
        siteCRCs: [
            // {
            //     isPrimary: true,
            //     userId: undefined,
            //     siteId: undefined,
            //     user: {
            //         id: undefined,
            //         name: undefined,
            //         objectId: undefined,
            //         isAdmin: undefined,
            //         isSuperAdmin: undefined,
            //         firstName: undefined,
            //         lastName: undefined,
            //         displayName: undefined,
            //         phoneNumber: undefined,
            //         emailAddress: undefined,
            //         accessType: undefined,
            //         assignedStudy: undefined,
            //         assignedSite: undefined,
            //         userStudies: undefined,
            //         userAccessGroups: undefined,
            //         hasLocalAuthentication: undefined,
            //     },
            // },
        ],
        payment: {
            credit: undefined,
            check: undefined,
            wireTransfer: undefined,
            reimburse: undefined,
            collectBankDetails: undefined,
        },
    };
}
export interface SiteInfo {
    siteNumber: string;
    name: string;
    pilastName: string;
    clintrakStatus: string;
}
export class SiteInfo implements SiteInfo {}

export interface Address {
    line1: string;
    line2: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    country: string;
    region: string;
}
export class Address implements Address {}

export interface SitePayment {
    credit: boolean;
    check: boolean;
    wireTransfer: boolean;
    collectBankDetails: boolean;
    reimburse: boolean;
}
export class SitePayment implements SitePayment {}

export class SiteServices implements SiteServices {}

export interface SiteServices {
    travel: boolean;
    reimbursement: boolean;
    stipend: boolean;
    other: boolean;
    stipendDetails: string;
    otherDetails: string;
    kycDocumentation: boolean;
    readyForPayment: boolean;
    allowPpinvitations: boolean;
}
export interface ISite {
    id: number;
    studyId: number;
    info: SiteInfo;
    address: Address;
    shippingAddress: Address;
    payment: SitePayment;
    services: SiteServices;
    siteCRCs: SiteCRC[];
    patients: any[];
    region: string;
    status: SiteStatus;
    isReviewedByCRC: boolean;
    isShippingAddressSameAsPrimary: boolean;
    primaryCrcEmailToInvite: string;
}

export class Site implements ISite {
    id: number;
    studyId: number;
    info: SiteInfo;
    address: Address;
    shippingAddress: Address;
    payment: SitePayment;
    services: SiteServices;
    siteCRCs: SiteCRC[];
    patients: any[];
    region: string;
    status: SiteStatus;
    isReviewedByCRC: boolean;
    isShippingAddressSameAsPrimary: boolean;
    primaryCrcEmailToInvite: string;
}

export interface SiteCRC {
    user: IUser;
    isPrimary: boolean;
    userId: number;
    siteId: number;
}

export interface SiteByStatusViewModel {
    id: number;
    protocol: string;
    institutionName: string;
    primaryCrcEmailToInvite: string;
    preRegisteredDate: string;
    statusId: number;
    statusName: string;
    sivDate: string;
    sivProjectedDates: string[];
    activationDate: string;
    activationProjectedDates: string[];
    lastReminderDate: string;
}

export interface SiteDataModel {
    id: number;
    studyId: number;
    siteNumber: string;
    institutionName: string;
    pilastName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    region: string;
    isShippingAddressSameAsPrimary: boolean;
    shippingAddress1: string;
    shippingAddress2: string;
    shippingCity: string;
    shippingState: string;
    shippingZipcode: string;
    shippingCountry: string;
    shippingRegion: string;
    siteCrcs: SiteCRC[]; // cannot be named siteCRCs because the model won't be bound with that from the API
    travelServiceSupport: boolean;
    reimbursementSupport: boolean;
    stipendSupport: boolean;
    otherServiceSupport: boolean;
    stipendSupportDetails: string;
    otherServiceDetails: string;
    kycdocumentRequired: boolean;
    paymentConfirmationRequired: boolean;
    paymentCc: boolean;
    paymentCheck: boolean;
    paymentWireTransfer: boolean;
    collectBankDetails: boolean;
    paymentDirect: boolean;
    patients: any[];
    status: SiteStatus;
    statusId: number;
    isReviewedByCRC: boolean;
    clinTrakStatus: string;
    primaryCrcEmailToInvite: string;
    clinTrakPrimaryCrcEmail: string;
    allowPpinvitations: boolean;
}

export interface SiteStatus {
    statusId: number;
    statusName: string;
}

export interface SiteRawDataModel extends SiteDataModel {
    primaryCRCEmail: string;
    supportingCRCEmail: string;
}
export class SiteRawDataModel implements SiteDataModel {}
export interface SiteTableView {
    id: number;
    siteNumber: string;
    pilastName: string;
    name: string;
    protocol: string;
    primaryCRC: string;
}
export interface ISiteSummaryViewModel {
    id: number;
    studyId: number;
    siteNumber?: string | null;
    protocol?: string | null;
    institutionName?: string | null;
    piLastName?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zipcode?: string | null;
    region?: string | null;
    country?: string | null;
    primaryCRCFullName?: string | null;
    secondaryCRCFullName?: string | null;
    isReviewedByCRC: boolean;
    status: SiteStatus;
    sivDate: string;
    sivProjectedDates: string[];
    activationDate: string;
    activationProjectedDates: string[];
    clinTrakStatus: string;
    primaryCrcEmailToInvite: string;
}

export class SiteSummaryViewModel implements ISiteSummaryViewModel {
    id!: number;
    studyId!: number;
    siteNumber?: string | null;
    protocol?: string | null;
    institutionName?: string | null;
    piLastName?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zipcode?: string | null;
    region?: string | null;
    country?: string | null;
    primaryCRCFullName?: string | null;
    primaryCRCId?: number | null;
    supportingCRCsFullNames: string;
    secondaryCRCFullName?: string | null;
    supportingCRCFullName: string;
    isReviewedByCRC: boolean;
    status: SiteStatus;
    sivDate: string;
    sivProjectedDates: string[];
    activationDate: string;
    activationProjectedDates: string[];
    clinTrakStatus: string;
    primaryCrcEmailToInvite: string;
    clintrakStatus: string;
    numPatients: number;
    numPatientsUtilizingPCS: number;

    constructor(data?: ISiteSummaryViewModel) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'] !== undefined ? _data['id'] : <any>null;
            this.studyId = _data['studyId'] !== undefined ? _data['studyId'] : <any>null;
            this.siteNumber = _data['siteNumber'] !== undefined ? _data['siteNumber'] : <any>null;
            this.protocol = _data['protocol'] !== undefined ? _data['protocol'] : <any>null;
            this.institutionName = _data['institutionName'] !== undefined ? _data['institutionName'] : <any>null;
            this.piLastName = _data['piLastName'] !== undefined ? _data['piLastName'] : <any>null;
            this.address1 = _data['address1'] !== undefined ? _data['address1'] : <any>null;
            this.address2 = _data['address2'] !== undefined ? _data['address2'] : <any>null;
            this.city = _data['city'] !== undefined ? _data['city'] : <any>null;
            this.state = _data['state'] !== undefined ? _data['state'] : <any>null;
            this.zipcode = _data['zipcode'] !== undefined ? _data['zipcode'] : <any>null;
            this.region = _data['region'] !== undefined ? _data['region'] : <any>null;
            this.country = _data['country'] !== undefined ? _data['country'] : <any>null;
            this.primaryCRCFullName =
                _data['primaryCRCFullName'] !== undefined ? _data['primaryCRCFullName'] : <any>null;
            this.supportingCRCsFullNames =
                _data['supportingCRCsFullNames'] !== undefined ? _data['supportingCRCsFullNames'] : <any>null;
            this.secondaryCRCFullName =
                _data['secondaryCRCFullName'] !== undefined ? _data['secondaryCRCFullName'] : <any>null;
            this.isReviewedByCRC = _data['isReviewedByCRC'] !== undefined ? _data['isReviewedByCRC'] : <any>null;
            this.status = _data['status'] !== undefined ? _data['status'] : <any>null;
            this.numPatients = _data['numPatients'] !== undefined ? _data['numPatients'] : <any>null;
            this.numPatientsUtilizingPCS =
                _data['numPatientsUtilizingPCS'] !== undefined ? _data['numPatientsUtilizingPCS'] : <any>null;
            this.primaryCrcEmailToInvite =
                _data['primaryCrcEmailToInvite'] !== undefined ? _data['primaryCrcEmailToInvite'] : <any>null;
            this.clinTrakStatus = _data['clinTrakStatus'] !== undefined ? _data['clinTrakStatus'] : <any>null;
        }
    }

    static fromJS(data: any): SiteSummaryViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new SiteSummaryViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id !== undefined ? this.id : <any>null;
        data['studyId'] = this.studyId !== undefined ? this.studyId : <any>null;
        data['siteNumber'] = this.siteNumber !== undefined ? this.siteNumber : <any>null;
        data['protocol'] = this.protocol !== undefined ? this.protocol : <any>null;
        data['institutionName'] = this.institutionName !== undefined ? this.institutionName : <any>null;
        data['piLastName'] = this.piLastName !== undefined ? this.piLastName : <any>null;
        data['address1'] = this.address1 !== undefined ? this.address1 : <any>null;
        data['address2'] = this.address2 !== undefined ? this.address2 : <any>null;
        data['city'] = this.city !== undefined ? this.city : <any>null;
        data['state'] = this.state !== undefined ? this.state : <any>null;
        data['zipcode'] = this.zipcode !== undefined ? this.zipcode : <any>null;
        data['region'] = this.region !== undefined ? this.region : <any>null;
        data['country'] = this.country !== undefined ? this.country : <any>null;
        data['primaryCRCFullName'] = this.primaryCRCFullName !== undefined ? this.primaryCRCFullName : <any>null;
        data['supportingCRCsFullNames'] =
            this.supportingCRCsFullNames !== undefined ? this.supportingCRCsFullNames : <any>null;
        data['secondaryCRCFullName'] = this.secondaryCRCFullName !== undefined ? this.secondaryCRCFullName : <any>null;
        data['isReviewedByCRC'] = this.isReviewedByCRC !== undefined ? this.isReviewedByCRC : <any>null;
        data['status'] = this.status !== undefined ? this.status : <any>null;
        data['numPatients'] = this.numPatients !== undefined ? this.numPatients : <any>null;
        data['numPatientsUtilizingPCS'] =
            this.numPatientsUtilizingPCS !== undefined ? this.numPatientsUtilizingPCS : <any>null;
        data['primaryCrcEmailToInvite'] =
            this.primaryCrcEmailToInvite !== undefined ? this.primaryCrcEmailToInvite : <any>null;
        data['clinTrakStatus'] = this.clinTrakStatus !== undefined ? this.clinTrakStatus : <any>null;

        return data;
    }
}

export interface CRCData {
    primaryCRC: IUser;
    supportingCRCs: IUser[];
}

export function getSiteSIVDate(datePipe: dateFormatPipe, site: SiteSummaryViewModel | SiteByStatusViewModel): string {
    if (site.sivDate && site.sivDate !== 'N/A') {
        return dateTransform(datePipe, site.sivDate);
    } else if (site.sivProjectedDates?.length > 0) {
        return dateArrayTransform(datePipe, site.sivProjectedDates)?.join('\r\n');
    } else return 'N/A';
}
