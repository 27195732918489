import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionModel } from '@models/actionModel';
import { SaveStatusResponse } from '@utility/utility';
import { Observable } from 'rxjs';
import { PatientRequestSummary, SitePatientsViewModel } from 'src/app/models/patient';
import { SiteService } from 'src/app/services/site/site.service';
import { SiteByStatusViewModel, SiteDataModel, SiteSummaryViewModel } from '../../models/site';

@Injectable()
export class AdminSiteRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    createSite: (newSite: SiteDataModel) => Observable<HttpResponse<number>> = (newSite: SiteDataModel) => {
        return this.client.post<number>(`${this.siteService.getSiteBaseUrl()}/api/sites`, newSite, {
            observe: 'response',
        });
    };

    getSitesVM: () => Observable<SiteSummaryViewModel[]> = () => {
        return this.client.get<SiteSummaryViewModel[]>(`${this.siteService.getSiteBaseUrl()}/api/sites/summaries`);
    };

    getStudySitesWithRequests: (studyId: number) => Observable<SiteSummaryViewModel[]> = (studyId: number) => {
        return this.client.get<SiteSummaryViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/sites/${studyId}/summaries`
        );
    };

    getStudySites: (studyId: number) => Observable<SiteSummaryViewModel[]> = (studyId: number) => {
        return this.client.get<SiteSummaryViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/sites/${studyId}/details`
        );
    };

    getSite: (siteId: number) => Observable<SiteDataModel> = (siteId: number) => {
        return this.client.get<SiteDataModel>(`${this.siteService.getSiteBaseUrl()}/api/sites/${siteId}`);
    };

    getSites: (siteIds: number[]) => Observable<SiteDataModel[]> = (siteIds: number[]) => {
        return this.client.get<SiteDataModel[]>(`${this.siteService.getSiteBaseUrl()}/api/sites`, {
            params: { siteIds },
        });
    };

    getSitesByStatusId: (statusId: number) => Observable<SiteByStatusViewModel[]> = (statusId: number) => {
        return this.client.get<SiteByStatusViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/sites/status/${statusId}`
        );
    };

    sendSiteRegistrationReminder: (siteId: number) => Observable<SaveStatusResponse> = (siteId: number) => {
        return this.client.post<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/sites/${siteId}/reminder`,
            null
        );
    };

    getSiteCRCs: (siteId: number) => Observable<any[]> = (siteId: number) => {
        return this.client.get<any[]>(`${this.siteService.getSiteBaseUrl()}/api/sites/${siteId}/users/crcdetails`);
    };

    updateSite: (site: SiteDataModel) => Observable<number> = (site: SiteDataModel) => {
        return this.client.put<number>(`${this.siteService.getSiteBaseUrl()}/api/sites`, site);
    };

    getSitePatients: (siteId: number) => Observable<SitePatientsViewModel[]> = (siteId: number) => {
        return this.client.get<SitePatientsViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/sites/${siteId}/patientdata`
        );
    };

    getSitePatientRequestSummaries: (siteId: number) => Observable<PatientRequestSummary[]> = (siteId: number) => {
        return this.client.get<PatientRequestSummary[]>(
            `${this.siteService.getSiteBaseUrl()}/api/sites/${siteId}/requestcounts`
        );
    };

    getSitePatientDataWithVisitsId: (siteId: number) => Observable<SitePatientsViewModel[]> = (siteId: number) => {
        return this.client.get<SitePatientsViewModel[]>(
            `${this.siteService.getSiteBaseUrl()}/api/sites/${siteId}/patientsdata`
        );
    };

    getUserSite(email: string): Observable<SiteDataModel[]> {
        return this.client.get<SiteDataModel[]>(`${this.siteService.getSiteBaseUrl()}/api/sites`);
    }

    getUserSiteByStudy(studyId: number): Observable<SiteDataModel[]> {
        return this.client.get<SiteDataModel[]>(`${this.siteService.getSiteBaseUrl()}/api/studies/${studyId}/sites`);
    }

    changeSiteStatus: (newStatus: string, siteId: number, site?: SiteDataModel) => Observable<number> = (
        newStatus: string,
        siteId: number,
        site?: SiteDataModel
    ) => {
        return this.client.put<number>(
            `${this.siteService.getSiteBaseUrl()}/api/sites/changestatus?newStatus=${newStatus}&siteId=${siteId}`,
            site
        );
    };

    getAvailableActions: (siteId: number) => Observable<ActionModel[]> = (siteId: number) => {
        return this.client.get<ActionModel[]>(`${this.siteService.getSiteBaseUrl()}/api/actions/available/${siteId}`);
    };
}
