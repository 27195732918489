<mds-dialog dialogTitle="Confirm Site Details" [attr.data-cy]="'siteRegisterModal'">
    <mds-dialog-content>
        <div class="o-grid__row">
            <div class="o-grid__item" id="siteCRCs">
                <div class="c-card">
                    <div class="c-card__header">
                        <div class="c-card__ribbon">
                            <div class="c-card__item c-card__item--left mb-2">
                                <h3>Site Information</h3>
                            </div>
                        </div>
                    </div>
                    <div class="c-card__content">
                        <div class="c-form">
                            <div class="o-grid">
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <div class="c-info__block">
                                            <h5>Site #</h5>
                                            <span>{{ site?.info?.siteNumber }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <div class="c-info__block">
                                            <h5>Institution Name</h5>
                                            <span>{{ site?.info?.name }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <div class="c-info__block">
                                            <h5>PI Last Name</h5>
                                            <span>{{ site?.info?.pilastName }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item" id="siteCRCs">
                <div class="c-card">
                    <div class="c-card__header">
                        <div class="c-card__ribbon">
                            <div class="c-card__item c-card__item--left mb-2">
                                <h3>Primary Site Address</h3>
                            </div>
                        </div>
                    </div>
                    <div class="c-card__content">
                        <div class="c-form">
                            <div class="o-grid">
                                <medpace-site-address
                                    [isEditing]="true"
                                    [parentFormGroup]="addressFormGroup"
                                ></medpace-site-address>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item" id="siteCRCs">
                <div class="c-card">
                    <div class="c-card__header">
                        <div class="c-card__ribbon">
                            <div class="c-card__item c-card__item--left mb-2">
                                <h3>Shipping Address</h3>
                            </div>
                        </div>
                    </div>
                    <div class="c-card__content">
                        <div class="c-form">
                            <div class="o-grid">
                                <medpace-site-shipping-address
                                    [isEditing]="true"
                                    [parentFormGroup]="shippingAddressFormGroup"
                                ></medpace-site-shipping-address>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item" id="siteCRCs">
                <div class="c-card">
                    <div class="c-card__header">
                        <div class="c-card__ribbon">
                            <div class="c-card__item c-card__item--left mb-2">
                                <h3>Site CRC Information</h3>
                            </div>
                        </div>
                    </div>
                    <div class="c-card__content">
                        <div class="c-form">
                            <div class="o-grid">
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <h5>Primary CRC Information</h5>
                                    </div>
                                </div>
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <div class="c-info__block">
                                            <h5>First/Given Name</h5>
                                            <span>{{ primaryCRC?.user?.firstName }}</span>
                                        </div>
                                    </div>
                                    <div class="o-grid__item">
                                        <div class="c-info__block">
                                            <h5>Last/Surname</h5>
                                            <span>{{ primaryCRC?.user?.lastName }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="o-grid__row">
                                    <div class="o-grid__field">
                                        <mds-text-field
                                            id="phone"
                                            [formGrp]="baseFormGroup"
                                            formCtrlName="phoneCtrl"
                                            placeholder="Type here..."
                                            appearance="outline"
                                            inputType="text"
                                            floatLabel="always"
                                            label="Phone Number"
                                            width="100%"
                                            [required]="true"
                                        ></mds-text-field>
                                    </div>
                                    <div class="o-grid__item">
                                        <div class="c-info__block">
                                            <h5>Email Address</h5>
                                            <span>{{
                                                toStringOrFallback(primaryCRC?.user?.emailAddress, 'Unspecified')
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <label>Would you like to add supporting CRCs?</label>
                                    </div>
                                </div>
                                <div class="o-grid__row">
                                    <div class="o-grid__item">
                                        <mds-radio-group
                                            [buttons]="supportingCrcRadioButtons"
                                            [formGrp]="baseFormGroup"
                                            formCtrlName="supportingRadioButtons"
                                            id="supportingCRC"
                                            (changeEvent)="selectSupportingCrcValue($event)"
                                        >
                                        </mds-radio-group>
                                    </div>
                                </div>
                                <div *ngIf="hasSupportingCrc === true">
                                    <div
                                        [attr.data-cy]="'supportingCrcs'"
                                        *ngFor="let formGroup of supportingCrcFormArray.controls; let i = index"
                                    >
                                        <div class="o-grid__row">
                                            <div class="o-grid__field half">
                                                <mds-text-field
                                                    id="firstName{{ i }}"
                                                    [formGrp]="formGroup"
                                                    formCtrlName="firstNameCtrl"
                                                    placeholder="Type here..."
                                                    appearance="outline"
                                                    inputType="text"
                                                    floatLabel="always"
                                                    label="First/Given Name"
                                                    width="100%"
                                                    [required]="true"
                                                ></mds-text-field>
                                            </div>
                                            <div class="o-grid__field half">
                                                <mds-text-field
                                                    id="lastName{{ i }}"
                                                    [formGrp]="formGroup"
                                                    formCtrlName="lastNameCtrl"
                                                    placeholder="Type here..."
                                                    appearance="outline"
                                                    inputType="text"
                                                    floatLabel="always"
                                                    label="Last/Surname"
                                                    width="100%"
                                                    [required]="true"
                                                ></mds-text-field>
                                            </div>
                                        </div>
                                        <div class="o-grid__row">
                                            <div class="o-grid__field">
                                                <mds-text-field
                                                    id="email{{ i }}"
                                                    [formGrp]="formGroup"
                                                    formCtrlName="emailCtrl"
                                                    placeholder="Type here..."
                                                    appearance="outline"
                                                    inputType="text"
                                                    floatLabel="always"
                                                    label="Email Address"
                                                    width="100%"
                                                    [required]="true"
                                                ></mds-text-field>
                                            </div>
                                        </div>
                                        <div class="o-grid__item" *ngIf="supportingCrcFormArray.length > 1">
                                            <mds-button
                                                [variant]="'primary'"
                                                [color]="'warn'"
                                                [label]="'Remove Supporting CRC'"
                                                [id]="'removeCrcBtn'"
                                                (clickEvent)="removeSupportingCRC(i)"
                                            ></mds-button>
                                        </div>
                                    </div>

                                    <div class="o-grid__row" *ngIf="supportingCrcFormArray.length <= 4">
                                        <div class="o-grid__item">
                                            <mds-button
                                                [variant]="'outline'"
                                                [label]="'Add Supporting CRC'"
                                                [id]="'addCrcBtn'"
                                                (clickEvent)="addSupportingCRC()"
                                            ></mds-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #spinner>
                        <medpace-loading-card></medpace-loading-card>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-checkbox
                    [disabled]="false"
                    [indeterminate]="false"
                    [label]="
                        'I have reviewed the details presented in this form, and I confirm that the information provided by me is accurate and complete.'
                    "
                    [checked]="false"
                    [required]="true"
                    id="verifyCheckbox"
                    [formCtrl]="verifyCheckboxControl"
                ></mds-checkbox>
            </div>
        </div>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'center'">
        <mds-button
            [id]="'completeBtn'"
            mat-button
            [attr.data-cy]="'closeButton'"
            label="Complete Registration"
            (clickEvent)="validAndClose()"
            variant="primary"
        ></mds-button>
        <mds-button
            [id]="'cancel-btn'"
            mat-button
            mat-dialog-close
            label="Cancel"
            variant="outline"
            (clickEvent)="cancel()"
        ></mds-button>
    </mds-dialog-actions>
</mds-dialog>
