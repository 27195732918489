export enum SiteStatusEnum {
    Created = 1,
    PreRegistered = 2,
    Registered = 3,
    Active = 4,
    Inactive = 5,
}

export function GetSiteStatusLabel(statusId: number) {
    return SiteStatusEnum[statusId];
}
