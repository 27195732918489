import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AccessActionDto,
    AccessRequestDto,
    CreateAccessRequestDto,
    CreateInvitationMessageDto,
    InvitationDto,
    UserAccessRequestLogDto,
} from '@models/accessRequest';
import { SiteService } from '@services/site/site.service';
import { Observable } from 'rxjs';

@Injectable()
export class AccessRequestRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    getAllAccessRequests(): Observable<AccessRequestDto[]> {
        return this.client.get<AccessRequestDto[]>(`${this.siteService.getSiteBaseUrl()}/api/access`);
    }

    getAllAccessRequestLogs(): Observable<UserAccessRequestLogDto[]> {
        return this.client.get<UserAccessRequestLogDto[]>(`${this.siteService.getSiteBaseUrl()}/api/access/logs`);
    }

    grantAccess(accessRequestId: number, action: AccessActionDto): Observable<number> {
        return this.client.put<number>(
            `${this.siteService.getSiteBaseUrl()}/api/access/grant/${accessRequestId}`,
            action
        );
    }

    rejectAccess(accessRequestId: number, action: AccessActionDto): Observable<number> {
        return this.client.put<number>(
            `${this.siteService.getSiteBaseUrl()}/api/access/reject/${accessRequestId}`,
            action
        );
    }

    addAccessRequest: (accessRequest: CreateAccessRequestDto, key: string) => Observable<number> = (
        accessRequest: CreateAccessRequestDto,
        key: string
    ) => {
        return this.client.post<number>(
            `${this.siteService.getSiteBaseUrl()}/api/access/createrequest/${key}`,
            accessRequest
        );
    };

    getAccessRequestsBySiteId(siteId: number): Observable<AccessRequestDto[]> {
        return this.client.get<AccessRequestDto[]>(`${this.siteService.getSiteBaseUrl()}/api/access/${siteId}`);
    }

    sendInvitationMessage: (invitationMessage: CreateInvitationMessageDto) => Observable<number> = (
        invitationMessage: CreateInvitationMessageDto
    ) => {
        return this.client.post<number>(`${this.siteService.getSiteBaseUrl()}/api/access/invite`, invitationMessage);
    };

    getInvitation: (guid: string) => Observable<InvitationDto> = (guid: string) => {
        return this.client.get<InvitationDto>(`${this.siteService.getSiteBaseUrl()}/api/access/invitation/${guid}`);
    };

    getInvitationsBySiteId: (siteId: number) => Observable<InvitationDto[]> = (siteId: number) => {
        return this.client.get<InvitationDto[]>(
            `${this.siteService.getSiteBaseUrl()}/api/access/invitations/${siteId}`
        );
    };

    ifUserExistByGuid: (guid: string) => Observable<boolean> = (guid: string) => {
        return this.client.get<boolean>(`${this.siteService.getSiteBaseUrl()}/api/access/ifUserExist/${guid}`);
    };

    reInvite: (guid: string, reason: string) => Observable<number> = (invitationGuid: string, reason: string) => {
        return this.client.post<number>(`${this.siteService.getSiteBaseUrl()}/api/access/reinvite/${invitationGuid}`, {
            value: reason,
        });
    };

    revokeInvitation: (guid: string, reason: string) => Observable<number> = (
        invitationGuid: string,
        reason: string
    ) => {
        return this.client.post<number>(
            `${this.siteService.getSiteBaseUrl()}/api/access/invitation/revoke/${invitationGuid}`,
            { value: reason }
        );
    };

    revokeAccessGrant: (siteId: number, userId: number, reason: string) => Observable<number> = (
        siteId: number,
        userId: number,
        reason: string
    ) => {
        return this.client.put<number>(`${this.siteService.getSiteBaseUrl()}/api/access/revoke/${siteId}/${userId}`, {
            value: reason,
        });
    };
}
