import { DatePipe } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { DisplayErrorModalComponent } from '@components/molecules/modals/display-error-modal/display-error-modal.component';
import { User } from '@models/user';
import { AuthService } from '@services/auth/auth.service';
import { CurrentlyEditedCardService } from '@services/currently-edited-card/currently-edited-card.service';
import { ErrorService } from '@services/error-handling/error-service';
import { GroupService } from '@services/group/group.service';
import { LoadingViewTriggerService } from '@services/loading-module-trigger/loading-module-trigger.service';
import { RequestMessagingService } from '@services/messaging/request-messaging.service';
import { AccessGroupStateService } from '@services/state-management/access-group-state.service';
import { AccessRequestStateService } from '@services/state-management/access-request-state.service';
import { CountryStateService } from '@services/state-management/country-state.service';
import { CurrencyStateService } from '@services/state-management/currency-state.service';
import { RegionStateService } from '@services/state-management/region-state.service';
import { TimeOptionsStateService } from '@services/state-management/time-options-state.service';
import { TravelOptionStateService } from '@services/state-management/travel-option-state.service';
import { UserService } from '@services/user/user.service';
import { NoRoleModalComponent } from '@study/modals/no-role-modal/no-role-modal.component';
import {
    EMPTY,
    Observable,
    Subject,
    Subscription,
    combineLatest,
    distinctUntilChanged,
    filter,
    of,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { IdleTimeService } from '../app/services/idle-time/idle-time.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    title: string = 'Travel Portal';
    loggedIn: boolean = false;
    user: User;
    isLoading: boolean = false;
    componentDestroyed$: Subject<boolean> = new Subject();
    isLayoutVisible: boolean = false;
    isAuthenticated$: Observable<boolean>;
    isRouterOutputLoad: boolean = false;
    noRoleDialog: MatDialogRef<NoRoleModalComponent>;

    timeoutPromise: Promise<boolean>;
    timerSubscription: Subscription;

    httpError504$ = this.errorStateService.getHttpErrors().pipe(
        takeUntilDestroyed(),
        filter((error) => error.status === 504),
        switchMap(() => {
            return this.dialog
                .open(DisplayErrorModalComponent, {
                    autoFocus: false,
                    width: '500px',
                    disableClose: false,
                    data: 'The server encountered a temporary issue and could not complete your request. Please try refreshing the page and report a bug if the problem persists.',
                })
                .afterClosed();
        })
    );

    constructor(
        private authService: AuthService,
        private group: GroupService,
        private userService: UserService,
        private countryStateService: CountryStateService,
        private currencyStateService: CurrencyStateService,
        private regionStateService: RegionStateService,
        private travelOptionStateService: TravelOptionStateService,
        private dialog: MatDialog,
        private loadingModalTriggerService: LoadingViewTriggerService,
        private requestMessagingService: RequestMessagingService,
        private accessGroupStateService: AccessGroupStateService,
        private router: Router,
        private currentlyEditedCardService: CurrentlyEditedCardService,
        private timeOptionsService: TimeOptionsStateService,
        private idleTimeService: IdleTimeService,
        private accessRequestStateService: AccessRequestStateService,
        private errorStateService: ErrorService
    ) {
        this.isAuthenticated$ = this.authService.getIsAuthenticated();

        this.httpError504$.subscribe();
        this.errorStateService
            .handleVersionError()
            .pipe(
                takeUntil(this.componentDestroyed$),
                switchMap((error) => {
                    let errorDialog = this.dialog.open(DisplayErrorModalComponent, {
                        autoFocus: false,
                        width: '500px',
                        disableClose: false,
                        data: error,
                    });
                    return errorDialog.afterClosed();
                }),
                tap(() => {
                    this.errorStateService.clearVersionError();
                })
            )
            .subscribe();
    }

    ngOnInit() {
        this.isAuthenticated$
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((isAuth) => {
                    if (!isAuth) {
                        if (this.isAccessRequestPage()) {
                            this.isRouterOutputLoad = true;
                        }
                    }
                }),
                filter((x) => x),
                distinctUntilChanged(),
                switchMap(() => {
                    return this.group.getGroupsFromStorage().pipe(
                        takeUntil(this.componentDestroyed$),
                        tap(() => this.userService.checkUserRole())
                    );
                }),
                switchMap((groups) => {
                    return combineLatest({
                        groups: of(groups),
                        doesUserHaveRole: this.userService.doesUserHaveRole(),
                    }).pipe(take(1));
                }),
                switchMap((result) => {
                    this.userInit(result.groups);
                    this.userService.setUser(this.user);
                    this.isRouterOutputLoad = result.doesUserHaveRole;
                    if (result.doesUserHaveRole) {
                        this.initStaticData();
                        return this.userService.getUser();
                    } else {
                        if (this.isAccessRequestPage()) {
                            this.isRouterOutputLoad = true;
                        } else {
                            this.openRoleDialog();
                            return EMPTY;
                        }
                    }
                })
            )
            .subscribe();

        this.loadingModalTriggerService
            .observeLoading()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((loading) => {
                    this.isLoading = loading;
                })
            )
            .subscribe();

        this.router.events
            .pipe(
                takeUntil(this.componentDestroyed$),
                filter((event) => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                this.currentlyEditedCardService.clearState();
            });

        this.timeoutPromise = this.idleTimeService.startTimeoutAsync(3600);
        this.setTimeoutAction();
    }

    setTimeoutAction() {
        if (!!this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        this.timerSubscription = this.idleTimeService.startTimer().subscribe();
        this.timeoutPromise.then((result: boolean) => {
            if (result) {
                this.authService.logout();
            }
        });
    }

    @HostListener('window:click')
    @HostListener('window:keypress')
    @HostListener('window:scroll')
    resetInactivityTimer() {
        this.timeoutPromise = this.idleTimeService.resetTimeoutAsync();
        this.setTimeoutAction();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    private isAccessRequestPage(): boolean {
        return this.router.url.includes('actions') || this.router.url.includes('access-request');
    }

    private openRoleDialog(): void {
        this.noRoleDialog?.close();
        this.noRoleDialog = this.dialog.open<NoRoleModalComponent>(NoRoleModalComponent, {
            id: 'NoRoleDialog',
            width: '80%',
            maxWidth: 1000,
            minWidth: 360,
            disableClose: true,
        });

        this.noRoleDialog
            .afterClosed()
            .pipe(
                tap(() => {
                    this.isRouterOutputLoad = true;
                }),
                take(1)
            )
            .subscribe();
    }

    private initStaticData(): void {
        this.regionStateService.init();
        this.countryStateService.init();
        this.currencyStateService.init();
        this.requestMessagingService.init();
        this.accessGroupStateService.init();
        this.travelOptionStateService.init();
        const is24HourFormat: boolean = this.getIs24HourFormat();
        this.timeOptionsService.init(is24HourFormat);
        if (!!this.user && this.user.isSuperAdmin) {
            this.accessRequestStateService.setAccessRequests();
        }
    }

    private getIs24HourFormat(): boolean {
        try {
            return !new DatePipe(navigator.language) // use the builtin locale array to determine if am/pm appears
                .transform(new Date(0), 'shortTime')
                .toLowerCase()
                .match('am|pm');
        } catch (error) {
            console.warn(`Locale ${navigator.language} not found, getting format for en-US`);
            return !new DatePipe('en-US').transform(new Date(0), 'shortTime').toLowerCase().match('am|pm');
        }
    }

    private userInit(groups: string[]): void {
        this.user = {
            name: this.authService.claims['given_name'] + ' ' + this.authService.claims['family_name'],
            objectId: this.authService.claims['sub'],
            isAdmin: this.group.isUserAdmin(groups),
            isSuperAdmin: groups.indexOf('Medpace_TravelPortal_SuperAdmins') > -1 ? true : false,
            firstName: this.authService.claims['given_name'],
            lastName: this.authService.claims['family_name'],
            phoneNumber: undefined,
            emailAddress: this.authService.claims['email'],
            id: undefined,
            accessType: undefined,
            displayName: this.authService.claims['given_name'] + ' ' + this.authService.claims['family_name'],
            assignedStudy: undefined,
            assignedSite: undefined,
            userStudies: [],
            userSites: [],
            userAccessGroups: [],
            hasLocalAuthentication: undefined,
        };
    }
}
