export class FilterBase {
    search: string;

    constructor() {
        this.search = '';
    }

    setFilterPredicate(tableColumns: string[]) {
        return (data: any, filter: string): boolean => {
            const filterObject: FilterBase = JSON.parse(filter);

            let matchesFilters: boolean[] = [];
            let matchesSearch = false;
            Object.keys(filterObject).forEach((filterName) => {
                if (filterName === 'search') {
                    if (filterObject.search === '') matchesSearch = true;
                    else {
                        tableColumns.forEach((columnName: string) => {
                            if (
                                columnName !== 'action' &&
                                data[columnName].toLowerCase().includes(filterObject.search.toLowerCase())
                            ) {
                                matchesSearch = true;
                            }
                        });
                    }
                } else {
                    if (
                        Array.isArray(filterObject[filterName]) &&
                        (!filterObject[filterName].length || filterObject[filterName].includes(data[filterName]))
                    )
                        matchesFilters.push(true);
                    else matchesFilters.push(false);
                }
            });

            return matchesSearch && (matchesFilters.length == 0 || matchesFilters.every((x) => x));
        };
    }
}
