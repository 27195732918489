/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
// TODO: Needs to be investigated and fixed
import { DatePipe } from '@angular/common';
import {
    Component,
    DoCheck,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
    inject,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpenseTypes } from '@app/enums/ExpenseTypes';
import { RequestTypes } from '@app/enums/RequestTypes';
import { ModalResult } from '@app/enums/modal-result.enum';
import { TravelType } from '@app/enums/travel-type.enum';
import { MedpaceCreateEditRequestExpenseDetailsComponent } from '@components/molecules/forms/medpace-create-edit-request-expense-details/medpace-create-edit-request-expense-details.component';
import * as MedpaceCreateRequestVisitDetailsComponent from '@components/molecules/forms/medpace-create-request-visit-details/medpace-create-request-visit-details.component';
import * as MedpaceEditRequestVisitDetailsComponent from '@components/molecules/forms/medpace-edit-request-visit-details/medpace-edit-request-visit-details.component';
import { SingleFlightFormGroup } from '@components/molecules/forms/travel-card/flight-request-details/flight-details/flight-details.component';
import { CaregiverFlightFormGroup } from '@components/molecules/forms/travel-card/flight-request-details/flight-request-details.component';
import { CaregiverTrainTripFormGroup } from '@components/molecules/forms/travel-card/train-request-details/train-request-details.component';
import {
    BookableCaregiver,
    CheckboxFormGroup,
    TravelCardFormGroup,
} from '@components/molecules/forms/travel-card/travel-card.component';
import { DisplayErrorModalComponent } from '@components/molecules/modals/display-error-modal/display-error-modal.component';
import { MedpaceMessageModalComponent } from '@components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { CountryViewModel } from '@models/country';
import { CurrencyViewModel } from '@models/currency';
import { EmittedEvent } from '@models/event';
import { CreateGetThereUrlViewModel } from '@models/getthere/createGetThereUrlViewModel';
import { Caregiver, Patient, getCaregiverFullName } from '@models/patient';
import { RequestApprovalStatus } from '@models/requestApprovalStatus';
import { ExpenseType, Study, StudyTravelOption, VisitName } from '@models/study';
import { TransferData } from '@models/transferData';
import { User } from '@models/user';
import { VisitGroup } from '@models/visitGroup';
import { EventService } from '@services/event/event.service';
import {
    GetThereInputParameters,
    GetThereService,
    GetThereTravelerId,
    mapToDTO,
} from '@services/getthere/getthere.service';
import { HistoryDataService } from '@services/historyData/history-data.service';
import { LoadingViewTriggerService } from '@services/loading-module-trigger/loading-module-trigger.service';
import { Step } from '@services/scroll/steps-interface';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { CountryStateService } from '@services/state-management/country-state.service';
import { CurrencyStateService } from '@services/state-management/currency-state.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { RequestStateService } from '@services/state-management/request-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { TimeOptionsStateService } from '@services/state-management/time-options-state.service';
import { mapTrainTravelFormModelToTrainTravelDetails } from '@services/transforms/request-transform';
import { UserService } from '@services/user/user.service';
import { disableControls, enableControls, getDisabledControls } from '@utility/form-utils';
import { PersistentFormGroup } from '@utility/persistent-forms';
import * as utility from '@utility/utility';
import { sha256 } from 'hash.js/lib/hash/sha/256';
import { Moment, unix } from 'moment';
import {
    BehaviorSubject,
    Observable,
    ReplaySubject,
    Subject,
    combineLatest,
    filter,
    forkJoin,
    map,
    merge,
    of,
    shareReplay,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs';
import { MedpaceAdditionalDetailsModalComponent } from 'src/app/admin/manage/modals/additional-details-modal.component';
import { MedpaceDeleteRequestModalComponent } from 'src/app/admin/manage/modals/delete-request-modal.component';
import { MedpaceRequestChangeModalComponent } from 'src/app/admin/manage/modals/request-change-modal.component';
import { MedpaceRequestErrorModalComponent } from 'src/app/admin/manage/modals/request-error-modal.component';
import { MedpaceViewHistoryModalComponent } from 'src/app/admin/manage/modals/view-history-modal.component';
import { InputChange } from 'src/app/models/event-objects/input-change';
import {
    GenericRequest,
    GenericRequestStatus,
    Reimbursement,
    RequestStatusDTO,
    TrainTravelDetails,
    TrainTravelDetailsFormModel,
    TrainTravelType,
    TravelAccommodations,
    TravelRequestCaregiver,
    TravelRequestFlightTravelDetail,
    VisitDetails,
} from 'src/app/models/request';
import { Site, SiteServices } from 'src/app/models/site';
import { AdminRequestServices } from 'src/app/services/admin/admin-request.service';
import { maxSizeOfFilesInMB } from 'src/globals';
import { AttachmentSizeService } from './../../../services/attachment-size/attachment-size.service';
import { RequestStatusEnum, RequestStatusService } from './../../../services/request-status/request-status.service';

@Component({
    selector: 'medpace-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MedpaceRequestComponent implements OnInit, OnChanges, DoCheck, OnDestroy {
    public GenericRequestStatus = GenericRequestStatus;
    @Input()
    isAdmin: boolean;

    patient: Patient;
    patient$: Observable<Patient> = this.patientStateService.getSummaryPatientData();

    study: Study;
    study$: Observable<Study> = this.studyStateService.getStudy();

    site: Site;
    site$: Observable<Site> = this.siteStateService.getSite();

    siteServices: SiteServices;

    visitTypes: VisitName[];
    visitTypes$: Observable<VisitName[]> = this.requestStateService.getVisits();

    expenseTypes: MdsOption[];

    request: GenericRequest;
    request$: Observable<GenericRequest> = this.requestStateService.getRequest();

    @Input()
    isUpdate: boolean;

    @Input()
    stipendOrOOP: string;

    @Output()
    submitClickEvent = new EventEmitter();

    @Output()
    incrementalSave = new EventEmitter();

    @Output()
    saveClickEvent = new EventEmitter();

    @Output()
    statusChangeEvent = new EventEmitter();

    @Output()
    requestChangeEvent = new EventEmitter<GenericRequest>();

    @Output()
    patientNavigationRequestEvent = new EventEmitter<InputChange>();

    isButtonSubmitActive: boolean = true;

    showTravelCards: boolean = false;
    showPaymentCard: boolean = false;
    travelAvailable: boolean;
    reimbursementAvailable: boolean;
    stipendAvailable: boolean;
    paymentAvailable: boolean;
    bannerExpanded: boolean = false;
    isCompletable: boolean;
    requestStatusLog: any[] = [];
    validationMessage: string = `Please complete the following:`;
    validForm: boolean = true;
    statusConstants: RequestApprovalStatus[];
    actualStatus: string = 'Loading...';
    maxSummaryFileSizesInMB: number;
    private componentDestroyed$: Subject<boolean> = new Subject();

    private requestTypeStep: Step = {
        name: 'Request Information',
        isActive: true,
        identifier: 'requestType',
    };
    private studyInformationStep: Step = {
        name: 'Study Details',
        isActive: false,
        identifier: 'studyInformation',
    };
    private patientInformationStep: Step = {
        name: 'Patient Information',
        isActive: false,
        identifier: 'patientInformation',
    };
    private visitDetailsStep: Step = {
        name: 'Visit Details',
        isActive: false,
        identifier: 'visitDetails',
    };
    private travelStep: Step = {
        name: 'Travel Information',
        isActive: false,
        identifier: 'travel-information-step',
    };

    private paymentStep: Step = {
        name: 'Payment Information',
        isActive: false,
        identifier: 'paymentInformation',
    };
    steps: Step[] = [this.requestTypeStep, this.studyInformationStep, this.patientInformationStep];

    defaultsteps: Step[] = this.steps;

    requestFormGroup: FormGroup<{
        travelCard: TravelCardFormGroup;
        VisitDetails: AbstractControl;
        ExpenseDetails: AbstractControl;
    }>;
    isNewRequest: boolean = true;

    private requestTypeSubject = new ReplaySubject<RequestTypes>();
    public requestType$ = combineLatest([
        this.requestTypeSubject.pipe(startWith(null)),
        this.activeRoute.queryParamMap,
    ]).pipe(
        map(([requestTypeSubjectValue, queryParamMap], index) => {
            let requestType: RequestTypes = null;
            if (index === 0 && queryParamMap.has('payment-travel')) {
                const paymentTravel = queryParamMap.get('payment-travel');
                if (paymentTravel === 'payment') requestType = RequestTypes.PAYMENT;
                else requestType = RequestTypes.TRAVEL;
            } else requestType = requestTypeSubjectValue;
            return requestType;
        }),
        filter((type) => !!type),
        tap((requestType: RequestTypes) => {
            //only one of controls travelCard, ExpenseDetails should be enabled and valid for travel request to save
            if (requestType === RequestTypes.TRAVEL) {
                //disable ExpenseDetails
                //enable travelCard
                this.requestFormGroup.controls.ExpenseDetails.disable();
                this.requestFormGroup.controls.travelCard.enable();
            } else if (requestType === RequestTypes.PAYMENT) {
                //enable ExpenseDetails
                //disable travelCard
                this.requestFormGroup.controls.ExpenseDetails.enable();
                this.requestFormGroup.controls.travelCard.disable();
            }
        }),
        shareReplay()
    );
    public createRequestVisitDetails_ViewModel$ = combineLatest([
        this.site$,
        this.visitTypes$,
        this.requestType$,
        this.patient$,
    ]).pipe(
        filter(([site, visitNames, requestType, patient]) => !!site && !!visitNames && !!requestType && !!patient),
        map(([site, visitNames, requestType, patient]) => {
            return {
                site,
                visitNames,
                requestType,
                patient,
            };
        }),
        map((result) => {
            const visitNameOptions = result.visitNames
                .filter((vn) => vn.visitGroup.id === result.patient.patientIdentification.visitGroupId)
                .map((name) => ({ value: name, viewValue: name.name }));
            const timePickerOptions = new Array<utility.MdsOptionGeneric<Moment>>();
            for (let hour = 0; hour < 24; hour++) {
                for (let minute = 0; minute < 60; minute += 15) {
                    const time = unix(0);
                    time.hour(hour);
                    time.minute(minute);

                    timePickerOptions.push({
                        value: time,
                        viewValue: this.datePipe.transform(time.toISOString(), 'HH:mm', undefined, 'en-US'),
                    });
                }
            }

            return <MedpaceCreateRequestVisitDetailsComponent.ViewModel>{
                requestType: result.requestType,
                paymentRequest_ViewModel: <MedpaceCreateRequestVisitDetailsComponent.PaymentRequest_ViewModel>{
                    siteAddress: result.site.address,
                    siteNumber: result.site.info.siteNumber,
                    visitNameOptions: visitNameOptions,
                },
                travelRequest_ViewModel: <MedpaceCreateRequestVisitDetailsComponent.TravelRequest_ViewModel>{
                    timePickerOptions: timePickerOptions,
                    siteAddress: result.site.address,
                    siteNumber: result.site.info.siteNumber,
                    visitNameOptions: visitNameOptions,
                },
            };
        })
    );

    private createRequestVisitDetailsSubject =
        new BehaviorSubject<MedpaceCreateRequestVisitDetailsComponent.MedpaceCreateRequestVisitDetailsComponent>(null);
    @ViewChild(MedpaceCreateRequestVisitDetailsComponent.MedpaceCreateRequestVisitDetailsComponent)
    public set createRequestVisitDetails_Component(
        value: MedpaceCreateRequestVisitDetailsComponent.MedpaceCreateRequestVisitDetailsComponent
    ) {
        if (value) {
            this.createRequestVisitDetailsSubject.next(value);
            this.requestFormGroup.setControl('VisitDetails', value.formGroup);
        }
    }

    public editRequestVisitDetails_ViewModel$ = combineLatest([
        this.request$,
        this.site$,
        this.visitTypes$,
        this.patient$,
    ]).pipe(
        filter(([request, site, visitNames, patient]) => !!request && !!site && !!visitNames && !!patient),
        map(([request, site, visitNames, patient]) => {
            const requestType = request.type === 'payment' ? RequestTypes.PAYMENT : RequestTypes.TRAVEL;

            const filteredVisitNames = visitNames.filter(
                (vn) => vn.visitGroup.id === patient.patientIdentification.visitGroupId
            );
            const visitNamesCopy = [...filteredVisitNames];
            // In PatientPACE, the patient might select a special value for Visit Name, "Not sure", represented as NULL in database.
            // In this only special case, we want such value to be available in a Visit Name dropdown so PCS users can edit and save existing request without error.
            // Otherwise, the "Not sure" option should not be visible to PCS users.
            if (request.visitDetails.visitNameId === undefined) {
                const unspecifiedVisitName: VisitName = {
                    id: undefined,
                    name: 'Unspecified',
                    scheduled: false,
                    sortOrder: 0,
                    studyId: 0,
                    visitGroup: <VisitGroup>{ name: '', sortOrder: 0 },
                };
                visitNamesCopy.push(unspecifiedVisitName);
            }

            const visitNameOptions = visitNamesCopy.map(
                (name) => <utility.MdsOptionGeneric<VisitName>>{ value: name, viewValue: name.name }
            );

            const visitName = visitNamesCopy.find((name) => name.id === request.visitDetails.visitNameId);
            let viewModel = <MedpaceEditRequestVisitDetailsComponent.ViewModel>{
                requestType: requestType,
                paymentRequest_ViewModel: null,
                travelRequest_ViewModel: null,
            };
            if (requestType === RequestTypes.PAYMENT) {
                viewModel.paymentRequest_ViewModel = <MedpaceEditRequestVisitDetailsComponent.PaymentRequest_ViewModel>{
                    siteAddress: site.address,
                    siteNumber: site.info.siteNumber,
                    visitNameOptions: visitNameOptions,
                    visitName: visitName,
                    visitDate: request.visitDetails.visitStartDate,
                };
            } else if (requestType === RequestTypes.TRAVEL) {
                const timePickerOptions = new Array<utility.MdsOptionGeneric<Moment>>();
                for (let hour = 0; hour < 24; hour++) {
                    for (let minute = 0; minute < 60; minute += 15) {
                        const time = unix(0);
                        time.hour(hour);
                        time.minute(minute);

                        timePickerOptions.push({
                            value: time,
                            viewValue: this.datePipe.transform(time.toISOString(), 'HH:mm', undefined, 'en-US'),
                        });
                    }
                }

                const visitStartDateTime = request.visitDetails.visitStartDate;
                const visitEndDateTime = request.visitDetails.visitEndDate;
                const [startHours, startMinutes] = request.visitDetails.visitStartTime.split(':');
                const [endHours, endMinutes] = request.visitDetails.visitEndTime.split(':');
                visitStartDateTime?.hour(Number(startHours));
                visitStartDateTime?.minute(Number(startMinutes));
                visitEndDateTime?.hour(Number(endHours));
                visitEndDateTime?.minute(Number(endMinutes));

                viewModel.travelRequest_ViewModel = <MedpaceEditRequestVisitDetailsComponent.TravelRequest_ViewModel>{
                    timePickerOptions: timePickerOptions,
                    siteAddress: site.address,
                    siteNumber: site.info.siteNumber,
                    specialNavigationInstructions: request.visitDetails.travelerInstructions,
                    visitNameOptions: visitNameOptions,
                    visitName: visitName,
                    visitStartDateTime: visitStartDateTime,
                    visitEndDateTime: visitEndDateTime,
                };
            }

            return viewModel;
        })
    );
    private editRequestVisitDetailsSubject =
        new BehaviorSubject<MedpaceEditRequestVisitDetailsComponent.MedpaceEditRequestVisitDetailsComponent>(null);
    @ViewChild(MedpaceEditRequestVisitDetailsComponent.MedpaceEditRequestVisitDetailsComponent)
    public set editRequestVisitDetails_Component(
        value: MedpaceEditRequestVisitDetailsComponent.MedpaceEditRequestVisitDetailsComponent
    ) {
        if (value) {
            this.editRequestVisitDetailsSubject.next(value);
            this.requestFormGroup.setControl('VisitDetails', value.formGroup);
        }
    }

    studyTravelOptions: StudyTravelOption[];

    @ViewChild(MedpaceCreateEditRequestExpenseDetailsComponent)
    public set expenseDetails(value: MedpaceCreateEditRequestExpenseDetailsComponent) {
        this._expenseDetails = value;
        this.expenseDetailsSubject.next(value);
    }
    private expenseDetailsSubject = new Subject<MedpaceCreateEditRequestExpenseDetailsComponent>();
    private _expenseDetails: MedpaceCreateEditRequestExpenseDetailsComponent;
    public expenseDetails$ = this.requestType$.pipe(
        switchMap((type) =>
            this.expenseDetailsSubject.pipe(
                map((expenseDetails) => {
                    return { expenseDetails, type };
                })
            )
        ),
        filter((result) => !!result.expenseDetails),
        tap((result) => {
            if (result.type === RequestTypes.PAYMENT) {
                this.requestFormGroup.setControl('ExpenseDetails', result.expenseDetails.formGroup);
                this.requestFormGroup.get('ExpenseDetails').enable();
            } else {
                this.requestFormGroup.get('ExpenseDetails')?.disable();
            }
        })
    );
    public readonly user$ = this.userService.getUser();

    private newTravelCard = new Subject<void>();
    protected isUpdate$ = this.activeRoute.data.pipe(map((data) => data['isUpdate'] as boolean));
    private loadingService = inject(LoadingViewTriggerService);
    //protected travelCardLoading = true;
    protected travelCardVM$ = combineLatest({
        requestType: this.requestType$,
        isUpdate: this.isUpdate$,
        request: this.requestStateService.getRequest(),
        patient: this.patientStateService.getPatient(),
        countries: this.countryStateService.getCountries(),
        currencies: this.currencyStateService.getCurrencies(),
        timeOptions: this.timeOptionsStateService.getTimeOptions(),
    }).pipe(
        filter((result) => result.requestType === RequestTypes.TRAVEL),
        filter((result) => !!result.patient && !!result.request),
        switchMap((result) =>
            forkJoin({
                car: this.historyDataService.getPatientTravelRequestHistoryForCarService(result.patient.id),
                flight: this.historyDataService.getPatientTravelRequestHistoryForFlight(result.patient.id),
                lodging: this.historyDataService.getPatientTravelRequestHistoryForLodging(result.patient.id),
                other: this.historyDataService.getPatientTravelRequestHistoryForOther(result.patient.id),
                carRental: this.historyDataService.getPatientTravelRequestHistoryForRentalCar(result.patient.id),
                train: this.historyDataService.getPatientTravelRequestHistoryForTrain(result.patient.id),
            }).pipe(
                map((historyData) => {
                    return [result, historyData] as const;
                })
            )
        ),
        tap(([result, historyData]) => {
            let primaryCaregiver = result.patient.caregivers.find((caregiver) => caregiver.isPrimary);
            const otherCaregivers = result.patient.caregivers.filter((caregiver) => !caregiver.isPrimary);
            const patientTravelDetails = result.request.travelRequests;
            const travellingCaregivers = result.patient.caregivers.map((caregiver) => {
                const travelRequestCaregiver =
                    result.request.travelRequestCaregivers?.find((c) => c.caregiverId === caregiver.id) ||
                    <TravelRequestCaregiver>{
                        caregiverId: caregiver.id,
                        caregiverName: getCaregiverFullName(caregiver),
                        caregiver: caregiver,
                        flightRequestTheSameAsPatient: true,
                        isChecked: false,
                        isPrimary: caregiver.isPrimary,
                        travelRequestId: result.request.id,
                        flightRequestDetails: {
                            airportOrigin: '',
                            airportDestination: '',
                            returnDate: null,
                            departureDate: null,
                            specialRequests: '',
                            travelType: TravelType.NO_FLIGHT,
                            id: null, //id: 0,
                        },
                        trainTravelDetails: {
                            isSameAsPatient: true,
                            trainFormInLegacyMode: false,
                            trainStationOrigin: '',
                            trainStationDestination: '',
                            departureDate: null,
                            returnDate: null,
                            trainTravelType: TrainTravelType.NoTravel,
                            specialRequests: '',
                        },
                    };
                return { travelRequestCaregiver, caregiver };
            });

            const fallbackValueForFlightDetails = {
                // before August 2023, we only had one text field for the unstructured information, `Special Requests`.
                specialRequests: patientTravelDetails.flightRequests,

                // also, we didn't have structured information whether a trip is a round trip, so here is an arbitrary choice to adapt to new object:
                travelType: patientTravelDetails.needsAirTravel ? TravelType.ROUND_TRIP : TravelType.NO_FLIGHT,

                airportOrigin: '',
                airportDestination: '',
                departureDate: null,
                returnDate: null,
                id: null,
            };

            const patientFlightRequestDetails: TravelRequestFlightTravelDetail =
                patientTravelDetails.flightRequestDetails || fallbackValueForFlightDetails;
            const caregiversFlightRequestDetails = travellingCaregivers.map<
                utility.FormGroupValueOf<CaregiverFlightFormGroup>
            >((value) => {
                const flightRequestDetails = value.travelRequestCaregiver.flightRequestDetails || {
                    travelType: TravelType.NO_FLIGHT,
                    airportOrigin: '',
                    airportDestination: '',
                    departureDate: null,
                    returnDate: null,
                    specialRequests: '',
                    id: null, //id: 0,
                };
                let travelType = <TravelType>flightRequestDetails.travelType;
                if (travelType === null || travelType === undefined) travelType = TravelType.NO_FLIGHT; // travelType needs to be specified, otherwise and error will be thrown. Currently if caregiver is not checked the response from server will contain null
                const needsAirTravel = travelType !== TravelType.NO_FLIGHT;
                const details: utility.FormGroupValueOf<CaregiverFlightFormGroup['controls']['details']> =
                    needsAirTravel
                        ? {
                              bookIdenticalTrip: value.travelRequestCaregiver.flightRequestTheSameAsPatient,
                              flightDetails: flightRequestDetails,
                          }
                        : {
                              bookIdenticalTrip: false,
                              flightDetails: flightRequestDetails,
                          };
                return {
                    needsAirTravel: needsAirTravel,
                    caregiver: value.caregiver,
                    details: details,
                };
            });
            const patientTrainTripRequestDetails: TrainTravelDetails =
                patientTravelDetails.trainTravelDetailsForPatient || {
                    trainTravelType: TrainTravelType.NoTravel,
                    trainStationOrigin: '',
                    trainStationDestination: '',
                    departureDate: null,
                    returnDate: null,
                    isSameAsPatient: true,
                    specialRequests: '',
                    trainFormInLegacyMode: false,
                };

            const travelPreferences = result.patient.travelPreferences;
            const flightPreferences = {
                needsTravel: travelPreferences.airtravel.needsAirTravel,
                airlinePreference1: travelPreferences.airtravel.airlinePreference1,
                airlinePreference2: travelPreferences.airtravel.airlinePreference2,
                frequentFlyerNumber1: travelPreferences.airtravel.frequentFlyer1,
                frequentFlyerNumber2: travelPreferences.airtravel.frequentFlyer2,
                knownTravelerNumber: travelPreferences.airtravel.knownTravelerNumber,
                seatPreference: travelPreferences.airtravel.seatPreference,
                specialNeeds: travelPreferences.airtravel.airlineSpecialNeeds,
            };
            const trainPreferences = {
                specialNeeds: travelPreferences.traintravel.specialNeeds,
            };
            const lodgingPreferences = {
                hotelBrandPreference: travelPreferences.lodging.hotelBrandPreference,
                roomPreference: travelPreferences.lodging.lodgingRoomPreference,
                specialNeeds: travelPreferences.lodging.specialRequirements,
                allergies: travelPreferences.lodging.allergies,
            };
            const carRentalPreferences = {
                frequentTravelerNumber: travelPreferences.rentalCar.rentalCarFrequentTravelerNumber,
            };
            const transferPreferences = {
                specialNeeds: travelPreferences.groundTransportation.groundSpecialNeeds,
            };
            if (result.isUpdate) {
                const primaryCaregiverFlightDetails = caregiversFlightRequestDetails.find(
                    (details) => details.caregiver.id === primaryCaregiver?.id
                );

                this.requestFormGroup.setControl(
                    'travelCard',
                    TravelCardFormGroup.create({
                        accompanyingCaregivers: {
                            primaryCaregiver: {
                                checked: result.request.travelRequestCaregivers.some(
                                    (travelRequestCaregiver) =>
                                        travelRequestCaregiver.caregiverId === primaryCaregiver?.id
                                ),
                                value: {
                                    caregiver: primaryCaregiver,
                                    bookable:
                                        !!primaryCaregiverFlightDetails &&
                                        (primaryCaregiverFlightDetails.details.bookIdenticalTrip ||
                                            primaryCaregiverFlightDetails.details.flightDetails.travelType !==
                                                TravelType.NO_FLIGHT),
                                },
                            },
                            otherCaregivers: otherCaregivers.map<
                                utility.FormGroupValueOf<CheckboxFormGroup<BookableCaregiver>>
                            >((caregiver) => {
                                const caregiverFlightDetails = caregiversFlightRequestDetails.find(
                                    (details) => details.caregiver.id === caregiver.id
                                );
                                return {
                                    checked: result.request.travelRequestCaregivers.some(
                                        (travelRequestCaregiver) => travelRequestCaregiver.caregiverId === caregiver.id
                                    ),
                                    value: {
                                        caregiver: caregiver,
                                        bookable:
                                            !!caregiverFlightDetails &&
                                            (caregiverFlightDetails.details.bookIdenticalTrip ||
                                                caregiverFlightDetails.details.flightDetails.travelType !==
                                                    TravelType.NO_FLIGHT),
                                    },
                                };
                            }),
                            none: {
                                checked: result.request.travelRequestCaregivers.length === 0,
                                value: null,
                            },
                        },
                        travelDetails: {
                            needsAirTravel: patientTravelDetails.needsAirTravel,
                            flightPreferences: flightPreferences,
                            flightDetails: {
                                patientFlightDetails: {
                                    travelType: patientFlightRequestDetails.travelType,
                                    airportOrigin: patientFlightRequestDetails.airportOrigin,

                                    airportDestination: patientFlightRequestDetails.airportDestination,

                                    departureDate: patientFlightRequestDetails.departureDate,
                                    returnDate: patientFlightRequestDetails.returnDate,
                                    specialRequests: patientFlightRequestDetails.specialRequests,
                                    id: patientFlightRequestDetails.id,
                                },
                                caregivers: caregiversFlightRequestDetails,
                            },
                            flightTransactionDetails: {
                                receiptDate: patientTravelDetails.flightTransDate,
                                receiptAmount: patientTravelDetails.flightTransAmount?.toString(),
                                currency: result.currencies.find(
                                    (currency) => currency.isocode === patientTravelDetails.flightTransCurrency
                                ),
                                uploadedAttachment: null,
                                downloadAttachmentId: patientTravelDetails.flightAttachmentInfoId,
                                downloadAttachmentName: 'flight-travel-attachment',
                            },
                            needsTrainTravel: patientTravelDetails.needsTrainTravel,
                            trainPreferences: trainPreferences,
                            trainDetails: {
                                patientTrainTripDetails: {
                                    travelType: patientTrainTripRequestDetails.trainTravelType,
                                    stationOrigin: patientTrainTripRequestDetails.trainStationOrigin,
                                    stationDestination: patientTrainTripRequestDetails.trainStationDestination,
                                    departureDate: patientTrainTripRequestDetails.departureDate,
                                    returnDate: patientTrainTripRequestDetails.returnDate,
                                    specialRequests: patientTrainTripRequestDetails.specialRequests,
                                },
                                caregivers: travellingCaregivers.map<
                                    utility.FormGroupValueOf<CaregiverTrainTripFormGroup>
                                >((value) => {
                                    let travelType = <TrainTravelType>(
                                        value.travelRequestCaregiver.trainTravelDetails?.trainTravelType
                                    );
                                    if (travelType === null || travelType === undefined)
                                        travelType = TrainTravelType.NoTravel; // travelType needs to be specified, otherwise and error will be thrown. Currently if caregiver is not checked the response from server will contain null

                                    const needsTrainTravel = travelType !== TrainTravelType.NoTravel;
                                    let details: utility.FormGroupValueOf<
                                        CaregiverTrainTripFormGroup['controls']['details']
                                    > = needsTrainTravel
                                        ? {
                                              bookIdenticalTrip:
                                                  value.travelRequestCaregiver.trainTravelDetails?.isSameAsPatient,
                                              trainDetails: {
                                                  travelType: travelType,
                                                  stationOrigin:
                                                      value.travelRequestCaregiver.trainTravelDetails
                                                          ?.trainStationOrigin,
                                                  stationDestination:
                                                      value.travelRequestCaregiver.trainTravelDetails
                                                          ?.trainStationDestination,
                                                  departureDate:
                                                      value.travelRequestCaregiver.trainTravelDetails?.departureDate,
                                                  returnDate:
                                                      value.travelRequestCaregiver.trainTravelDetails?.returnDate,
                                                  specialRequests:
                                                      value.travelRequestCaregiver.trainTravelDetails?.specialRequests,
                                              },
                                          }
                                        : {
                                              bookIdenticalTrip: false,
                                              trainDetails: {
                                                  travelType: travelType,
                                                  stationOrigin: '',
                                                  stationDestination: '',
                                                  departureDate: null,
                                                  returnDate: null,
                                                  specialRequests: '',
                                              },
                                          };
                                    return {
                                        needsTrainTravel: needsTrainTravel,
                                        caregiver: value.caregiver,
                                        details: details,
                                    };
                                }),
                            },
                            trainTransactionDetails: {
                                receiptDate: patientTravelDetails.trainTransDate,
                                receiptAmount: patientTravelDetails.trainCost?.toString(),
                                currency: result.currencies.find(
                                    (currency) => currency.isocode === patientTravelDetails.trainCurrency
                                ),
                                uploadedAttachment: null,
                                downloadAttachmentId: patientTravelDetails.trainAttachmentInfoId,
                                downloadAttachmentName: 'train-travel-attachment',
                            },
                            needsLodging: patientTravelDetails.needsLodging,
                            lodgingPreferences: lodgingPreferences,
                            lodgingDetails: {
                                checkInDate: patientTravelDetails.lodgingCheckInDate,
                                checkOutDate: patientTravelDetails.lodgingCheckOutDate,
                                specialRequests: patientTravelDetails.lodgingRequests,
                            },
                            lodgingTransactionDetails: {
                                receiptDate: patientTravelDetails.lodgingTransDate,
                                receiptAmount: patientTravelDetails.lodgingCost?.toString(),
                                currency: result.currencies.find(
                                    (currency) => currency.isocode === patientTravelDetails.lodgingCurrency
                                ),
                                uploadedAttachment: null,
                                downloadAttachmentId: patientTravelDetails.lodgingAttachmentInfoId,
                                downloadAttachmentName: 'lodging-travel-attachment',
                            },
                            needsCarRental: patientTravelDetails.needsRentalCar,
                            carRentalDetails: {
                                pickupAddress: patientTravelDetails.rentalCarLocation,
                                pickupCountry: result.countries.find(
                                    (option) => option.countryCode === patientTravelDetails.rentalCarCountry
                                )?.viewValue,
                                pickupDate: patientTravelDetails.rentalCarPickUpDate,
                                pickupTime: !patientTravelDetails.rentalCarPickUpTime
                                    ? null
                                    : result.timeOptions.find(
                                          (option) =>
                                              option.value ===
                                              this.timeOptionsStateService.convertTimeStringToMinutesSinceMidnight(
                                                  patientTravelDetails.rentalCarPickUpTime
                                              )
                                      )?.value,
                                dropOffDate: patientTravelDetails.rentalCarDropOffDate,
                                dropOffTime: !patientTravelDetails.rentalCarDropOffTime
                                    ? null
                                    : result.timeOptions.find(
                                          (option) =>
                                              option.value ===
                                              this.timeOptionsStateService.convertTimeStringToMinutesSinceMidnight(
                                                  patientTravelDetails.rentalCarDropOffTime
                                              )
                                      )?.value,
                                specialRequests: patientTravelDetails.rentalCarRequests,
                            },
                            carRentalTransactionDetails: {
                                receiptDate: patientTravelDetails.rentalCarDate,
                                receiptAmount: patientTravelDetails.rentalCarCost?.toString(),
                                currency: result.currencies.find(
                                    (currency) => currency.isocode === patientTravelDetails.rentalCarCurrency
                                ),
                                uploadedAttachment: null,
                                downloadAttachmentId: patientTravelDetails.rentalCarAttachmentInfoId,
                                downloadAttachmentName: 'rental-car-attachment',
                            },
                            carRentalPreferences: carRentalPreferences,

                            needsTransfer: patientTravelDetails.needsCar,
                            transferPreferences: transferPreferences,
                            transferDetails: {
                                transfers: patientTravelDetails.transferDatas.map((transferData) => {
                                    return {
                                        ...transferData,
                                        pickupDate: transferData.pickupDateTime?.clone().startOf('day'),
                                        pickupTime: transferData.pickupDateTime
                                            ?.clone()
                                            .diff(transferData.pickupDateTime.clone().startOf('day'), 'minutes'),
                                    };
                                }),
                                specialRequests: patientTravelDetails.carServiceRequests,
                            },
                            transferTransactionDetails: {
                                receiptDate: patientTravelDetails.groundTransDate,
                                receiptAmount: patientTravelDetails.groundCost?.toString(),
                                currency: result.currencies.find(
                                    (currency) => currency.isocode === patientTravelDetails.groundCurrency
                                ),
                                uploadedAttachment: null,
                                downloadAttachmentId: patientTravelDetails.groundAttachmentInfoId,
                                downloadAttachmentName: 'ground-travel-attachment',
                            },
                            needsOther: patientTravelDetails.needsOther,
                            otherDetails: {
                                specialRequests: patientTravelDetails.otherRequests,
                            },
                            otherTransactionDetails: {
                                receiptDate: patientTravelDetails.otherTransDate,
                                receiptAmount: patientTravelDetails.otherCost?.toString(),
                                currency: result.currencies.find(
                                    (currency) => currency.isocode === patientTravelDetails.otherCurrency
                                ),
                                uploadedAttachment: null,
                                downloadAttachmentId: patientTravelDetails.otherAttachmentInfoId,
                                downloadAttachmentName: 'other-travel-attachment',
                            },
                        },
                    })
                );
            } else {
                this.requestFormGroup.setControl(
                    'travelCard',
                    TravelCardFormGroup.create({
                        accompanyingCaregivers: {
                            primaryCaregiver: {
                                checked: false,
                                value: {
                                    caregiver: primaryCaregiver,
                                    bookable: false,
                                },
                            },
                            otherCaregivers: otherCaregivers.map<
                                utility.FormGroupValueOf<CheckboxFormGroup<BookableCaregiver>>
                            >((caregiver) => {
                                return {
                                    checked: false,
                                    value: {
                                        caregiver: caregiver,
                                        bookable: false,
                                    },
                                };
                            }),
                            none: {
                                checked: false,
                                value: null,
                            },
                        },
                        travelDetails: {
                            needsAirTravel: false,
                            flightPreferences: flightPreferences,
                            flightDetails: {
                                patientFlightDetails: {
                                    travelType: TravelType.NO_FLIGHT,
                                    airportOrigin: '',
                                    airportDestination: '',
                                    departureDate: null,
                                    returnDate: null,
                                    specialRequests: '',
                                    id: null, // 0,
                                },
                                caregivers: travellingCaregivers.map<
                                    utility.FormGroupValueOf<CaregiverFlightFormGroup>
                                >((value) => {
                                    return {
                                        needsAirTravel: false,
                                        caregiver: value.caregiver,
                                        details: {
                                            bookIdenticalTrip: true,
                                            flightDetails: {
                                                travelType: TravelType.NO_FLIGHT,
                                                airportOrigin: '',
                                                airportDestination: '',
                                                departureDate: null,
                                                returnDate: null,
                                                specialRequests: '',
                                                id: null, //  id: 0,
                                            },
                                        },
                                    };
                                }),
                            },
                            flightTransactionDetails: {
                                receiptDate: null,
                                receiptAmount: null,
                                currency: null,
                                uploadedAttachment: null,
                                downloadAttachmentId: null,
                                downloadAttachmentName: 'flight-travel-attachment',
                            },
                            needsTrainTravel: false,
                            trainPreferences: trainPreferences,
                            trainDetails: {
                                patientTrainTripDetails: {
                                    travelType: TrainTravelType.NoTravel,
                                    stationOrigin: '',
                                    stationDestination: '',
                                    departureDate: null,
                                    returnDate: null,
                                    specialRequests: '',
                                },
                                caregivers: travellingCaregivers.map<
                                    utility.FormGroupValueOf<CaregiverTrainTripFormGroup>
                                >((value) => {
                                    return {
                                        needsTrainTravel: false,
                                        caregiver: value.caregiver,
                                        details: {
                                            bookIdenticalTrip: true,
                                            trainDetails: {
                                                travelType: TrainTravelType.NoTravel,
                                                stationOrigin: '',
                                                stationDestination: '',
                                                departureDate: null,
                                                returnDate: null,
                                                specialRequests: '',
                                            },
                                        },
                                    };
                                }),
                            },
                            trainTransactionDetails: {
                                receiptDate: null,
                                receiptAmount: null,
                                currency: null,
                                uploadedAttachment: null,
                                downloadAttachmentId: null,
                                downloadAttachmentName: 'train-travel-attachment',
                            },
                            needsLodging: false,
                            lodgingPreferences: lodgingPreferences,
                            lodgingDetails: {
                                checkInDate: null,
                                checkOutDate: null,
                                specialRequests: '',
                            },
                            lodgingTransactionDetails: {
                                receiptDate: null,
                                receiptAmount: null,
                                currency: null,
                                uploadedAttachment: null,
                                downloadAttachmentId: null,
                                downloadAttachmentName: 'lodging-travel-attachment',
                            },
                            needsCarRental: false,
                            carRentalDetails: {
                                pickupAddress: '',
                                pickupCountry: null,
                                pickupDate: null,
                                pickupTime: null,
                                dropOffDate: null,
                                dropOffTime: null,
                                specialRequests: '',
                            },
                            carRentalTransactionDetails: {
                                receiptDate: null,
                                receiptAmount: null,
                                currency: null,
                                uploadedAttachment: null,
                                downloadAttachmentId: null,
                                downloadAttachmentName: 'rental-car-attachment',
                            },
                            carRentalPreferences: carRentalPreferences,

                            needsTransfer: false,
                            transferPreferences: transferPreferences,
                            transferDetails: {
                                transfers: [],
                                specialRequests: '',
                            },
                            transferTransactionDetails: {
                                receiptDate: null,
                                receiptAmount: null,
                                currency: null,
                                uploadedAttachment: null,
                                downloadAttachmentId: null,
                                downloadAttachmentName: 'ground-travel-attachment',
                            },
                            needsOther: false,
                            otherDetails: {
                                specialRequests: '',
                            },
                            otherTransactionDetails: {
                                receiptDate: null,
                                receiptAmount: null,
                                currency: null,
                                uploadedAttachment: null,
                                downloadAttachmentId: null,
                                downloadAttachmentName: 'other-travel-attachment',
                            },
                        },
                    })
                );
            }
            this.newTravelCard.next();
            const accompanyingCaregivers_None$ =
                this.requestFormGroup.controls.travelCard.controls.accompanyingCaregivers.controls.none.controls.checked.valueChanges.pipe(
                    takeUntil(this.newTravelCard),
                    map((value) => {
                        let accompanyingCaregiversValue =
                            this.requestFormGroup.controls.travelCard.controls.accompanyingCaregivers.getRawValue();
                        if (value) {
                            accompanyingCaregiversValue.primaryCaregiver.checked = false;
                            accompanyingCaregiversValue.otherCaregivers.forEach((c) => (c.checked = false));
                        }
                        return accompanyingCaregiversValue;
                    })
                );
            const accompanyingCaregivers_Any$ = merge(
                this.requestFormGroup.controls.travelCard.controls.accompanyingCaregivers.controls.primaryCaregiver
                    .controls.checked.valueChanges,
                ...this.requestFormGroup.controls.travelCard.controls.accompanyingCaregivers.controls.otherCaregivers.controls.map(
                    (c) => c.controls.checked.valueChanges
                )
            ).pipe(
                takeUntil(this.newTravelCard),
                map((value) => {
                    let accompanyingCaregiversValue =
                        this.requestFormGroup.controls.travelCard.controls.accompanyingCaregivers.getRawValue();
                    accompanyingCaregiversValue.none.checked = ![
                        accompanyingCaregiversValue.primaryCaregiver.checked,
                        ...accompanyingCaregiversValue.otherCaregivers.map((c) => c.checked),
                    ].includes(true);
                    return accompanyingCaregiversValue;
                })
            );

            merge(accompanyingCaregivers_None$, accompanyingCaregivers_Any$)
                .pipe(
                    takeUntil(this.newTravelCard),
                    startWith(this.requestFormGroup.controls.travelCard.controls.accompanyingCaregivers.getRawValue()),
                    tap((value) => {
                        this.requestFormGroup.controls.travelCard.controls.accompanyingCaregivers.setValue(value, {
                            emitEvent: false,
                        });
                    }),
                    map((value) => {
                        if (value.none.checked) return [];
                        else
                            return [value.primaryCaregiver, ...value.otherCaregivers]
                                .filter((c) => c.checked)
                                .map((c) => c.value.caregiver);
                    }),
                    tap((caregivers) => {
                        const accompanyingCaregiverTravelControls = [
                            ...this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.flightDetails.controls.caregivers.controls.filter(
                                (caregiverControl) =>
                                    caregivers.some(
                                        (caregiver) => caregiver.id === caregiverControl.getRawValue().caregiver.id
                                    )
                            ),
                            ...this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.trainDetails.controls.caregivers.controls.filter(
                                (caregiverControl) =>
                                    caregivers.some(
                                        (caregiver) => caregiver.id === caregiverControl.getRawValue().caregiver.id
                                    )
                            ),
                        ];
                        const nonAccompanyingCaregiverTravelControls = [
                            ...this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.flightDetails.controls.caregivers.controls.filter(
                                (caregiverControl) =>
                                    !caregivers.some(
                                        (caregiver) => caregiver.id === caregiverControl.getRawValue().caregiver.id
                                    )
                            ),
                            ...this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.trainDetails.controls.caregivers.controls.filter(
                                (caregiverControl) =>
                                    !caregivers.some(
                                        (caregiver) => caregiver.id === caregiverControl.getRawValue().caregiver.id
                                    )
                            ),
                        ];
                        accompanyingCaregiverTravelControls.forEach((control) =>
                            control.enable({ breakPersistence: true, onlySelf: true, emitEvent: false })
                        );
                        nonAccompanyingCaregiverTravelControls.forEach((control) =>
                            control.disable({ persistent: true, onlySelf: true, emitEvent: false })
                        );
                    })
                )
                .subscribe();

            this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.flightDetails.controls.caregivers.controls.forEach(
                (caregiverControl) =>
                    combineLatest({
                        bookIdenticalTrip: caregiverControl.controls.details.controls.bookIdenticalTrip.valueChanges,
                        travelType:
                            caregiverControl.controls.details.controls.flightDetails.controls.travelType.valueChanges,
                    })
                        .pipe(
                            takeUntil(this.newTravelCard),
                            tap((result) => {
                                const needsAirTravel =
                                    result.bookIdenticalTrip || result.travelType !== TravelType.NO_FLIGHT;
                                const relatedControl = [
                                    this.requestFormGroup.controls.travelCard.controls.accompanyingCaregivers.controls
                                        .primaryCaregiver,
                                    ...this.requestFormGroup.controls.travelCard.controls.accompanyingCaregivers
                                        .controls.otherCaregivers.controls,
                                ].find(
                                    (control) =>
                                        control.getRawValue().value.caregiver.id ===
                                        caregiverControl.getRawValue().caregiver.id
                                );
                                if (relatedControl) {
                                    let caregiverValue = relatedControl.controls.value.getRawValue();
                                    caregiverValue.bookable = needsAirTravel;
                                    relatedControl.controls.value.setValue(caregiverValue, { emitEvent: false });
                                }
                            })
                        )
                        .subscribe()
            );

            //group observable that enables/disables formcontrols returned from it
            const formValue = this.requestFormGroup.getRawValue();
            merge(
                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.needsAirTravel.valueChanges.pipe(
                    takeUntil(this.newTravelCard),
                    startWith(formValue.travelCard.travelDetails.needsAirTravel),
                    map((value) => {
                        return {
                            value,
                            controls: <PersistentFormGroup[]>[
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.flightDetails,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .flightPreferences,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .flightTransactionDetails,
                            ],
                        };
                    })
                ),
                ...this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.flightDetails.controls.caregivers.controls.map(
                    (c) =>
                        c.controls.details.controls.bookIdenticalTrip.valueChanges.pipe(
                            takeUntil(this.newTravelCard),
                            startWith(c.getRawValue().details.bookIdenticalTrip),
                            map((bookIdenticalTrip) => {
                                return {
                                    value: !bookIdenticalTrip,
                                    controls: <PersistentFormGroup[]>[c.controls.details.controls.flightDetails],
                                };
                            })
                        )
                ),
                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.needsTrainTravel.valueChanges.pipe(
                    takeUntil(this.newTravelCard),
                    startWith(formValue.travelCard.travelDetails.needsTrainTravel),
                    map((value) => {
                        return {
                            value,
                            controls: <PersistentFormGroup[]>[
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.trainDetails,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .trainPreferences,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .trainTransactionDetails,
                            ],
                        };
                    })
                ),
                ...this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.trainDetails.controls.caregivers.controls.map(
                    (c) =>
                        c.controls.details.controls.bookIdenticalTrip.valueChanges.pipe(
                            takeUntil(this.newTravelCard),
                            startWith(c.getRawValue().details.bookIdenticalTrip),
                            map((bookIdenticalTrip) => {
                                return {
                                    value: !bookIdenticalTrip,
                                    controls: <PersistentFormGroup[]>[c.controls.details.controls.trainDetails],
                                };
                            })
                        )
                ),
                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.needsLodging.valueChanges.pipe(
                    takeUntil(this.newTravelCard),
                    startWith(formValue.travelCard.travelDetails.needsLodging),
                    map((value) => {
                        return {
                            value,
                            controls: <PersistentFormGroup[]>[
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .lodgingDetails,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .lodgingPreferences,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .lodgingTransactionDetails,
                            ],
                        };
                    })
                ),
                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.needsCarRental.valueChanges.pipe(
                    takeUntil(this.newTravelCard),
                    startWith(formValue.travelCard.travelDetails.needsCarRental),
                    map((value) => {
                        return {
                            value,
                            controls: <PersistentFormGroup[]>[
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .carRentalDetails,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .carRentalPreferences,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .carRentalTransactionDetails,
                            ],
                        };
                    })
                ),
                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.needsTransfer.valueChanges.pipe(
                    takeUntil(this.newTravelCard),
                    startWith(formValue.travelCard.travelDetails.needsTransfer),
                    map((value) => {
                        return {
                            value,
                            controls: <PersistentFormGroup[]>[
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .transferDetails,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .transferPreferences,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .transferTransactionDetails,
                            ],
                        };
                    })
                ),
                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.needsOther.valueChanges.pipe(
                    takeUntil(this.newTravelCard),
                    startWith(formValue.travelCard.travelDetails.needsOther),
                    map((value) => {
                        return {
                            value,
                            controls: <PersistentFormGroup[]>[
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls.otherDetails,
                                this.requestFormGroup.controls.travelCard.controls.travelDetails.controls
                                    .otherTransactionDetails,
                            ],
                        };
                    })
                )
            )
                .pipe(
                    takeUntil(this.newTravelCard),
                    filter((result) => !this.requestFormGroup.controls.travelCard.disabled),
                    tap((result) => {
                        result.controls.forEach((control) =>
                            result.value
                                ? control.enable({ breakPersistence: true, onlySelf: true, emitEvent: false })
                                : control.disable({ persistent: true, onlySelf: true, emitEvent: false })
                        );
                    })
                )
                .subscribe();

            result.isUpdate
                ? this.requestFormGroup.controls.travelCard.disable({ emitEvent: false })
                : this.requestFormGroup.controls.travelCard.enable({ emitEvent: false });
        }),
        map(([result, historyData]) => {
            const formGroupValue = this.requestFormGroup.getRawValue();
            return {
                historyData,
                countryOptions: result.countries.map(
                    (country) =>
                        <utility.MdsOptionGeneric<CountryViewModel>>{ value: country, viewValue: country.viewValue }
                ),
                currencyOptions: result.currencies.map(
                    (currency) =>
                        <utility.MdsOptionGeneric<CurrencyViewModel>>{
                            value: currency,
                            viewValue: currency.isocode,
                        }
                ),
                timeOptions: result.timeOptions,
                patientBookable:
                    formGroupValue.travelCard.travelDetails.needsAirTravel ||
                    formGroupValue.travelCard.travelDetails.needsLodging ||
                    formGroupValue.travelCard.travelDetails.needsCarRental,
                isUpdate: result.isUpdate,
            };
        }),
        tap((_) => this.loadingService.closeLoading({ force: true })) // break persistence
    );

    constructor(
        public dialog: MatDialog,
        private requestServices: AdminRequestServices,
        private router: Router,
        private requestStatusService: RequestStatusService,
        private attachmentSizeService: AttachmentSizeService,
        private loadingViewTriggerService: LoadingViewTriggerService,
        private snackbarService: SnackbarService,
        private requestStateService: RequestStateService,
        private studyStateService: StudyStateService,
        private siteStateService: SiteStateService,
        private patientStateService: PatientStateService,
        private activeRoute: ActivatedRoute,
        private datePipe: DatePipe,
        private renderer: Renderer2,
        private userService: UserService,
        private eventService: EventService,
        private countryStateService: CountryStateService,
        private currencyStateService: CurrencyStateService,
        private timeOptionsStateService: TimeOptionsStateService,
        private fb: FormBuilder,
        private historyDataService: HistoryDataService,
        private getThereService: GetThereService
    ) {
        this.requestFormGroup = fb.group({
            travelCard: <TravelCardFormGroup>null,
            ExpenseDetails: <AbstractControl>null,
            VisitDetails: <AbstractControl>null,
        });

        if (this.activeRoute.snapshot.params?.requestId) this.isNewRequest = false;
    }
    ngOnInit(): void {
        combineLatest({
            request: this.request$,
            patient: this.patient$,
            site: this.site$,
            study: this.study$,
            visitTypes: this.visitTypes$,
        })
            .pipe(
                tap(({ request, patient, site, study, visitTypes }) => {
                    if (request) this.request = request;
                    if (patient) this.patient = patient;
                    if (site) {
                        this.site = site;
                        this.siteServices = site.services;
                    }
                    if (study) {
                        this.study = study;
                        this.studyTravelOptions = this.study.studyInfo.studyTravelOptions;
                    }
                    if (visitTypes) this.visitTypes = visitTypes;
                    if (study?.studyInfo?.expenseTypes)
                        this.expenseTypes = this.mapExpenseTypesToMdsOptionArray(study.studyInfo.expenseTypes);
                })
            )
            .subscribe();

        this.statusConstants = this.requestStatusService.getStatuses();
        this.isCompletable = true;
        this.attachmentSizeService.flushAttachmentSize();

        this.ObserveFileSizeLimit();
        this.ObserveVisitDetailsFormGroup();
    }

    private ObserveVisitDetailsFormGroup() {
        this.createRequestVisitDetailsSubject
            .pipe(
                tap((visitDetailsComponent) => {
                    if (visitDetailsComponent) {
                        visitDetailsComponent.formGroupChangeEvent
                            .pipe(
                                tap((event) => {
                                    this.requestFormGroup.setControl('VisitDetails', event);
                                }),
                                takeUntil(this.componentDestroyed$)
                            )
                            .subscribe();
                    }
                }),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe();
    }
    public onEditVisitDetailsSave(data: MedpaceEditRequestVisitDetailsComponent.Data) {
        this.addEditRequestVisitDetails_Data();
        this.submitIncrementalSave();
    }
    ngOnDestroy() {
        this.historyDataService.clearRepositoryCache();
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.site && changes.site.currentValue) {
            if (changes.siteServices && changes.siteServices.currentValue) {
                this.siteServices = changes.siteServices.currentValue;
                const services = this.siteServices;
                services.travel ? (this.travelAvailable = true) : (this.travelAvailable = false);
                services.reimbursement ? (this.reimbursementAvailable = true) : (this.reimbursementAvailable = false);
                services.stipend ? (this.stipendAvailable = true) : (this.stipendAvailable = false);
                this.stipendAvailable || this.reimbursementAvailable
                    ? (this.paymentAvailable = true)
                    : (this.paymentAvailable = false);
            }
        }
        if (!this.request) {
            this.steps = this.defaultsteps;
        }

        if (changes.patient && changes.patient.currentValue) {
            this.patient = changes.patient.currentValue;
        }

        if (changes.request && changes.request.currentValue) {
            if (changes.request.currentValue.status === undefined) {
                changes.request.currentValue.status = 0;
            }
        }

        this.maxSummaryFileSizesInMB = maxSizeOfFilesInMB;
        this.componentDisplayHandler();
    }

    ngDoCheck(): void {
        this.componentDisplayHandler();
        this.updateStatusName();
    }

    componentDisplayHandler(): void {
        if (this.request === null || this.request === undefined) {
            this.request = <GenericRequest>{
                travelRequests: <TravelAccommodations>{},
                status: 0,
            };
            this.showPaymentCard = false;
            this.showTravelCards = false;
        }
        if (
            this.request.type === 'payment' ||
            this.request.type === 'reimbursement' ||
            this.request.type === 'stipend'
        ) {
            this.showPaymentCard = true;
            this.showTravelCards = false;
            this.steps[3] = this.visitDetailsStep;
            this.steps[4] = this.paymentStep;
        }
        if (this.request.type === 'travel') {
            this.showPaymentCard = false;

            this.showTravelCards = true;
            this.steps[3] = this.visitDetailsStep;
            this.steps[4] = this.travelStep;
        }
    }

    @HostListener('window:scroll', ['$event'])
    handleScroll(event: any) {
        this.updateStatusName();
    }

    submitIncrementalSave(specificCase?: boolean) {
        if (this.allowIncrementalSave(specificCase)) {
            this.validationMessage = '';
            this.incrementalSave.emit(this.request);
        }
    }

    allowIncrementalSave(specificCase?: boolean) {
        return this.isAdmin || (!this.isAdmin && this.request.status <= 2) || specificCase;
    }

    saveDraftClick(): void {
        if (this.isButtonSubmitActive) {
            const disabledControls = getDisabledControls(this.requestFormGroup);
            enableControls(this.requestFormGroup, disabledControls);
            if (this.request.status === GenericRequestStatus.New) this.request.status = GenericRequestStatus.Draft;

            if (this.requestFormGroup.valid) {
                if (this.request.type === 'payment') {
                    this.request.reimbursement = this._expenseDetails.getOutputData();
                    this.request.paymentType = this.request.reimbursement.type;
                }
                if (this.request.type === 'travel') {
                    this.request = this.applyTravelCardData(
                        this.request,
                        this.requestFormGroup.controls.travelCard.getRawValue()
                    );
                    this.loadingService.openLoading({ persistent: false }); //no persistent loading, the user will be redirected
                }
                this.isUpdate ? this.addEditRequestVisitDetails_Data() : this.addCreateRequestVisitDetails_Data();

                this.saveClickEvent.emit(this.request);
            } else {
                utility.markFormGroupTouched(this.requestFormGroup);
                if (this.isUpdate && this.requestFormGroup.invalid) {
                    utility.buildSnackBar(utility.collectErrors(this.requestFormGroup), this.snackbarService);
                }
            }
            disableControls(this.requestFormGroup, disabledControls);
        }
    }
    submitRequest(): void {
        //need to check the expense attachments
        if (this.isButtonSubmitActive) {
            if (this.requestFormGroup.valid) {
                const dialogRef = this.dialog.open(MedpaceAdditionalDetailsModalComponent, {
                    disableClose: true,
                    width: '500px',
                });
                dialogRef.afterClosed().subscribe((result) => {
                    if (result && result.save) {
                        if (
                            this.request.status === GenericRequestStatus.Draft ||
                            this.request.status === GenericRequestStatus.PendingSiteVerification
                        ) {
                            this.request.status = GenericRequestStatus.New;
                        }

                        this.request.additionalDetails = result.info;

                        if (this.request.type === 'payment') {
                            this.request.reimbursement = this._expenseDetails.getOutputData();
                            this.request.paymentType = this.request.reimbursement.type;
                        }
                        if (this.request.type === 'travel') {
                            this.request = this.applyTravelCardData(
                                this.request,
                                this.requestFormGroup.controls.travelCard.getRawValue()
                            );
                            this.loadingService.openLoading({ persistent: false }); //no persistent loading, the user will be redirected
                        }
                        this.isUpdate
                            ? this.addEditRequestVisitDetails_Data()
                            : this.addCreateRequestVisitDetails_Data();

                        this.submitClickEvent.emit(this.request);
                    }
                });
            } else {
                utility.markFormGroupTouched(this.requestFormGroup);
            }
        }
    }

    private addEditRequestVisitDetails_Data() {
        const data = this.editRequestVisitDetailsSubject.getValue().data;
        if (data.requestType === RequestTypes.PAYMENT) {
            this.request.visitDetails.visitStartDate = data.paymentRequest_Data.visitDate;
            this.request.visitDetails.visitName = data.paymentRequest_Data.visitName;
            this.request.visitDetails.visitNameId = data.paymentRequest_Data.visitName.id;
        } else if (data.requestType === RequestTypes.TRAVEL) {
            this.request.visitDetails.travelerInstructions = data.travelRequest_Data.specialNavigationInstructions;
            this.request.visitDetails.visitName = data.travelRequest_Data.visitName;
            this.request.visitDetails.visitNameId = data.travelRequest_Data.visitName.id;

            this.request.visitDetails.visitStartDate = data.travelRequest_Data.visitStartDateTime;
            this.request.visitDetails.visitEndDate = data.travelRequest_Data.visitEndDateTime;
            this.request.visitDetails.visitStartTime = this.datePipe.transform(
                data.travelRequest_Data.visitStartDateTime?.toDate(),
                'HH:mm',
                'utc',
                'en-US'
            );
            this.request.visitDetails.visitEndTime = this.datePipe.transform(
                data.travelRequest_Data.visitEndDateTime?.toDate(),
                'HH:mm',
                'utc',
                'en-US'
            );
        }
    }
    private addCreateRequestVisitDetails_Data() {
        const data = this.createRequestVisitDetailsSubject.getValue().data;
        this.request.visitDetails = new VisitDetails();
        if (data.requestType === RequestTypes.PAYMENT) {
            this.request.visitDetails.visitStartDate = data.paymentRequest_Data.visitDate;
            this.request.visitDetails.visitName = data.paymentRequest_Data.visitName;
            this.request.visitDetails.visitNameId = data.paymentRequest_Data.visitName.id;
        } else if (data.requestType === RequestTypes.TRAVEL) {
            this.request.visitDetails.travelerInstructions = data.travelRequest_Data.specialNavigationInstructions;
            this.request.visitDetails.visitName = data.travelRequest_Data.visitName;
            this.request.visitDetails.visitNameId = data.travelRequest_Data.visitName.id;

            this.request.visitDetails.visitStartDate = data.travelRequest_Data.visitStartDateTime;
            this.request.visitDetails.visitEndDate = data.travelRequest_Data.visitEndDateTime;
            this.request.visitDetails.visitStartTime = this.datePipe.transform(
                data.travelRequest_Data.visitStartDateTime?.toDate(),
                'HH:mm',
                'utc',
                'en-US'
            );
            this.request.visitDetails.visitEndTime = this.datePipe.transform(
                data.travelRequest_Data.visitEndDateTime?.toDate(),
                'HH:mm',
                'utc',
                'en-US'
            );
        }
    }
    completeRequest(): void {
        if (
            (this.siteServices.kycDocumentation == true && !this.patient.patientIdentification.KYCDocumentInfoId) ||
            (this.siteServices.readyForPayment == true && !this.patient.readyForPayment)
        ) {
            this.dialog.open(MedpaceRequestErrorModalComponent, { disableClose: true });
        } else {
            this.request.status = 3;
            this.submitClickEvent.emit(this.request);
        }
    }

    changeStatus(): void {
        if (
            this.request.type !== 'travel' &&
            this.siteServices.readyForPayment == true &&
            !this.patient.readyForPayment
        ) {
            this.isCompletable = false;
        }
        const dialogRef = this.dialog.open(MedpaceRequestChangeModalComponent, {
            data: {
                request: this.request,
                completable: this.isCompletable,
            },
            width: '500px',
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let requestDTO: RequestStatusDTO = <RequestStatusDTO>{};
                this.request.additionalDetails = result.info;

                requestDTO.request = this.request;
                requestDTO.newStatus = result.status;

                this.statusChangeEvent.emit(requestDTO);
            }
        });
    }

    showSwitchModeButtonInVisitDetailsCard(isAdmin: boolean, isSuperAdmin: boolean): boolean {
        if (
            !!this.request &&
            this.request.status !== GenericRequestStatus.Completed &&
            this.isUpdate &&
            (isAdmin ||
                isSuperAdmin ||
                this.request.status === GenericRequestStatus.Draft ||
                this.request.status === GenericRequestStatus.Submitted ||
                this.request.status === GenericRequestStatus.PendingSiteVerification)
        )
            return true;

        return false;
    }

    viewHistory(): void {
        this.requestStatusLog = [];
        let request;
        if (this.request.type === 'travel') {
            this.loadingViewTriggerService.openLoading();
            request = this.requestServices.getTravelRequestLog(this.request.id);
        } else if (
            this.request.type === 'payment' ||
            this.request.type === 'stipend' ||
            this.request.type === 'out-of-pocket'
        ) {
            if (this.request.paymentType === 'stipend') {
                request = this.requestServices.getStipendRequestLog(this.request.id);
            } else if (this.request.paymentType === 'out-of-pocket') {
                request = this.requestServices.getReimbursementRequestLog(this.request.id);
            }
        }

        request.subscribe((result) => {
            this.requestStatusLog = []; //to be destroyed
            result.forEach((r) => {
                const logEntry = {
                    requestApprovalStatusId: this.statusConstants.find((x) => x.value === r.requestApprovalStatusId)
                        .viewValue,
                    timestamp: r.timestamp,
                    responsibleUserName: !this.isAdmin && r.anonymized ? 'CRC User' : r.responsibleUserName,
                    details: r.details,
                };
                this.requestStatusLog.push(logEntry);
            });
            this.loadingViewTriggerService.closeLoading();
            this.dialog.open(MedpaceViewHistoryModalComponent, {
                data: this.requestStatusLog,
                width: '80%',
                disableClose: true,
            });
        });
    }

    deleteRequest(): void {
        const dialogRef = this.dialog.open(MedpaceDeleteRequestModalComponent, {
            data: this.request,
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
            let response;
            if (result) {
                if (this.request.type === 'travel') {
                    response = this.requestServices.deleteTravelRequest(this.request.id);
                } else if (this.request.type === 'stipend' || this.request.paymentType === 'stipend') {
                    response = this.requestServices.deleteStipendRequest(this.request.id);
                } else if (this.request.type === 'reimbursement' || this.request.paymentType === 'out-of-pocket') {
                    response = this.requestServices.deleteReimbursementRequest(this.request.id);
                }

                response.subscribe((result) => {
                    this.router
                        .navigate([`studies/${this.study.id}/sites/${this.site.id}/patients/${this.patient.id}`])
                        .then((navigated: boolean) => {
                            if (navigated) {
                                this.snackbarService.openInfoSnackbar('Deleted Request');
                            }
                        });
                });
            }
        });
    }

    fieldChangeEvent(event: InputChange): void {
        if (event.target === 'requestType' && event.value === 'travel') {
            this.request.type = 'travel';
            this.componentDisplayHandler();
            this.steps[4] = this.paymentStep;
            this.requestTypeSubject.next(RequestTypes.TRAVEL);
        }

        if (event.target === 'requestType' && event.value === 'payment') {
            this.request.type = 'payment';
            this.componentDisplayHandler();
            this.steps[4] = this.travelStep;
            this.requestTypeSubject.next(RequestTypes.PAYMENT);
        }
        if (event.target === 'flightTransDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'flightTransAmount') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'flightTransCurrency') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'flightDoc') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'trainTransDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'trainCost') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'trainCurrency') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'trainDoc') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'lodgingCheckInDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'lodgingCheckOutDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'lodgingDateRequired') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'lodgingTransDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'lodgingCost') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'lodgingCurrency') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'lodgingDoc') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'groundTransDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'groundCost') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'groundCurrency') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'carService') {
            const transferData = event.value.transferDatas as {
                pickupDate: Moment;
                pickupTime: number;
                pickupAddress: string;
                dropoffAddress: string;
            }[];
            this.request.travelRequests.transferDatas = transferData.map(
                (data) =>
                    <TransferData>{
                        dropoffAddress: data.dropoffAddress ?? '',
                        pickupAddress: data.pickupAddress ?? '',
                        pickupDateTime:
                            data.pickupDate && data.pickupTime !== null && data.pickupTime !== undefined
                                ? data.pickupDate.add(data.pickupTime, 'minutes')
                                : null,
                    }
            );
        }
        if (event.target === 'otherTransDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'otherCost') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'otherCurrency') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'otherDoc') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'otherRequests') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'needsRentalCar') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarRequests') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarCost') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarCurrency') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarDoc') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarPickUpTime') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarDropOffTime') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarPickUpDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarDropOffDate') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarCountry') {
            this.request.travelRequests[event.target] = event.value;
        }
        if (event.target === 'rentalCarLocation') {
            this.request.travelRequests[event.target] = event.value;
        }

        if (event.target.indexOf('needsAir') !== -1) {
            if (event.target === 'needsAirTravel') {
                if (!this.request.travelRequests) {
                    this.request.travelRequests = <TravelAccommodations>{};
                }
                this.request.travelRequests.needsAirTravel = event.value;
            }
            if (event.target === 'needsAir' && this.request.travelRequests.needsAirTravel) {
                this.request.travelRequests.flightRequests = event.value;
            }
        }
        if (event.target.indexOf('needsTrain') !== -1) {
            if (event.target === 'needsTrainTravel') {
                if (!this.request.travelRequests) {
                    this.request.travelRequests = <TravelAccommodations>{};
                }
                this.request.travelRequests.needsTrainTravel = event.value;

                if (event.value === false) {
                    this.request.travelRequests.trainTravelDetailsForPatient = null;
                    if (this.request.travelRequestCaregivers) {
                        for (const caregiver of this.request.travelRequestCaregivers) {
                            caregiver.trainTravelDetails = null;
                        }
                    }
                }
            }

            if (event.target === 'needsTrain' && this.request.travelRequests.needsTrainTravel) {
                this.request.travelRequests.trainRequests = event.value;
            }
        }
        if (event.target.indexOf('needsLodging') !== -1) {
            if (event.target === 'needsLodging') {
                if (!this.request.travelRequests) {
                    this.request.travelRequests = <TravelAccommodations>{};
                }
                this.request.travelRequests.needsLodging = event.value;
            }
            if (event.target === 'needsLodgingAccess' && this.request.travelRequests.needsLodging) {
                this.request.travelRequests.lodgingRequests = event.value;
            }
        }
        if (event.target.indexOf('needsGround') !== -1) {
            if (event.target === 'needsGroundTransportation') {
                if (!this.request.travelRequests) {
                    this.request.travelRequests = <TravelAccommodations>{};
                }
                this.request.travelRequests.needsCar = event.value;
            }
            if (event.target === 'needsGround' && this.request.travelRequests.needsCar) {
                this.request.travelRequests.carServiceRequests = event.value;
            }
        }

        if (event.target.indexOf('rentalCarRequest') !== -1) {
            if (event.target === 'needsRentalCar') {
                if (!this.request.travelRequests) {
                    this.request.travelRequests = <TravelAccommodations>{};
                }
                this.request.travelRequests.needsRentalCar = event.value;
            }
            if (event.target === 'rentalCarRequest' && this.request.travelRequests.needsRentalCar) {
                this.request.travelRequests.rentalCarRequests = event.value;
            }
        }

        if (event.target.indexOf('needsOther') !== -1) {
            if (event.target === 'needsOtherAccomodations') {
                if (!this.request.travelRequests) {
                    this.request.travelRequests = <TravelAccommodations>{};
                }
                this.request.travelRequests.needsOther = event.value;
            }
            if (event.target === 'needsOther' && this.request.travelRequests.needsCar) {
                this.request.travelRequests.otherRequests = event.value;
            }
        }
        if (event.target === 'hasCaregiverTravelling') {
            this.request.caregiver = event.value;
        }
        if (event.target === 'minorPatient') {
            this.request.isMinor = event.value;
        }
        if (event.target === 'travelCaregiver') {
            if (!this.request.travelRequestCaregivers) {
                this.request.travelRequestCaregivers = [];
            }

            const eventValue = event.value as TravelRequestCaregiver;

            if (eventValue.isChecked) {
                this.request.travelRequestCaregivers.push(eventValue);
            } else {
                const foundCaregiverIndex = this.request.travelRequestCaregivers.findIndex(
                    (t) => t.caregiverId === eventValue.caregiverId
                );
                if (foundCaregiverIndex > -1) {
                    if (this.request.travelRequestCaregivers[foundCaregiverIndex].travelRequestId) {
                        this.requestServices
                            .deleteTravelRequestCaregiver(
                                this.request.travelRequestCaregivers[foundCaregiverIndex].travelRequestId,
                                this.request.travelRequestCaregivers[foundCaregiverIndex].caregiverId
                            )
                            .subscribe(() => this.request.travelRequestCaregivers.splice(foundCaregiverIndex, 1));
                    } else {
                        this.request.travelRequestCaregivers.splice(foundCaregiverIndex, 1);
                    }
                }
            }
            this.eventService.emit(new EmittedEvent('travelCaregiversChanged', null));
        }

        if (event.target == 'trainTravelCaregivers') {
            const caregiversTrainTravels = event.value as TrainTravelDetailsFormModel[];

            for (const trainTravelPlan of caregiversTrainTravels) {
                const caregiverInRequest = this.request.travelRequestCaregivers.find(
                    (x) => x.caregiverId == trainTravelPlan.caregiverId
                );
                if (!caregiverInRequest)
                    throw Error('Assertion failed: caregiver list should be in sync in both component');

                caregiverInRequest.trainTravelDetails = mapTrainTravelFormModelToTrainTravelDetails(trainTravelPlan);

                if (
                    caregiverInRequest.trainTravelDetails.isSameAsPatient ||
                    caregiverInRequest.trainTravelDetails.trainTravelType == TrainTravelType.NoTravel
                ) {
                    // don't send data from the hidden/irrelevant parts of the form to the backend, as it might trigger validation errors
                    caregiverInRequest.trainTravelDetails.departureDate = null;
                    caregiverInRequest.trainTravelDetails.returnDate = null;
                    caregiverInRequest.trainTravelDetails.specialRequests = null;
                    caregiverInRequest.trainTravelDetails.trainStationOrigin = null;
                    caregiverInRequest.trainTravelDetails.trainStationDestination = null;
                }
            }
        }

        if (event.target == 'trainTravelPatient') {
            const patientTrainTravelPlan = event.value as TrainTravelDetailsFormModel;

            if (patientTrainTravelPlan.trainFormInLegacyMode) {
                this.request.travelRequests.trainRequests = patientTrainTravelPlan.trainSpecialRequests;
            } else {
                this.request.travelRequests.trainTravelDetailsForPatient =
                    mapTrainTravelFormModelToTrainTravelDetails(patientTrainTravelPlan);
            }
        }

        this.requestChangeEvent.emit(this.request);
        this.updateStatusName();
    }

    updateStatusName() {
        if (this.request.status != 0) {
            this.actualStatus = this.statusConstants.filter((val) => val.value === this.request?.status)[0]?.viewValue;
        }
    }

    handlePaymentChange(event: any) {
        this.request.paymentType = event.target;
        this.request.reimbursement = event.value;
    }
    public onPaymentRequestExpenseDetailsSave(reimbursement: Reimbursement) {
        this.request.reimbursement = reimbursement;
        this.request.paymentType = this.request.reimbursement.type;
        this.submitIncrementalSave();
    }

    patientNavigation(event: any) {
        this.patientNavigationRequestEvent.emit(event);
    }

    checkStatusOption(chosenOption: any) {
        switch (chosenOption) {
            case 'Change Status':
                this.changeStatus();
                break;
            case 'View History':
                this.viewHistory();
                break;
            case 'Delete Request':
                this.deleteRequest();
                break;
            default:
                console.error('Status not recognized!');
                break;
        }
    }

    private ObserveFileSizeLimit() {
        this.attachmentSizeService
            .AreFileSizesInLimit()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((IsLimitExceeded) => (this.isButtonSubmitActive = IsLimitExceeded))
            )
            .subscribe();
    }
    public isUpdateAndAdmin(user: User): boolean {
        return this.isUpdate && (user.isAdmin || user.isSuperAdmin);
    }
    public isRequestStatus(...statuses: number[]): boolean {
        return !!this.request && statuses.includes(this.request.status);
    }
    public travelCardSave(data: utility.FormGroupValueOf<TravelCardFormGroup>) {
        this.request = this.applyTravelCardData(this.request, data);
        this.loadingService.openLoading({ persistent: true }); // persistent loading to prevent loading.interceptor from disabling it when the GET request arrives
        this.submitIncrementalSave();
    }
    private applyTravelCardData(request: GenericRequest, data: utility.FormGroupValueOf<TravelCardFormGroup>) {
        const patientFlightDetails = data.travelDetails.flightDetails.patientFlightDetails;
        const patientTrainDetails = data.travelDetails.trainDetails.patientTrainTripDetails;
        const patientLodgingDetails = data.travelDetails.lodgingDetails;
        const patientCarRentalDetails = data.travelDetails.carRentalDetails;
        const patientTransferDetails = data.travelDetails.transferDetails;
        this.countryStateService
            .getCountries()
            .pipe(take(1))
            .subscribe((countries) => {
                request = {
                    ...request,

                    travelRequests: {
                        ...request.travelRequests,
                        needsAirTravel: data.travelDetails.needsAirTravel,

                        needsTrainTravel: data.travelDetails.needsTrainTravel,

                        needsLodging: data.travelDetails.needsLodging,

                        needsRentalCar: data.travelDetails.needsCarRental,

                        needsCar: data.travelDetails.needsTransfer,

                        needsOther: data.travelDetails.needsOther,
                    },
                    travelRequestCaregivers: [
                        data.accompanyingCaregivers.primaryCaregiver,
                        ...data.accompanyingCaregivers.otherCaregivers,
                    ]
                        .filter((caregiver) => caregiver.checked)
                        .map((c) => {
                            const flightDetailsForCaregiver = data.travelDetails.flightDetails.caregivers.find(
                                (caregiver) => caregiver.caregiver.id === c.value.caregiver.id
                            );

                            // null value = caregiver doesn't need a flight booked
                            let flightDetails: utility.FormGroupValueOf<SingleFlightFormGroup> = null;
                            if (flightDetailsForCaregiver?.details?.bookIdenticalTrip) {
                                flightDetails = {
                                    ...patientFlightDetails,
                                    id: null,
                                };
                            } else if (flightDetailsForCaregiver?.details?.flightDetails) {
                                flightDetails = flightDetailsForCaregiver.details.flightDetails;
                            }

                            const flightRequestDetails = !(
                                flightDetailsForCaregiver.details.bookIdenticalTrip ||
                                flightDetailsForCaregiver.details.flightDetails.travelType !== TravelType.NO_FLIGHT
                            )
                                ? null
                                : {
                                      airportDestination: flightDetails.airportDestination,
                                      airportOrigin: flightDetails.airportOrigin,
                                      departureDate: flightDetails.departureDate,
                                      returnDate: flightDetails.returnDate,
                                      specialRequests: flightDetails.specialRequests,
                                      travelType: flightDetails.travelType,
                                      id: flightDetails.id,
                                  };
                            const trainGeneralDetails = data.travelDetails.trainDetails.caregivers.find(
                                (caregiver) => caregiver.caregiver.id === c.value.caregiver.id
                            );
                            const trainDetails = !trainGeneralDetails?.details.bookIdenticalTrip
                                ? trainGeneralDetails.details.trainDetails
                                : patientTrainDetails;
                            const trainTravelDetails = !(
                                trainGeneralDetails.details.bookIdenticalTrip ||
                                trainGeneralDetails.details.trainDetails.travelType !== TrainTravelType.NoTravel
                            )
                                ? null
                                : {
                                      trainStationOrigin: trainDetails.stationOrigin,
                                      trainStationDestination: trainDetails.stationDestination,
                                      departureDate: trainDetails.departureDate,
                                      returnDate: trainDetails.returnDate,
                                      isSameAsPatient: !!trainGeneralDetails?.details.bookIdenticalTrip,
                                      specialRequests: trainDetails.specialRequests,
                                      trainTravelType: trainDetails.travelType,
                                      trainFormInLegacyMode: false,
                                  };
                            const travelRequestCaregiver: TravelRequestCaregiver = {
                                caregiverId: c.value.caregiver.id,
                                caregiverName: getCaregiverFullName(c.value.caregiver),
                                isChecked: c.checked,
                                isPrimary: c.value.caregiver.isPrimary,
                                flightRequestTheSameAsPatient: null,
                                flightRequestDetails: null,
                                flightRequestDetailsId: null,
                                trainTravelDetails: null,
                                travelRequestId: request.id,
                            };
                            if (data.travelDetails.needsAirTravel) {
                                travelRequestCaregiver.flightRequestTheSameAsPatient =
                                    !!flightDetailsForCaregiver?.details.bookIdenticalTrip;
                                travelRequestCaregiver.flightRequestDetails = flightRequestDetails;
                                travelRequestCaregiver.flightRequestDetailsId = flightRequestDetails
                                    ? flightRequestDetails.id
                                    : null;
                            }
                            if (data.travelDetails.needsTrainTravel) {
                                travelRequestCaregiver.trainTravelDetails = trainTravelDetails;
                            }
                            return travelRequestCaregiver;
                        }),
                };
                if (request.travelRequests.needsAirTravel) {
                    request.travelRequests.flightRequestDetails = {
                        airportOrigin: patientFlightDetails.airportOrigin,
                        airportDestination: patientFlightDetails.airportDestination,
                        departureDate: patientFlightDetails.departureDate,
                        returnDate: patientFlightDetails.returnDate,
                        specialRequests: patientFlightDetails.specialRequests,
                        travelType: patientFlightDetails.travelType,
                        id: patientFlightDetails.id,
                    };
                    request.travelRequests.flightRequestDetailsId = patientFlightDetails.id;
                    request.travelRequests.flightRequests = patientFlightDetails.specialRequests;

                    if (this.isUpdate) {
                        // transaction details are only available when updating existing request
                        request.travelRequests.flightTransDate =
                            data.travelDetails.flightTransactionDetails.receiptDate;
                        request.travelRequests.flightTransAmount =
                            +data.travelDetails.flightTransactionDetails.receiptAmount;
                        request.travelRequests.flightTransCurrency =
                            data.travelDetails.flightTransactionDetails.currency?.isocode;
                        request.travelRequests.flightDoc =
                            data.travelDetails.flightTransactionDetails.uploadedAttachment;
                    }
                }
                if (request.travelRequests.needsTrainTravel) {
                    request.travelRequests.trainTravelDetailsForPatient = {
                        trainStationOrigin: patientTrainDetails.stationOrigin,
                        trainStationDestination: patientTrainDetails.stationDestination,
                        departureDate: patientTrainDetails.departureDate,
                        returnDate: patientTrainDetails.returnDate,
                        isSameAsPatient: true,
                        specialRequests: patientTrainDetails.specialRequests,
                        trainTravelType: patientTrainDetails.travelType,
                        trainFormInLegacyMode: false,
                    };
                    request.travelRequests.trainRequests = patientTrainDetails.specialRequests;

                    if (this.isUpdate) {
                        request.travelRequests.trainCost = +data.travelDetails.trainTransactionDetails.receiptAmount;
                        request.travelRequests.trainCurrency =
                            data.travelDetails.trainTransactionDetails.currency?.isocode;
                        request.travelRequests.trainTransDate = data.travelDetails.trainTransactionDetails.receiptDate;
                        request.travelRequests.trainDoc = data.travelDetails.trainTransactionDetails.uploadedAttachment;
                    }
                }

                if (request.travelRequests.needsLodging) {
                    request.travelRequests.lodgingCheckInDate = patientLodgingDetails.checkInDate;
                    request.travelRequests.lodgingCheckOutDate = patientLodgingDetails.checkOutDate;
                    request.travelRequests.lodgingRequests = patientLodgingDetails.specialRequests;
                    request.travelRequests.lodgingDateRequired = false; // will not be used anymore

                    if (this.isUpdate) {
                        request.travelRequests.lodgingCost =
                            +data.travelDetails.lodgingTransactionDetails.receiptAmount;
                        request.travelRequests.lodgingCurrency =
                            data.travelDetails.lodgingTransactionDetails.currency?.isocode;
                        request.travelRequests.lodgingTransDate =
                            data.travelDetails.lodgingTransactionDetails.receiptDate;
                        request.travelRequests.lodgingDoc =
                            data.travelDetails.lodgingTransactionDetails.uploadedAttachment;
                    }
                }
                if (request.travelRequests.needsRentalCar) {
                    request.travelRequests.rentalCarLocation = patientCarRentalDetails.pickupAddress;
                    request.travelRequests.rentalCarCountry =
                        countries.find((country) => country.viewValue === patientCarRentalDetails.pickupCountry)
                            ?.countryCode ?? '';
                    request.travelRequests.rentalCarPickUpDate = patientCarRentalDetails.pickupDate;
                    request.travelRequests.rentalCarPickUpTime =
                        this.timeOptionsStateService.convertMinutesSinceMidnightToTimeString(
                            patientCarRentalDetails.pickupTime,
                            false //pass false instead of 'this.timeOptionsStateService.is24HourFormat', the data from backend will be a 12-hour format string like '5:15 am'
                        );
                    request.travelRequests.rentalCarDropOffDate = patientCarRentalDetails.dropOffDate;
                    request.travelRequests.rentalCarDropOffTime =
                        this.timeOptionsStateService.convertMinutesSinceMidnightToTimeString(
                            patientCarRentalDetails.dropOffTime,
                            false //this.timeOptionsStateService.is24HourFormat
                        );
                    request.travelRequests.rentalCarRequests = patientCarRentalDetails.specialRequests;

                    if (this.isUpdate) {
                        request.travelRequests.rentalCarCost =
                            +data.travelDetails.carRentalTransactionDetails.receiptAmount;
                        request.travelRequests.rentalCarCurrency =
                            data.travelDetails.carRentalTransactionDetails.currency?.isocode;
                        request.travelRequests.rentalCarDate =
                            data.travelDetails.carRentalTransactionDetails.receiptDate;
                        request.travelRequests.rentalCarDoc =
                            data.travelDetails.carRentalTransactionDetails.uploadedAttachment;
                    }
                }

                if (request.travelRequests.needsCar) {
                    request.travelRequests.carServiceRequests = patientTransferDetails.specialRequests;
                    request.travelRequests.transferDatas = patientTransferDetails.transfers.map(
                        (transfer) =>
                            <TransferData>{
                                ...transfer,
                                pickupDateTime: transfer.pickupDate?.startOf('day').add(transfer.pickupTime, 'minutes'),
                            }
                    );
                    if (this.isUpdate) {
                        request.travelRequests.groundCost =
                            +data.travelDetails.transferTransactionDetails.receiptAmount;
                        request.travelRequests.groundCurrency =
                            data.travelDetails.transferTransactionDetails.currency?.isocode;
                        request.travelRequests.groundTransDate =
                            data.travelDetails.transferTransactionDetails.receiptDate;
                        request.travelRequests.groundDoc =
                            data.travelDetails.transferTransactionDetails.uploadedAttachment;
                    }
                }

                if (request.travelRequests.needsOther) {
                    request.travelRequests.otherRequests = data.travelDetails.otherDetails.specialRequests;

                    if (this.isUpdate) {
                        request.travelRequests.otherCost = +data.travelDetails.otherTransactionDetails.receiptAmount;
                        request.travelRequests.otherCurrency =
                            data.travelDetails.otherTransactionDetails.currency?.isocode;
                        request.travelRequests.otherTransDate = data.travelDetails.otherTransactionDetails.receiptDate;
                        request.travelRequests.otherDoc = data.travelDetails.otherTransactionDetails.uploadedAttachment;
                    }
                }
            });

        return request;
    }
    loadGetThere(getThereUrlParams: GetThereInputParameters) {
        const getThereUrlParamsDto = mapToDTO(getThereUrlParams);
        const getThereUrl$ = this.getThereService.getUrl(getThereUrlParamsDto);

        getThereUrl$
            .pipe(
                switchMap((response) => {
                    if (response.warnings && response.warnings.length > 0) {
                        let warningMessage = 'Please note the following:\n\n<ul>';
                        const bulletPoints = response.warnings.map((warning) => `<li>${warning}</li>`).join('\n');
                        warningMessage += bulletPoints;
                        warningMessage += '</ul>';

                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = false;
                        dialogConfig.autoFocus = true;
                        dialogConfig.maxWidth = 650;
                        dialogConfig.minWidth = 350;
                        dialogConfig.data = {
                            title: 'Warning: some values will be truncated',
                            bodyText: warningMessage,
                            showCancelButton: true,
                        };

                        const dialog = this.dialog.open(MedpaceMessageModalComponent, dialogConfig);
                        return combineLatest<[CreateGetThereUrlViewModel, ModalResult, boolean]>([
                            of(response),
                            dialog.afterClosed(),
                            of(true),
                        ]);
                    } else {
                        return combineLatest<[CreateGetThereUrlViewModel, undefined, boolean]>([
                            of(response),
                            of(undefined),
                            of(false),
                        ]);
                    }
                })
            )
            .pipe(
                switchMap(([response, modalResult, didModalOccur]) => {
                    if ((didModalOccur && modalResult === ModalResult.Okay) || !didModalOccur) {
                        window.open(response.getThereUrl.url, '_blank', 'noopener');
                    }
                    return of(response);
                })
            )
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                error: (msg) => {
                    console.log('Error loading GetThere. Error message: ', msg);

                    this.dialog.open(DisplayErrorModalComponent, {
                        autoFocus: false,
                        width: '500px',
                        disableClose: false,
                        data:
                            'There was an error loading GetThere.\nPlease contact the system administrator for assistance. \n\nError Message:\n' +
                            msg,
                    });
                    throw msg;
                },
            });
    }

    /**
     *
     * @param studyCode
     * @param siteNumber
     * @param userId - user's id in the PCS database (the database-generated one). Do NOT use the Patient Number (e.g. 001-002) here, as it's considered a personal information and we must not use it.
     * @param userType - 'c' for caregiver, 'p' for patient - used to prevent situation where id for patient and caregiver might be identical
     * @returns
     */
    private generateUserId(studyCode: string, userId: number, userType: 'c' | 'p'): string {
        let id = `${studyCode}-${userId}-${userType}`.toLowerCase();
        // Realistic example: "tst-0109-693045-p" (17 characters)

        // GetThere's limit for user_id is 64 characters (64 is fine, 65 isn't).
        // For longer id we generate sha256 hash (64 characters)
        if (id.length > 64) {
            const hashedId = sha256().update(id).digest('hex');
            if (hashedId.length !== 64) {
                //should never happen, sha256 generates a string of length 64
                throw Error(`Hashed ID for GetThere should have length 64, got ${hashedId.length}`);
            }

            const warningMessage = `Traveler's user id exceeds GetThere's limit of 64 character. Generated hashed id '${hashedId}' from '${id}'`;
            console.warn(warningMessage);

            id = hashedId;
        }

        return id;
    }
    bookViaGetThereForCaregiver(caregiver: Caregiver) {
        const travelerId = this.generateUserId(this.patient.studyCode, caregiver.id, 'c');
        const getThereTravelerId = !caregiver
            ? null
            : <GetThereTravelerId>{
                  travelerId: travelerId,
                  firstName: caregiver.firstName,
                  middleName: caregiver.middleName,
                  lastName: caregiver.lastName,
                  emailAddress: caregiver.email,
                  country: caregiver.country,
                  address1: caregiver.address1,
                  address2: caregiver.address2,
                  city: caregiver.city,
                  state: caregiver.state,
                  zipcode: caregiver.zipcode,
                  phoneNumber: caregiver.phone,
                  gender: caregiver.gender,
                  birthDate: caregiver.birthDate,
                  studyCode: this.patient.studyCode,
                  siteNumber: this.patient.siteNumber,
                  patientId: caregiver.patientId.toString(),
              };
        const value = this.requestFormGroup.controls.travelCard.getRawValue();
        const caregiverDetails = value.travelDetails.flightDetails.caregivers.find(
            (c) => c.caregiver.id === caregiver.id
        );
        this.loadGetThere(
            this.getThereService.createGetThereURLParams(
                getThereTravelerId,
                caregiverDetails.details.bookIdenticalTrip ||
                    caregiverDetails.details.flightDetails.travelType !== TravelType.NO_FLIGHT,
                value.travelDetails.flightDetails.caregivers.find((c) => c.caregiver.id === caregiver.id).details
                    .flightDetails,
                value.travelDetails.needsLodging, // the rest is taken from patient
                value.travelDetails.lodgingDetails,
                value.travelDetails.needsCarRental,
                value.travelDetails.carRentalDetails,
                this.request.visitDetails
            )
        );
    }
    bookViaGetThereForPatient() {
        const travelerId = this.generateUserId(this.patient.studyCode, this.patient.id, 'p');
        const getThereTravelerId =
            !this.patient || !this.patient.patientIdentification
                ? null
                : <GetThereTravelerId>{
                      travelerId: travelerId,
                      firstName: this.patient.patientIdentification.firstName,
                      middleName: this.patient.patientIdentification.middleName,
                      lastName: this.patient.patientIdentification.lastName,
                      emailAddress: this.patient.patientIdentification.emailAddress,
                      country: this.patient.patientIdentification.country,
                      address1: this.patient.patientIdentification.address1,
                      address2: this.patient.patientIdentification.address2,
                      city: this.patient.patientIdentification.city,
                      state: this.patient.patientIdentification.state,
                      zipcode: this.patient.patientIdentification.zipcode,
                      phoneNumber: this.patient.patientIdentification.phoneNumber,
                      gender: this.patient.patientIdentification.gender,
                      birthDate: this.patient.patientIdentification.birthDate,
                      studyCode: this.patient.studyCode,
                      siteNumber: this.patient.siteNumber,
                      patientId: this.patient.patientIdentification.patientId,
                  };
        const value = this.requestFormGroup.controls.travelCard.getRawValue();
        this.loadGetThere(
            this.getThereService.createGetThereURLParams(
                getThereTravelerId,
                value.travelDetails.needsAirTravel,
                value.travelDetails.flightDetails.patientFlightDetails,
                value.travelDetails.needsLodging,
                value.travelDetails.lodgingDetails,
                value.travelDetails.needsCarRental,
                value.travelDetails.carRentalDetails,
                this.request.visitDetails
            )
        );
    }

    mapExpenseTypesToMdsOptionArray(expenseTypes: ExpenseType[]): MdsOption[] {
        let mappedExpenses = expenseTypes.map(
            (expense: ExpenseType): MdsOption => ({ value: expense.name, viewValue: expense.name })
        );
        if (!mappedExpenses.find((val) => val.viewValue.toLowerCase().includes('other'))) {
            //Add 'Other Expenses' as a fallback
            mappedExpenses = [...mappedExpenses, { value: ExpenseTypes.Other, viewValue: ExpenseTypes.Other }];
        }
        return mappedExpenses;
    }

    showEditButton(requestStatus: number, user: User): boolean {
        if (requestStatus === RequestStatusEnum.Draft || requestStatus === RequestStatusEnum.Completed) {
            return false;
        } else return this.isUpdateAndAdmin(user);
    }
}
