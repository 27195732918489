<div
    *ngIf="{
        trainingMaterials: trainingMaterialsArray$ | async,
        trainingMaterialsInit: trainingMaterialsInit$ | async,
    } as vm"
    [attr.data-cy]="'trainingMaterialsDialog'"
>
    <div class="o-grid">
        @if (isEditing) {
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="flex end">
                        <mds-button
                            id="saveTrainingMaterials"
                            (clickEvent)="saveTrainingMaterials(vm.trainingMaterials)"
                            variant="primary"
                            [attr.data-cy]="'trainingMaterialsSaveButton'"
                            label="Save"
                        ></mds-button>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item half">
                    <div class="c-info__block">
                        <h5>Training materials - site registration</h5>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field half">
                    <mds-text-field
                        id="trainingMaterialsSiteRegisteredLink"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="text"
                        floatLabel="always"
                        label="Link"
                        width="100%"
                        [attr.data-cy]="'trainingMaterialsSiteRegisteredLinkInput'"
                        [formCtrl]="trainingMaterialsFromGroup.controls.trainingMaterialsSiteRegisteredLink"
                    ></mds-text-field>
                </div>
                <div class="o-grid__field half">
                    <mds-text-field
                        id="trainingMaterialsSiteRegisteredLink"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="text"
                        floatLabel="always"
                        label="Label"
                        width="100%"
                        [attr.data-cy]="'trainingMaterialsSiteRegisteredLinkLabelInput'"
                        [formCtrl]="trainingMaterialsFromGroup.controls.trainingMaterialsSiteRegisteredLinkLabel"
                    ></mds-text-field>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item half">
                    <div class="c-info__block">
                        <h5>Training materials - site activation</h5>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field half">
                    <mds-text-field
                        id="trainingMaterialsSiteActiveLink"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="text"
                        floatLabel="always"
                        label="Link"
                        width="100%"
                        [attr.data-cy]="'trainingMaterialsSiteActivatedLinkInput'"
                        [formCtrl]="trainingMaterialsFromGroup.controls.trainingMaterialsSiteActiveLink"
                    ></mds-text-field>
                </div>
                <div class="o-grid__field half">
                    <mds-text-field
                        id="trainingMaterialsSiteActiveLink"
                        placeholder="Type here..."
                        appearance="outline"
                        inputType="text"
                        floatLabel="always"
                        label="Label"
                        width="100%"
                        [attr.data-cy]="'trainingMaterialsSiteActivatedLinkLabelInput'"
                        [formCtrl]="trainingMaterialsFromGroup.controls.trainingMaterialsSiteActiveLinkLabel"
                    ></mds-text-field>
                </div>
            </div>
        } @else if (!loaderSpinner) {
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="flex end">
                        <mds-button
                            id="editTrainingMaterials"
                            (clickEvent)="isEditing = !isEditing"
                            variant="primary"
                            label="Edit"
                            [attr.data-cy]="'trainingMaterialsEditButton'"
                        ></mds-button>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item half">
                    <div class="c-info__block">
                        <h5>Training materials - site registration</h5>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item half">
                    <div class="c-info__block">
                        <span class="bold">Link:</span>
                        <span [attr.data-cy]="'trainingMaterialsSiteRegisteredLinkDisplayValue'">
                            {{
                                getDisplayValueForTrainingMaterialConfig(
                                    vm.trainingMaterials,
                                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK
                                )
                            }}</span
                        >
                    </div>
                </div>
                <div class="o-grid__item half">
                    <div class="c-info__block">
                        <span class="bold">Label:</span>
                        <span [attr.data-cy]="'trainingMaterialsSiteRegisteredLinkLabelDisplayValue'">
                            {{
                                getDisplayValueForTrainingMaterialConfig(
                                    vm.trainingMaterials,
                                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK_LABEL
                                )
                            }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item half">
                    <div class="c-info__block">
                        <h5>Training materials - site activation</h5>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item half">
                    <div class="c-info__block">
                        <span class="bold">Link:</span>
                        <span [attr.data-cy]="'trainingMaterialsSiteActivatedLinkDisplayValue'">
                            {{
                                getDisplayValueForTrainingMaterialConfig(
                                    vm.trainingMaterials,
                                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK
                                )
                            }}</span
                        >
                    </div>
                </div>
                <div class="o-grid__item half">
                    <div class="c-info__block">
                        <span class="bold">Label:</span>
                        <span [attr.data-cy]="'trainingMaterialsSiteActivatedLinkLabelDisplayValue'">
                            {{
                                getDisplayValueForTrainingMaterialConfig(
                                    vm.trainingMaterials,
                                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK_LABEL
                                )
                            }}</span
                        >
                    </div>
                </div>
            </div>
        }
        <div *ngIf="!isEditing && loaderSpinner" class="o-grid">
            <medpace-loading-card></medpace-loading-card>
        </div>
    </div>
</div>
