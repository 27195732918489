import { Injectable } from '@angular/core';
import {
    AccessActionDto,
    AccessRequestDto,
    CreateAccessRequestDto,
    CreateInvitationMessageDto,
    InvitationDto,
    UserAccessRequestLogDto,
} from '@models/accessRequest';
import { AccessRequestRepository } from '@repositories/accessRequest/access-request.repository';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AccessRequestService {
    constructor(private accessRequestRepo: AccessRequestRepository) {}

    getAllAccessRequests(): Observable<AccessRequestDto[]> {
        return this.accessRequestRepo.getAllAccessRequests();
    }

    getAllAccessRequestLogs(): Observable<UserAccessRequestLogDto[]> {
        return this.accessRequestRepo.getAllAccessRequestLogs();
    }

    grantAccess(accessRequestId: number, action: AccessActionDto): Observable<number> {
        return this.accessRequestRepo.grantAccess(accessRequestId, action);
    }

    rejectAccess(accessRequestId: number, action: AccessActionDto): Observable<number> {
        return this.accessRequestRepo.rejectAccess(accessRequestId, action);
    }

    createAccessRequest(accessRequest: CreateAccessRequestDto, key: string): Observable<number> {
        return this.accessRequestRepo.addAccessRequest(accessRequest, key);
    }

    sendInvitation(invitationMessage: CreateInvitationMessageDto): Observable<number> {
        return this.accessRequestRepo.sendInvitationMessage(invitationMessage);
    }

    getInvitation(guid: string): Observable<InvitationDto> {
        return this.accessRequestRepo.getInvitation(guid);
    }

    getInvitationsBySiteId(siteId: number): Observable<InvitationDto[]> {
        return this.accessRequestRepo.getInvitationsBySiteId(siteId);
    }

    ifUserExistByGuid(guid: string): Observable<boolean> {
        return this.accessRequestRepo.ifUserExistByGuid(guid);
    }

    reInvite(guid: string, reason: string): Observable<number> {
        return this.accessRequestRepo.reInvite(guid, reason);
    }

    revokeInvitation(guid: string, reason: string): Observable<number> {
        return this.accessRequestRepo.revokeInvitation(guid, reason);
    }

    revokeAccessGrant(siteId: number, userId: number, reason: string): Observable<number> {
        return this.accessRequestRepo.revokeAccessGrant(siteId, userId, reason);
    }

    getAccessRequestsBySiteId(siteId: number): Observable<AccessRequestDto[]> {
        return this.accessRequestRepo.getAccessRequestsBySiteId(siteId);
    }
}
