/* tslint:disable */
/* eslint-disable */
// ReSharper disable InconsistentNaming

import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { API_BASE_URL } from '@app/API_BASE_URL';
import { Observable, of as _observableOf, throwError as _observableThrow } from 'rxjs';
import { catchError as _observableCatch, mergeMap as _observableMergeMap } from 'rxjs/operators';
import { ISaveStatus } from '../../models/general';
import {
    CreateUserDTO,
    GetUserDto,
    PasswordUpdateDto,
    UpdateUserDTO,
    UserAdminViewModel,
    UserStatusDTO,
} from '../../models/user';
import { blobToText, throwException } from '../../utility/generated-nswag';

export interface IUserClient {
    getUserByEmail(email: string): Observable<GetUserDto>;
    getUserAccountDataByEmailClaim(): Observable<UserAdminViewModel>;
    getUserAdminDetails(): Observable<UserAdminViewModel[]>;
    create(createUserDTO: CreateUserDTO): Observable<ISaveStatus>;
    update(userDTO: UpdateUserDTO): Observable<number>;
    setUserStatus(disableUserDTO: UserStatusDTO): Observable<number>;
    updatePassword(password: PasswordUpdateDto): Observable<number>;
    resetPassword(base64EncodedEmail: string | null | undefined): Observable<number>;
}

@Injectable()
export class UserClient implements IUserClient {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        if (!baseUrl)
            throw new Error(
                `Could not retrieve the URL of a backend API from the configuration. Check the value used to initialize API_BASE_URL. The retrieved value was: ${baseUrl}.`
            );
        this.baseUrl = baseUrl;
    }

    getUserByEmail(email: string): Observable<GetUserDto> {
        let url_ = this.baseUrl + '/api/users/useremail/{encodedEmail}';
        if (email === undefined || email === null) throw new Error("The parameter 'encodedEmail' must be defined.");
        url_ = url_.replace('{encodedEmail}', encodeURIComponent('' + email));
        url_ = url_.replace(/[?&]$/, '');

        let options_: any = {
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                Accept: 'application/json',
            }),
        };

        return this.http
            .request('get', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetUserByEmail(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetUserByEmail(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<GetUserDto>;
                        }
                    } else return _observableThrow(response_) as any as Observable<GetUserDto>;
                })
            );
    }

    protected processGetUserByEmail(response: HttpResponseBase): Observable<GetUserDto> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                  ? (response as any).error
                  : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = GetUserDto.fromJS(resultData200);
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException('An unexpected server error occurred.', status, _responseText, _headers);
                })
            );
        }
        return _observableOf(null as any);
    }

    getUserAccountDataByEmailClaim(): Observable<UserAdminViewModel> {
        let url_ = this.baseUrl + '/api/users/me/account';
        url_ = url_.replace(/[?&]$/, '');

        let options_: any = {
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                Accept: 'application/json',
            }),
        };

        return this.http
            .request('get', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetUserAccountDataByEmailClaim(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetUserAccountDataByEmailClaim(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<UserAdminViewModel>;
                        }
                    } else return _observableThrow(response_) as any as Observable<UserAdminViewModel>;
                })
            );
    }

    protected processGetUserAccountDataByEmailClaim(response: HttpResponseBase): Observable<UserAdminViewModel> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                  ? (response as any).error
                  : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = UserAdminViewModel.fromJS(resultData200);
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException('An unexpected server error occurred.', status, _responseText, _headers);
                })
            );
        }
        return _observableOf(null as any);
    }

    getUserAdminDetails(): Observable<UserAdminViewModel[]> {
        let url_ = this.baseUrl + '/api/users/admindetails';
        url_ = url_.replace(/[?&]$/, '');

        let options_: any = {
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                Accept: 'application/json',
            }),
        };

        return this.http
            .request('get', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processGetUserAdminDetails(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processGetUserAdminDetails(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<UserAdminViewModel[]>;
                        }
                    } else return _observableThrow(response_) as any as Observable<UserAdminViewModel[]>;
                })
            );
    }

    protected processGetUserAdminDetails(response: HttpResponseBase): Observable<UserAdminViewModel[]> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                  ? (response as any).error
                  : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    if (Array.isArray(resultData200)) {
                        result200 = [] as any;
                        for (let item of resultData200) result200!.push(UserAdminViewModel.fromJS(item));
                    } else {
                        result200 = <any>null;
                    }
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException('An unexpected server error occurred.', status, _responseText, _headers);
                })
            );
        }
        return _observableOf(null as any);
    }

    create(createUserDTO: CreateUserDTO): Observable<ISaveStatus> {
        let url_ = this.baseUrl + '/api/users';
        url_ = url_.replace(/[?&]$/, '');

        const content_ = JSON.stringify(createUserDTO);

        let options_: any = {
            body: content_,
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }),
        };

        return this.http
            .request('post', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processCreate(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processCreate(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<ISaveStatus>;
                        }
                    } else return _observableThrow(response_) as any as Observable<ISaveStatus>;
                })
            );
    }

    protected processCreate(response: HttpResponseBase): Observable<ISaveStatus> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                  ? (response as any).error
                  : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = ISaveStatus.fromJS(resultData200);
                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException('An unexpected server error occurred.', status, _responseText, _headers);
                })
            );
        }
        return _observableOf(null as any);
    }

    update(userDTO: UpdateUserDTO): Observable<number> {
        let url_ = this.baseUrl + '/api/users';
        url_ = url_.replace(/[?&]$/, '');

        const content_ = JSON.stringify(userDTO);

        let options_: any = {
            body: content_,
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }),
        };

        return this.http
            .request('put', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processUpdate(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processUpdate(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<number>;
                        }
                    } else return _observableThrow(response_) as any as Observable<number>;
                })
            );
    }

    protected processUpdate(response: HttpResponseBase): Observable<number> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                  ? (response as any).error
                  : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = resultData200 !== undefined ? resultData200 : <any>null;

                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException('An unexpected server error occurred.', status, _responseText, _headers);
                })
            );
        }
        return _observableOf(null as any);
    }

    setUserStatus(disableUserDTO: UserStatusDTO): Observable<number> {
        let url_ = this.baseUrl + '/api/users/status';
        url_ = url_.replace(/[?&]$/, '');

        const content_ = JSON.stringify(disableUserDTO);

        let options_: any = {
            body: content_,
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }),
        };

        return this.http
            .request('put', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processSetUserStatus(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processSetUserStatus(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<number>;
                        }
                    } else return _observableThrow(response_) as any as Observable<number>;
                })
            );
    }

    protected processSetUserStatus(response: HttpResponseBase): Observable<number> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                  ? (response as any).error
                  : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = resultData200 !== undefined ? resultData200 : <any>null;

                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException('An unexpected server error occurred.', status, _responseText, _headers);
                })
            );
        }
        return _observableOf(null as any);
    }

    updatePassword(password: PasswordUpdateDto): Observable<number> {
        let url_ = this.baseUrl + '/api/users/password';
        url_ = url_.replace(/[?&]$/, '');

        const content_ = JSON.stringify(password);

        let options_: any = {
            body: content_,
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }),
        };

        return this.http
            .request('put', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processUpdatePassword(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processUpdatePassword(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<number>;
                        }
                    } else return _observableThrow(response_) as any as Observable<number>;
                })
            );
    }

    protected processUpdatePassword(response: HttpResponseBase): Observable<number> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                  ? (response as any).error
                  : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = resultData200 !== undefined ? resultData200 : <any>null;

                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException('An unexpected server error occurred.', status, _responseText, _headers);
                })
            );
        }
        return _observableOf(null as any);
    }
    resetPassword(base64EncodedEmail: string | null | undefined): Observable<number> {
        let url_ = this.baseUrl + '/api/users/password/reset?';
        if (base64EncodedEmail !== undefined && base64EncodedEmail !== null)
            url_ += 'base64EncodedEmail=' + encodeURIComponent('' + base64EncodedEmail) + '&';
        url_ = url_.replace(/[?&]$/, '');

        let options_: any = {
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({
                Accept: 'application/json',
            }),
        };

        return this.http
            .request('put', url_, options_)
            .pipe(
                _observableMergeMap((response_: any) => {
                    return this.processResetPassword(response_);
                })
            )
            .pipe(
                _observableCatch((response_: any) => {
                    if (response_ instanceof HttpResponseBase) {
                        try {
                            return this.processResetPassword(response_ as any);
                        } catch (e) {
                            return _observableThrow(e) as any as Observable<number>;
                        }
                    } else return _observableThrow(response_) as any as Observable<number>;
                })
            );
    }

    protected processResetPassword(response: HttpResponseBase): Observable<number> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse
                ? response.body
                : (response as any).error instanceof Blob
                  ? (response as any).error
                  : undefined;

        let _headers: any = {};
        if (response.headers) {
            for (let key of response.headers.keys()) {
                _headers[key] = response.headers.get(key);
            }
        }
        if (status === 200) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    let result200: any = null;
                    let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                    result200 = resultData200 !== undefined ? resultData200 : <any>null;

                    return _observableOf(result200);
                })
            );
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(
                _observableMergeMap((_responseText: string) => {
                    return throwException('An unexpected server error occurred.', status, _responseText, _headers);
                })
            );
        }
        return _observableOf(null as any);
    }

    //getting number of sites where crc user is primaryCRC
    getUserSiteCountBySiteStatus: (siteStatusId: number) => Observable<number> = (siteStatusId: number) => {
        return this.http.get<number>(`${this.baseUrl}/api/users/me/sites/status/${siteStatusId}/count`);
    };
}
