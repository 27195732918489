<div class="">
    <h5>Site Registration</h5>
</div>
<div class="c-card__item c-card__item--right">
    <div>
        <mds-dropdown
            floatLabel="always"
            [closeOnPageScroll]="false"
            appearance="outline"
            [multiple]="true"
            [label]="'Protocol'"
            placeholder="Select filter..."
            [options]="protocols"
            id="filterDropdown"
            (selectionChange)="protocolSelectionChanged($event)"
        ></mds-dropdown>
    </div>
    <div class="c-card__search">
        <mds-text-field
            data-cy="table-search"
            appearance="outline"
            label="Search"
            startIcon="search"
            (keyup)="applyFilter($event)"
        ></mds-text-field>
    </div>
</div>

<div class="c-card__content">
    <div *ngIf="loaderSpinner" class="o-grid">
        <div class="o-grid__row">
            <medpace-loading-card [diameter]="100" style="margin: auto"></medpace-loading-card>
        </div>
    </div>
    <!-- we're doing a one-off table here because of the additional column. -->
    <div class="c-table" [style]="'display:' + (loaderSpinner ? 'none' : 'block')">
        <mds-table
            id="pre-registered-sites-table"
            [dataSource]="preRegisteredSites ? preRegisteredSites : null"
            [hasCustomRowDefs]="true"
            columnCase="uppercase"
            dataWhiteSpace="nowrap"
            style="display: grid"
            matSort
            [attr.data-cy]="'preregisteredSitesTable'"
        >
            <ng-container matColumnDef="protocol">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Protocol"
                    >PROTOCOL</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{ element.protocol }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="institutionName">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Insitution name"
                    >INSTITUTION NAME</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{ element.institutionName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="primaryCrcEmailToInvite">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Primary Crc"
                    >PRIMARY CRC</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{ element.primaryCrcEmailToInvite }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="preRegisteredDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by invitation date"
                    >INVITATION DATE</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{ element.preRegisteredDate }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="activationDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by activation date"
                    >ACTIVATION DATE</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{ element.activationDate }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="sivDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by siv date"
                    >SIV DATE</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{ element.sivDate }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef>ACTION</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mds-button
                        variant="primary"
                        label="Send Reminder"
                        (clickEvent)="sendReminder(element)"
                    ></mds-button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="preRegisteredSitesColumns"> </mat-header-row>
            <mat-row *matRowDef="let row; columns: preRegisteredSitesColumns"></mat-row>
        </mds-table>
    </div>
</div>
