import { Component } from '@angular/core';
import { ContactNumber } from './contact-number';

@Component({
    selector: 'app-global-contact-numbers',
    templateUrl: './global-contact-numbers.component.html',
    styleUrls: ['./global-contact-numbers.component.scss'],
})
export class GlobalContactNumbersComponent {
    columns: string[] = ['countryName', 'patientConciergeServicesNumber', 'medpaceTravelDepartmentNumber'];

    columnMap: any[] = [
        { header: 'Country', name: 'countryName' },
        { header: 'Patient Concierge Services', name: 'patientConciergeServicesNumber' },
        { header: 'Medpace Travel Department', name: 'medpaceTravelDepartmentNumber' },
    ];

    // This list is also copied into PatientPACE.
    // If you want to update any value, please update in both places!
    contactNumbers: ContactNumber[] = [
        {
            countryName: 'Argentina',
            patientConciergeServicesNumber: '0800-345-1576',
            medpaceTravelDepartmentNumber: '0800-345-1574',
        },
        {
            countryName: 'Australia',
            patientConciergeServicesNumber: '1800-767-935',
            medpaceTravelDepartmentNumber: '1800-765-142',
        },
        {
            countryName: 'Austria',
            patientConciergeServicesNumber: '0800-232991',
            medpaceTravelDepartmentNumber: '0800-281456',
        },
        {
            countryName: 'Belgium',
            patientConciergeServicesNumber: '0800-79-423',
            medpaceTravelDepartmentNumber: '0800-79-426',
        },
        {
            countryName: 'Brazil',
            patientConciergeServicesNumber: '0800-200-3508',
            medpaceTravelDepartmentNumber: '0800-900-0239',
        },
        {
            countryName: 'Canada',
            patientConciergeServicesNumber: '1-866-758-6635',
            medpaceTravelDepartmentNumber: '1-866-417-6311',
        },
        {
            countryName: 'Chile',
            patientConciergeServicesNumber: '800-914-467',
            medpaceTravelDepartmentNumber: '800-914-461',
        },
        {
            countryName: 'China',
            patientConciergeServicesNumber: '400-120-9355',
            medpaceTravelDepartmentNumber: '400-120-9356',
        },
        {
            countryName: 'Czech Republic',
            patientConciergeServicesNumber: '800 040 394',
            medpaceTravelDepartmentNumber: '800 040 395',
        },
        {
            countryName: 'Denmark',
            patientConciergeServicesNumber: '80-25-42-07',
            medpaceTravelDepartmentNumber: '80-25-42-06',
        },
        {
            countryName: 'France',
            patientConciergeServicesNumber: '0-805-08-07-66',
            medpaceTravelDepartmentNumber: '0-805-98-55-81',
        },
        {
            countryName: 'Germany',
            patientConciergeServicesNumber: '0800-1812001',
            medpaceTravelDepartmentNumber: '0800-1815189',
        },
        {
            countryName: 'Greece',
            patientConciergeServicesNumber: '800-848-1584',
            medpaceTravelDepartmentNumber: '800-848-1686',
        },
        {
            countryName: 'Hong Kong',
            patientConciergeServicesNumber: '800-906-127',
            medpaceTravelDepartmentNumber: '800-968-790',
        },
        {
            countryName: 'Hungary',
            patientConciergeServicesNumber: '06-80-088-042',
            medpaceTravelDepartmentNumber: '06-80-088-041',
        },
        {
            countryName: 'Italy',
            patientConciergeServicesNumber: '800-788-926',
            medpaceTravelDepartmentNumber: '800-137-099',
        },
        {
            countryName: 'Japan',
            patientConciergeServicesNumber: '0800-100-0076',
            medpaceTravelDepartmentNumber: '0800-222-0364',
        },
        {
            countryName: 'Mexico',
            patientConciergeServicesNumber: '800-681-8071',
            medpaceTravelDepartmentNumber: '800-681-8065',
        },
        {
            countryName: 'Netherlands',
            patientConciergeServicesNumber: '0800-0224691',
            medpaceTravelDepartmentNumber: '0800-0223269',
        },
        {
            countryName: 'Norway',
            patientConciergeServicesNumber: '800-62-573',
            medpaceTravelDepartmentNumber: '800-62-579',
        },
        {
            countryName: 'Poland',
            patientConciergeServicesNumber: '800-005-176',
            medpaceTravelDepartmentNumber: '800-005-181',
        },
        {
            countryName: 'Singapore',
            patientConciergeServicesNumber: '800-852-3826',
            medpaceTravelDepartmentNumber: '800-492-2495',
        },
        {
            countryName: 'South Korea',
            patientConciergeServicesNumber: '00308-132-537',
            medpaceTravelDepartmentNumber: '00308-491-0206',
        },
        {
            countryName: 'Spain',
            patientConciergeServicesNumber: '900-876-156',
            medpaceTravelDepartmentNumber: '900-876-165',
        },
        {
            countryName: 'Sweden',
            patientConciergeServicesNumber: '020-12 73 08',
            medpaceTravelDepartmentNumber: '020-12 74 51',
        },
        {
            countryName: 'Switzerland',
            patientConciergeServicesNumber: '0800-551-984',
            medpaceTravelDepartmentNumber: '0800-552-131',
        },
        {
            countryName: 'Taiwan',
            patientConciergeServicesNumber: '00801-49-1660',
            medpaceTravelDepartmentNumber: '00801-49-1661',
        },
        {
            countryName: 'Turkey',
            patientConciergeServicesNumber: '0800-621-2436',
            medpaceTravelDepartmentNumber: '0800-621-2437',
        },
        {
            countryName: 'United Kingdom',
            patientConciergeServicesNumber: '0808-189-3136',
            medpaceTravelDepartmentNumber: '0808-101-2774',
        },
        {
            countryName: 'United States',
            patientConciergeServicesNumber: '1-866-715-4403',
            medpaceTravelDepartmentNumber: '866-417-6311',
        },
    ];
}
