import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvConfigurationService } from '../services/env/env-configuration.service';

//import { Observable } from 'rxjs';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
    constructor(private env: EnvConfigurationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Clone the request and replace the original headers with
        // cloned headers, updated with the groups.
        let configuration = this.env.configuration;

        if (configuration != null) {
            const versionReq = req.clone({
                headers: req.headers.set('Frontend-Version', configuration.version),
            });

            // send cloned request with header to the next handler.
            return next.handle(versionReq);
        }

        return next.handle(req);
    }
}
