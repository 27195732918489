import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestTypes, RequestTypesKeys } from '@app/enums/RequestTypes';
import { DateFormat } from '@models/date-format';
import { Patient } from '@models/patient';
import { RequestSummary } from '@models/request';
import { RequestApprovalStatus } from '@models/requestApprovalStatus';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { GroupService } from '@services/group/group.service';
import { PatientStatusService } from '@services/patient-status/patient-status.service';
import { RequestStatusService } from '@services/request-status/request-status.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { exportToCsv } from '@utility/utility';
import { utc } from 'moment';
import { Subject, filter, forkJoin, switchMap, takeUntil, tap } from 'rxjs';
import { VisitName } from 'src/app/models/study';
import { dateFormatPipe } from 'src/app/pipes/datepipe';
import { sortRequestByStatus } from 'src/app/sorting-helpers/utilities';

@Component({
    selector: 'medpace-patient-overview',
    templateUrl: './patient-overview.component.html',
    styleUrls: ['./patient-overview.component.scss'],
    providers: [dateFormatPipe],
})
export class MedpacePatientOverviewComponent implements OnInit, OnDestroy {
    tableColumnHeaders: string[] = [
        'requestPseudoId',
        'visitName',
        'visitDate',
        'requestDate',
        'requestType',
        'status',
        'readyForPayment',
    ];
    tableColumnMap: any[] = [
        { header: 'Request ID', name: 'requestPseudoId' },
        { header: 'Visit Name', name: 'visitName' },
        { header: 'Visit Date', name: 'visitDate' },
        { header: 'Request Date', name: 'requestDate' },
        { header: 'Request Type', name: 'requestType' },
        { header: 'Status', name: 'status' },
        { header: 'Payment Ready', name: 'readyForPayment' },
        { header: 'Id', name: 'id' },
        { header: 'type', name: 'type' },
    ];

    travelRequestData: RequestSummary[] = [];
    filterableData: RequestSummary[];

    //request status constants
    statusConstants: RequestApprovalStatus[] = this.requestStatus.getStatuses();

    patient: any;
    paymentString: string;
    visitTypes: VisitName[];
    isAdmin: boolean;

    fileName: string = "patient's_requests";

    csvColumns: string[] = [
        'requestPseudoId',
        'visitName',
        'visitDate',
        'requestDate',
        'requestType',
        'status',
        'readyForPayment',
    ];

    private componentDestroyed$: Subject<boolean> = new Subject();

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private patientServices: AdminPatientServices,
        private studyServices: AdminStudyServices,
        private groups: GroupService,
        private datepipe: dateFormatPipe,
        private dialog: MatDialog,
        private patientStatus: PatientStatusService,
        private requestStatus: RequestStatusService,
        private patientStateService: PatientStateService
    ) {}

    ngOnInit(): void {
        this.travelRequestData = [];

        this.groups
            .getGroupsFromStorage()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((result) => {
                this.isAdmin = this.groups.isUserAdmin(result);
            });

        this.patientStateService
            .getSummaryPatientData()
            .pipe(
                takeUntil(this.componentDestroyed$),
                filter((patient) => !!patient),
                switchMap((patient: Patient) => {
                    //patient setup
                    this.patient = patient;
                    if (this.patient.readyForPayment == true) {
                        this.paymentString = 'Yes';
                    } else {
                        this.paymentString = 'No';
                    }
                    this.patient.status = this.patientStatus.getStatusById(this.patient.statusId);

                    const patientRequestSummaries = this.patientServices.getPatientRequestSummaries(this.patient.id);
                    const visits = this.studyServices.getStudyVisitTypes(this.patient.studyId);

                    return forkJoin([patientRequestSummaries, visits]);
                }),
                tap(([patientRequestSummaries, visitTypes]: [RequestSummary[], VisitName[]]) => {
                    this.visitTypes = visitTypes;

                    let temp: RequestSummary[] = patientRequestSummaries.map((rs) => {
                        const requestSummary: RequestSummary = {
                            id: rs.requestId,
                            requestPseudoId: rs.requestPseudoId,
                            status: rs.status,
                            // `Unspecified` is a label for null value, which is displayed when Patient selected `Not sure` in PatientPACE
                            visitName: rs.visitName ?? 'Unspecified',
                            visitDate: this.datepipe
                                .transform(utc(rs.visitDate).toDate(), DateFormat.dateOnly)
                                ?.toUpperCase(),
                            requestDate: this.datepipe
                                .transform(utc(rs.requestDate).toDate(), DateFormat.dateOnly, null)
                                ?.toUpperCase(),
                            requestType: rs.requestType,
                            type: this.getRequestType(rs.requestType),
                            readyForPayment: rs.readyForPayment,
                            flaggedDuplicate: rs.flaggedDuplicate,
                            isPatientDeleted: rs.isPatientDeleted,
                        };
                        return requestSummary;
                    });

                    this.travelRequestData = temp;
                    sortRequestByStatus(this.travelRequestData);
                })
            )
            .subscribe();
        this.patientStateService.setSummaryPatientData(this.activeRoute.snapshot.params.patientId);
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    getRequestType(requestType: string, fallbackValue: string = '') {
        let type: string;
        if (requestType === RequestTypesKeys.REIMBURSEMENT || requestType === RequestTypesKeys.STIPEND) {
            type = RequestTypes.PAYMENT;
        } else if (requestType === RequestTypesKeys.TRAVEL) {
            type = RequestTypes.TRAVEL;
        } else {
            type = fallbackValue;
        }

        return type;
    }

    addNewRequest(): void {
        this.router.navigate([
            `studies/${this.patient?.studyId}/sites/${this.patient?.siteId}/patients/${this.patient?.id}/newrequest`,
        ]);
    }

    goToDetails(): void {
        if (this.patient.statusId !== 2) {
            this.router.navigate([
                `studies/${this.patient?.studyId}/sites/${this.patient?.siteId}/patients/${this.patient?.id}/edit`,
            ]);
        }
    }

    doClickRow(event: any): void {
        switch (event.requestType) {
            case 'Stipend':
                this.router.navigate(
                    [
                        `studies/${this.patient.studyId}/sites/${this.patient.siteId}/patients/${this.patient.id}/requests/${event.id}/edit`,
                    ],
                    { queryParams: { 'payment-travel': 'payment', 'stipend-oop': 'stipend' } }
                );
                break;
            case 'Reimbursement':
                this.router.navigate(
                    [
                        `studies/${this.patient.studyId}/sites/${this.patient.siteId}/patients/${this.patient.id}/requests/${event.id}/edit`,
                    ],
                    { queryParams: { 'payment-travel': 'payment', 'stipend-oop': 'out-of-pocket' } }
                );
                break;
            default:
                this.router.navigate(
                    [
                        `studies/${this.patient.studyId}/sites/${this.patient.siteId}/patients/${this.patient.id}/requests/${event.id}/edit`,
                    ],
                    { queryParams: { 'payment-travel': 'travel', 'stipend-oop': 'NA' } }
                );
                break;
        }
    }

    filterData(event) {
        this.filterableData = event;
    }

    saveAsCsv() {
        if (this.filterableData) exportToCsv(this.filterableData, this.fileName, this.csvColumns, this.dialog);
        else exportToCsv(this.travelRequestData, this.fileName, this.csvColumns, this.dialog);
    }
}
