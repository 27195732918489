export interface PcsConfiguration {
    id: number;
    key: PcsConfigurationKeyEnum;
    value: string;
}

export enum PcsConfigurationKeyEnum {
    TRAINING_DOCUMENTS_SITE_REGISTERED_LINK = 'training_site_registered_link',
    TRAINING_DOCUMENTS_SITE_REGISTERED_LINK_LABEL = 'training_site_registered_link_label',
    TRAINING_DOCUMENTS_SITE_ACTIVE_LINK = 'training_site_active_link',
    TRAINING_DOCUMENTS_SITE_ACTIVE_LINK_LABEL = 'training_site_active_link_label',
}

export function getPcsConfigurationByKey(configuration: PcsConfiguration[], key: PcsConfigurationKeyEnum) {
    let value = configuration.find((x) => x.key == key);

    if (!value) value = <PcsConfiguration>{ key: key, value: '' };

    return value;
}
