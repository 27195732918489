import { Injectable } from '@angular/core';
import { PcsConfiguration } from '@models/pcsConfiguration';
import { SaveStatusResponse } from '@utility/utility';
import { Observable } from 'rxjs';
import { PcsConfigurationRepository } from '../../repositories/pcsConfiguration/pcs-configuration.repository';

@Injectable({
    providedIn: 'root',
})
export class PcsConfigurationService {
    constructor(private repo: PcsConfigurationRepository) {}

    getPcsConfigurationsByKeys: (keys: string[]) => Observable<PcsConfiguration[]> = (keys: string[]) => {
        return this.repo.getPcsConfigurationsByKeys(keys);
    };

    createOrUpdatePcsConfigurations: (pcsConfigurations: PcsConfiguration[]) => Observable<SaveStatusResponse> = (
        pcsConfigurations: PcsConfiguration[]
    ) => {
        return this.repo.createOrUpdatePcsConfigurations(pcsConfigurations);
    };
}
