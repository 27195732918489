<mds-dialog dialogTitle="Edit Request">
    <mds-dialog-content>
        @if (patient$ | async; as patient) {
            @if (isAnyControlEnabled(formGroup.controls.patient) || patientEditRequest.profileChangeDetails) {
                <mds-expansion-panel
                    [panelTitle]="getPatientFullName(patient) + ' (Patient)'"
                    description="Open To Accept Changes"
                    [expanded]="true"
                    [icon]="'card_travel'"
                >
                    <medpace-patient-edit-request
                        [formGroup]="formGroup.controls.patient"
                        [patient]="patient"
                        [editRequest]="patientEditRequest"
                    />
                </mds-expansion-panel>
            }
            @if (isAnyControlEnabled(formGroup.controls.patientTravelPrefs) && isTravelSupported$ | async) {
                <mds-expansion-panel
                    [panelTitle]="getPatientFullName(patient) + ' (Patient) Travel preferences'"
                    description="Open To Accept Changes"
                    [expanded]="true"
                    [icon]="'card_travel'"
                >
                    <medpace-patient-travel-prefs-edit-request
                        [formGroup]="formGroup.controls.patientTravelPrefs"
                        [patient]="patient"
                        [editRequest]="patientEditRequest"
                    />
                </mds-expansion-panel>
            }
            @for (caregiverFormGroup of formGroup.controls.caregivers.controls; track caregiverFormGroup) {
                @if (isAnyControlEnabled(caregiverFormGroup)) {
                    <mds-expansion-panel
                        [panelTitle]="getCaregiverFullname(caregiverFormGroup.caregiver) + ' (Caregiver)'"
                        description="Open To Accept Changes"
                        [expanded]="false"
                        [icon]="'card_travel'"
                    >
                        <medpace-caregiver-edit-request
                            [formGroup]="caregiverFormGroup"
                            [caregiver]="caregiverFormGroup.caregiver"
                            [editRequest]="caregiverFormGroup.editRequest"
                        />
                    </mds-expansion-panel>
                }
            }
        } @else {
            <medpace-loading-card />
        }
    </mds-dialog-content>
    <mds-dialog-actions [align]="'end'">
        <mds-button variant="outline" label="Close" data-cy="close-modal" (clickEvent)="closeModal()" />
        <mds-button
            data-cy="delete-request"
            variant="primary"
            color="warn"
            label="Delete Edit Request"
            (clickEvent)="deleteRequest()"
        />
        <mds-button data-cy="submit-request" variant="primary" label="Submit" (clickEvent)="submit()" type="submit" />
    </mds-dialog-actions>
</mds-dialog>

<ng-template #loading>
    <div class="spinnerOverlay">
        <medpace-loading-card />
    </div>
</ng-template>
