import { AfterViewInit, ChangeDetectorRef, Component, inject, input, ViewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GetSiteStatusLabel, SiteStatusEnum } from '@app/enums/site-status.enum';
import { dateFormatPipe } from '@app/pipes/datepipe';
import { DisplayErrorModalComponent } from '@components/molecules/modals/display-error-modal/display-error-modal.component';
import { customSort } from '@models/datatable/custom-sort';
import { PreRegisteredSitesFilter } from '@models/datatable/preregistered-filter';
import { MomentFormat } from '@models/date-format';
import { getSiteSIVDate, SiteByStatusViewModel } from '@models/site';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { dateArrayTransform, dateTransform, MdsOptionGeneric } from '@utility/utility';
import * as moment from 'moment';
import { catchError, distinctUntilChanged, EMPTY, filter, switchMap, take, tap } from 'rxjs';
import { AdminSiteServices } from '../../../services/admin/admin-site.service';
import {
    SendReminderModalComponent,
    SendReminderResult,
} from '../modals/send-reminder-modal/send-reminder-modal.component';

@Component({
    selector: 'medpace-preregistered-sites-table',
    templateUrl: './preregistered-sites-table.component.html',
    styleUrl: './preregistered-sites-table.component.scss',
})
export class PreregisteredSitesTableComponent implements AfterViewInit {
    private siteService = inject(AdminSiteServices);
    private datePipe = inject(dateFormatPipe);
    private dialog = inject(MatDialog);
    private snackbarService = inject(SnackbarService);
    private cdr = inject(ChangeDetectorRef);

    private filtersValues: PreRegisteredSitesFilter = new PreRegisteredSitesFilter();

    preRegisteredSitesColumns: string[] = [
        'protocol',
        'institutionName',
        'primaryCrcEmailToInvite',
        'preRegisteredDate',
        'activationDate',
        'sivDate',
        'action',
    ];

    load = input<boolean>(false);
    protocols: MdsOptionGeneric<string>[];

    loadTableData$ = toObservable(this.load).pipe(
        takeUntilDestroyed(),
        filter(Boolean),
        distinctUntilChanged(),
        switchMap((x) =>
            this.siteService.getSitesByStatusId(SiteStatusEnum.PreRegistered).pipe(
                take(1),
                tap((sites) => {
                    this.protocols = sites.map((site) => {
                        return { value: site.protocol, viewValue: site.protocol };
                    });

                    this.preRegisteredSites = new MatTableDataSource(
                        sites.map((site) => {
                            site.statusName = GetSiteStatusLabel(site.statusId);
                            site.preRegisteredDate = moment(site.preRegisteredDate)
                                .utc(true)
                                .format(MomentFormat.dateOnly)
                                .toUpperCase();
                            site.sivDate = getSiteSIVDate(this.datePipe, site);
                            site.activationDate = site.activationDate
                                ? dateTransform(this.datePipe, site.activationDate)
                                : site.activationProjectedDates.length > 0
                                  ? dateArrayTransform(this.datePipe, site.activationProjectedDates)?.join('\r\n')
                                  : 'N/A';
                            site.lastReminderDate ? dateTransform(this.datePipe, site.lastReminderDate) : null;
                            return site;
                        })
                    );
                    this.preRegisteredSites.sortData = (data: any[], sort: MatSort) => {
                        return customSort(data, sort);
                    };
                    this.preRegisteredSites.sort = this.sort;
                    this.preRegisteredSites.filterPredicate = this.filtersValues.setFilterPredicate(
                        this.preRegisteredSitesColumns
                    );
                    this.loaderSpinner = false;
                }),
                catchError(() => {
                    this.loaderSpinner = false;
                    this.preRegisteredSites = new MatTableDataSource([]);
                    return EMPTY;
                })
            )
        )
    );

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    preRegisteredSites: MatTableDataSource<SiteByStatusViewModel, MatPaginator> = new MatTableDataSource([]);
    loaderSpinner = false;

    ngAfterViewInit(): void {
        this.loaderSpinner = true;
        this.loadTableData$.subscribe();

        this.cdr.detectChanges();
    }

    applyFilter(event: Event) {
        const searchValue = (event.target as HTMLInputElement).value;

        this.filtersValues.search = searchValue;

        this.setFilter();
    }

    protocolSelectionChanged($event: MatSelectChange) {
        this.filtersValues.protocol = $event.value;
        this.setFilter();
    }

    setFilter() {
        this.preRegisteredSites.filter = JSON.stringify(this.filtersValues);
    }

    sendReminder(site: SiteByStatusViewModel) {
        const dialogRef = this.dialog.open<SendReminderModalComponent, any, SendReminderResult>(
            SendReminderModalComponent,
            {
                disableClose: true,
                data: site.lastReminderDate,
                width: '500px',
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((result) => result.send),
                switchMap(() =>
                    this.siteService.sendSiteRegistrationReminder(site.id).pipe(
                        tap((_) => {
                            const data = this.preRegisteredSites.data;
                            const index = data.findIndex((x) => x.id == site.id);
                            if (index !== -1) {
                                data[index] = {
                                    ...data[index],
                                    ...{ lastReminderDate: dateTransform(this.datePipe, new Date().toISOString()) },
                                };
                                this.preRegisteredSites.data = data;
                            }
                            this.snackbarService.openInfoSnackbar('Reminder message has been sent successfully.');
                        })
                    )
                ),
                catchError((err) => {
                    console.error('error', err);
                    this.dialog.open(DisplayErrorModalComponent, {
                        autoFocus: false,
                        width: '500px',
                        disableClose: false,
                        data: err.error.ExceptionMessage,
                    });

                    return EMPTY;
                })
            )
            .subscribe();
    }
}
