import { Component, inject, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { getPcsConfigurationByKey, PcsConfiguration, PcsConfigurationKeyEnum } from '@models/pcsConfiguration';
import { PcsConfigurationService } from '@services/pcs-configuration/pcs-configuration.service';
import { defer, EMPTY, filter, Observable, Subject, switchMap, take, tap } from 'rxjs';

@Component({
    selector: 'medpace-traning-materials',
    templateUrl: './traning-materials.component.html',
    styleUrl: './traning-materials.component.scss',
})
export class TraningMaterialsComponent {
    pcsConfigurationService = inject(PcsConfigurationService);
    isEditing: boolean = false;
    loaderSpinner: boolean = false;

    getPcsConfigurationByKey = getPcsConfigurationByKey;
    PcsConfigurationKeyEnum = PcsConfigurationKeyEnum;

    @Input({ required: true }) trainingMaterials$!: Observable<PcsConfiguration[]>;

    trainingMaterialsSubject = new Subject<PcsConfiguration[]>();

    trainingMaterialsFromGroup = new FormGroup({
        trainingMaterialsSiteRegisteredLink: new FormControl<string>(''),
        trainingMaterialsSiteRegisteredLinkLabel: new FormControl<string>(''),
        trainingMaterialsSiteActiveLink: new FormControl<string>(''),
        trainingMaterialsSiteActiveLinkLabel: new FormControl<string>(''),
    });

    trainingMaterialsInit$ = defer(() => {
        this.loaderSpinner = true;
        return this.trainingMaterials$;
    }).pipe(
        tap((array) => {
            this.trainingMaterialsSubject.next(array);
            this.loaderSpinner = false;
        })
    );

    trainingMaterialsArray$ = this.trainingMaterialsSubject.asObservable().pipe(
        tap((array) => {
            this.patchFormGroup(array);
        })
    );

    patchFormGroup(array: PcsConfiguration[]) {
        this.trainingMaterialsFromGroup.patchValue({
            trainingMaterialsSiteActiveLink: getPcsConfigurationByKey(
                array,
                PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK
            )?.value,
            trainingMaterialsSiteActiveLinkLabel: getPcsConfigurationByKey(
                array,
                PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK_LABEL
            )?.value,
            trainingMaterialsSiteRegisteredLink: getPcsConfigurationByKey(
                array,
                PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK
            )?.value,
            trainingMaterialsSiteRegisteredLinkLabel: getPcsConfigurationByKey(
                array,
                PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK_LABEL
            )?.value,
        });
    }

    saveTrainingMaterials(currentValues: PcsConfiguration[]) {
        let modifiedValues: PcsConfiguration[] = [];

        modifiedValues.push(
            this.pushToArrayIfModified(
                getPcsConfigurationByKey(
                    currentValues,
                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK
                ),
                this.trainingMaterialsFromGroup.controls.trainingMaterialsSiteRegisteredLink.value
            )
        );

        modifiedValues.push(
            this.pushToArrayIfModified(
                getPcsConfigurationByKey(
                    currentValues,
                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK_LABEL
                ),
                this.trainingMaterialsFromGroup.controls.trainingMaterialsSiteRegisteredLinkLabel.value
            )
        );

        modifiedValues.push(
            this.pushToArrayIfModified(
                getPcsConfigurationByKey(currentValues, PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK),
                this.trainingMaterialsFromGroup.controls.trainingMaterialsSiteActiveLink.value
            )
        );

        modifiedValues.push(
            this.pushToArrayIfModified(
                getPcsConfigurationByKey(
                    currentValues,
                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK_LABEL
                ),
                this.trainingMaterialsFromGroup.controls.trainingMaterialsSiteActiveLinkLabel.value
            )
        );

        modifiedValues = modifiedValues.filter((value) => value);

        //check if is any difference between data
        if (modifiedValues?.length > 0) {
            this.pcsConfigurationService
                .createOrUpdatePcsConfigurations(modifiedValues)
                .pipe(
                    switchMap((saveStatus) => {
                        if (saveStatus.saveSuccessful) {
                            return this.pcsConfigurationService
                                .getPcsConfigurationsByKeys([
                                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK,
                                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK_LABEL,
                                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK,
                                    PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK_LABEL,
                                ])
                                .pipe(take(1));
                        }

                        return EMPTY;
                    }),
                    filter((newValues) => !!newValues),
                    tap((newValues) => {
                        this.isEditing = false;
                        this.trainingMaterialsSubject.next(newValues);
                    })
                )
                .subscribe();
        } else {
            this.isEditing = false;
        }
    }

    pushToArrayIfModified(currentValue: PcsConfiguration, controlValue: string) {
        if (currentValue?.value != controlValue) {
            currentValue.value = controlValue;
            return currentValue;
        }
        return undefined;
    }

    getDisplayValueForTrainingMaterialConfig(currentValues: PcsConfiguration[], key: PcsConfigurationKeyEnum) {
        const value = getPcsConfigurationByKey(currentValues, key)?.value;

        return value !== '' ? value : 'Unspecified';
    }
}
