<ng-container *ngIf="user$ | async as user">
    <mds-dialog [dialogTitle]="'Site Contact'">
        <mds-dialog-content>
            <div class="o-grid">
                <div class="o-grid__row" *ngIf="data?.name && data?.name !== 'N/A'">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Name</h5>
                            <span>{{ data?.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Email Address</h5>
                            <span>{{ data?.emailAddress }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Status</h5>
                            <span>{{ data?.status }}</span>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row" *ngIf="data?.status !== 'Active'">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Expire Time</h5>
                            <span *ngIf="data?.status === 'Requested Approval'">{{
                                data?.accessRequest?.expiredBy | dateFormat: DateFormat.dateOnly : 'utc'
                            }}</span>
                            <span *ngIf="data?.status === 'Invited'">{{
                                data?.invitation?.expiredBy | dateFormat: DateFormat.dateOnly : 'utc'
                            }}</span>
                        </div>
                    </div>
                </div>
                <div
                    class="o-grid__row"
                    *ngIf="
                        data?.status !== 'Requested Approval' ||
                        (data?.status === 'Requested Approval' && !user.isSuperAdmin)
                    "
                >
                    <div class="o-grid__field">
                        <mds-textarea
                            [formCtrl]="reasonControl"
                            id="reasonField"
                            [cdkAutosizeMinRows]="5"
                            [cdkAutosizeMaxRows]="5"
                            label="Reason"
                            placeholder="Type here..."
                            floatLabel="always"
                            appearance="outline"
                            [autoSize]="true"
                        >
                        </mds-textarea>
                    </div>
                </div>
            </div>
        </mds-dialog-content>
        <mds-dialog-actions [align]="'center'">
            <ng-container *ngIf="data.status === 'Invited'" [ngTemplateOutlet]="Invited"></ng-container>
            <ng-container
                *ngIf="data.status === 'Requested Approval'"
                [ngTemplateOutlet]="RequestApproval"
            ></ng-container>
            <ng-container *ngIf="data.status === 'Active'" [ngTemplateOutlet]="Active"></ng-container>
            <mds-button
                [id]="'cancel-btn'"
                mat-button
                mat-dialog-close
                label="Close"
                variant="outline"
                (clickEvent)="close()"
            ></mds-button>
        </mds-dialog-actions>
    </mds-dialog>

    <ng-template #Invited>
        <mds-button
            [id]="'reinviteBtn'"
            variant="primary"
            color="primary"
            (clickEvent)="reinvite()"
            label="Reinvite"
        ></mds-button>
        <mds-button
            [id]="'revokeBtn'"
            variant="primary"
            color="warn"
            (clickEvent)="revokeInvitation()"
            label="Revoke"
        ></mds-button>
    </ng-template>

    <ng-template #RequestApproval>
        <mds-button
            *ngIf="user?.isSuperAdmin"
            [id]="'grantAccessBtn'"
            variant="primary"
            color="primary"
            (clickEvent)="grantRejectAccessRequest()"
            label="Grant/Reject Access"
        ></mds-button>
        <mds-button
            *ngIf="!user?.isSuperAdmin"
            [id]="'revokeAccessBtn'"
            variant="primary"
            color="warn"
            (clickEvent)="revokeAccessRequest()"
            label="Revoke"
        ></mds-button>
    </ng-template>

    <ng-template #Active>
        <mds-button
            [id]="'revokeBtn'"
            variant="primary"
            color="warn"
            (clickEvent)="revokeAccessGrant()"
            label="Revoke"
        ></mds-button>
    </ng-template>
</ng-container>
