import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InputChange } from '@models/event-objects/input-change';
import { ClinTrakDataStateService } from '@services/state-management/clintrak-data-state.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { emailValidator, maxLengthValidator } from '@utility/utility.validators';
import { filter, iif, map, Observable, switchMap, tap } from 'rxjs';
@Component({
    selector: 'medpace-crc-invite-card',
    templateUrl: './crc-invite-card.component.html',
    styleUrls: ['./crc-invite-card.component.scss'],
})
export class CRCInviteCardComponent implements OnInit {
    @Input() isEditing: boolean;
    @Output() inputChangeEvent = new EventEmitter<InputChange>();
    @Input() parentFormGroup: FormGroup;

    crcEmailControl: FormControl<string> = new FormControl<string>('', [
        Validators.required,
        maxLengthValidator(150),
        emailValidator,
    ]);
    loaderSpinner: boolean = true;

    siteCrcEmail$: Observable<string> = this.siteStateService.getSite().pipe(
        takeUntilDestroyed(),
        filter(Boolean),
        tap((site) => {
            this.setPrimaryCrcEmail(site.primaryCrcEmailToInvite);
            this.loaderSpinner = false;
        }),
        map((site) => {
            return site.primaryCrcEmailToInvite;
        })
    );

    clinTraCrcEmail$: Observable<string> = this.clinTrakDataStateService.getStudySiteData().pipe(
        takeUntilDestroyed(),
        tap((clinTrakSiteData) => {
            this.setPrimaryCrcEmail(clinTrakSiteData.primaryCrcEmail);
            this.loaderSpinner = false;
        }),
        map((clinTrakSiteData) => {
            return clinTrakSiteData.primaryCrcEmail;
        })
    );

    public readonly crcEmail$ = this.activeRoute.url.pipe(
        switchMap((url) =>
            iif(
                () => {
                    let isUpdate = url.filter((x) => x.path === 'edit').length !== 0;
                    return isUpdate;
                },
                this.siteCrcEmail$,
                this.clinTraCrcEmail$
            )
        )
    );

    constructor(
        private clinTrakDataStateService: ClinTrakDataStateService,
        private siteStateService: SiteStateService,
        private activeRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.parentFormGroup.addControl('primaryCrcControl', this.crcEmailControl);
    }

    setPrimaryCrcEmail(primaryCrcEmail: string) {
        this.parentFormGroup.controls.primaryCrcControl.setValue(primaryCrcEmail);
        this.inputChangeEvent.emit({ target: 'primaryCrcEmailToInvite', value: primaryCrcEmail });
    }

    emitChange: (value: any, target: any) => void = (value: any, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };
}
