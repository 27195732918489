import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AccessLevelTypes } from '@app/enums/AccessLevelTypes';
import {
    CreateEditAnnouncementDialogComponent,
    CreateEditAnnouncementDialogData,
    CreateEditAnnouncementDialogResult,
} from '@components/molecules/forms/announcements/create-edit-announcement-dialog/create-edit-announcement-dialog.component';
import { AccessGroupDTO } from '@models/accessGroupDTO';
import {
    AccessRequestDto,
    AccessRequestTableModel,
    UserAccessRequestLogDto,
    UserAccessRequestLogTableModel,
} from '@models/accessRequest';
import { Announcement, AnnouncementStatus } from '@models/announcement';
import { AnnouncementFilterOption } from '@models/announcementFilterOption';
import { MomentFormat } from '@models/date-format';
import { IUserTableModel } from '@models/interfaces/iUser';
import { PcsConfigurationKeyEnum } from '@models/pcsConfiguration';
import { Site } from '@models/site';
import { Study } from '@models/study';
import { UserAdminViewModel, UserTableModel } from '@models/user';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { AnnouncementService } from '@services/announcement/announcement.service';
import { PcsConfigurationService } from '@services/pcs-configuration/pcs-configuration.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { AccessGroupStateService } from '@services/state-management/access-group-state.service';
import { AccessRequestStateService } from '@services/state-management/access-request-state.service';
import { mapAccessTypeName } from '@utility/accessGroup-helper';
import { PersistentFormControl } from '@utility/persistent-forms';
import { exportToExcel } from '@utility/utility';
import { equalOrMoreThanTodayOnlyDateValidator, maxLengthValidator } from '@utility/utility.validators';
import { Moment, utc } from 'moment';
import * as moment from 'moment/moment';
import {
    BehaviorSubject,
    catchError,
    filter,
    map,
    merge,
    Observable,
    of,
    ReplaySubject,
    share,
    startWith,
    Subject,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs';
import { UserStateService } from '../../services/state-management/user-state.service';
import { MedpaceAccessRequestActionModalComponent } from './modals/access-request-action-modal/access-request-action-modal.component';
import { SendInvitationModalComponent } from './modals/user-management/send-invitation-modal/send-invitation-modal.component';
import { UpdateUserManagementModalComponent } from './modals/user-management/update-user-management-modal/update-user-management-modal.component';
import { MedpaceUserOperationAlertModalComponent } from './modals/user-operation-alert-modal/user-operation-alert-modal.component';
@Component({
    templateUrl: './super-admin-dashboard.component.html',
    styleUrls: ['./super-admin-dashboard.component.scss'],
})
export class MedpaceSuperAdminDashboardComponent implements OnDestroy {
    private componentDestroyed$: Subject<boolean> = new Subject();
    accessRequestcolumns: string[] = [
        'displayName',
        'userEmail',
        'accessType',
        'assignedStudy',
        'assignedSite',
        'createDate',
        'expireDate',
    ];
    accessRequestcolumnMap: any[] = [
        { header: 'Name', name: 'displayName', isNested: false, nest: undefined },
        { header: 'Email Address', name: 'userEmail', isNested: false, nest: undefined },
        { header: 'Access Type', name: 'accessType', isNested: false, nest: undefined },
        { header: 'Affected Studies', name: 'assignedStudy', isNested: false, nest: undefined },
        { header: 'Affected Sites', name: 'assignedSite', isNested: false, nest: undefined },
        { header: 'Submitted', name: 'createDate', isNested: false, nest: undefined },
        { header: 'Expired', name: 'expireDate', isNested: false, nest: undefined },
    ];
    accessRequestLogcolumns: string[] = [
        'userEmail',
        'accessType',
        'assignedStudy',
        'assignedSite',
        'requestTimestamp',
        'justification',
        'reason',
        'processedBy',
        'status',
    ];
    accessRequestLogcolumnMap: any[] = [
        { header: 'Email Address', name: 'userEmail', isNested: false, nest: undefined },
        { header: 'Access Type', name: 'accessType', isNested: false, nest: undefined },
        { header: 'Create Date', name: 'requestTimestamp', isNested: false, nest: undefined },
        { header: 'Affected Studies', name: 'assignedStudy', isNested: false, nest: undefined },
        { header: 'Affected Sites', name: 'assignedSite', isNested: false, nest: undefined },
        { header: 'Justification', name: 'justification', isNested: false, nest: undefined },
        { header: 'Reason', name: 'reason', isNested: false, nest: undefined },
        { header: 'Processed By', name: 'processedBy', isNested: false, nest: undefined },
        { header: 'Access status', name: 'status', isNested: false, nest: undefined },
    ];
    columns: string[] = [
        'displayName',
        'emailAddress',
        'userStatus',
        'accessType',
        'assignedStudy',
        'assignedSite',
        'loginTypes',
    ];
    columnMap: any[] = [
        { header: 'Name', name: 'displayName', isNested: false, nest: undefined },
        { header: 'Email Address', name: 'emailAddress', isNested: false, nest: undefined },
        { header: 'Status', name: 'userStatus', isNested: false, nest: undefined },
        { header: 'Access Type', name: 'accessType', isNested: false, nest: undefined },
        { header: 'Assigned Study', name: 'assignedStudy', isNested: false, nest: undefined },
        { header: 'Assigned Site', name: 'assignedSite', isNested: false, nest: undefined },
        { header: 'Has Local Account', name: 'loginTypes', isNested: false, nest: undefined },
    ];
    users: any[];
    isPendingARExpanded: boolean = false;
    filteredUsers: UserTableModel[];
    acessRequestActionDialog: MatDialogRef<MedpaceAccessRequestActionModalComponent>;
    accessGroups$: Observable<AccessGroupDTO[]> = this.accessGroupStateService.getAccessGroups();
    tableUsers: UserTableModel[];
    tableUsers$: Observable<UserTableModel[]> = this.userStateService
        .getUserAdminViewModelArray()
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((users) => users !== null && users?.length > 0),
            map((users) => {
                return this._mapToUserTableModel(users);
            })
        )
        .pipe(
            tap((result) => {
                this.tableUsers = result;
            })
        );

    accessRequests$: Observable<AccessRequestTableModel[]> = this.accessRequestStateService.getAccessRequests().pipe(
        takeUntil(this.componentDestroyed$),
        filter((ar) => ar !== null),
        map((ar) => {
            if (ar.length === 0) return null;
            ar.map((x) => (x.accessGroup.displayName = mapAccessTypeName(x.accessGroup.displayName)));
            return this._mapToAccessRequestTableModel(ar);
        })
    );

    accessRequestLogs: UserAccessRequestLogTableModel[];
    accessRequests: AccessRequestTableModel[];
    filteredAccessRequestLogs: UserAccessRequestLogTableModel[];
    loadAccessRequestLogSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    accessRequestLogs$: Observable<UserAccessRequestLogTableModel[]> = this.accessRequestStateService
        .getAccessRequestLogs()
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((ar) => ar !== null),
            map((ar) => {
                if (ar.length === 0) return null;
                ar.map((x) => (x.accessLevel.displayName = mapAccessTypeName(x.accessLevel.displayName)));
                return this._mapToAccessRequestLogTableModel(ar);
            }),
            tap((result) => {
                this.accessRequestLogs = result;
                this.filteredAccessRequestLogs = result;
            })
        );

    setAccessRequestLogsData() {
        this.accessRequestStateService.setAccessRequestLogs();
    }

    @Output() modalOpenEvent = new EventEmitter();
    constructor(
        private accessGroupStateService: AccessGroupStateService,
        public dialog: MatDialog,
        private userStateService: UserStateService,
        private announcementService: AnnouncementService,
        private matDialog: MatDialog,
        studyService: AdminStudyServices,
        private siteService: AdminSiteServices,
        private snackbarService: SnackbarService,
        private accessRequestStateService: AccessRequestStateService,
        private activatedRoute: ActivatedRoute,
        private pcsConfigurationService: PcsConfigurationService
    ) {
        this.userStateService.setUserAdminViewModelArray();
        studyService.getActiveStudies().subscribe((studies) => this.studiesSubject.next(studies));

        this.activatedRoute.queryParamMap
            .pipe(
                tap((result) => {
                    const params = JSON.parse(result?.get('params'));
                    const openParam = !!params ? params['open'] : null;

                    if (openParam?.includes('pendingAccessRequest')) {
                        this.isPendingARExpanded = true;
                    }
                })
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.accessRequestStateService.clearStore();
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    doClickRow(event: UserTableModel): void {
        this.dialog.open(UpdateUserManagementModalComponent, {
            data: {
                userEmail: event.emailAddress,
            },
            autoFocus: false,
            width: '500px',
            disableClose: true,
        });
    }

    doClickAccessRequestRow(event: AccessRequestTableModel): void {
        this.acessRequestActionDialog = this.dialog.open(MedpaceAccessRequestActionModalComponent, {
            data: event,
            autoFocus: false,
            width: '420px',
            disableClose: true,
        });

        this.acessRequestActionDialog.afterClosed().subscribe((result: number) => {
            this.accessRequestStateService.removeFromState(result);
            this.userStateService.setUserAdminViewModelArray();
            this.setAccessRequestLogsData();
        });
    }

    openInvitationDialog(): void {
        this.dialog.open(SendInvitationModalComponent, {
            autoFocus: false,
            width: '500px',
            disableClose: true,
        });
    }

    openUserCreationDialog(): void {
        this.dialog.open(MedpaceUserOperationAlertModalComponent, {
            autoFocus: false,
            width: '400px',
            disableClose: true,
        });
    }

    downloadUserSpreadsheet(): void {
        let headers: string[] = this.columnMap.map((row) => {
            return row.header;
        });
        if (this.filteredUsers) exportToExcel<UserTableModel>(this.filteredUsers, 'UserTable', headers, this.matDialog);
        else exportToExcel<UserTableModel>(this.tableUsers, 'UserTable', headers, this.matDialog);
    }

    downloadAccessRequestLogSpreadsheet(): void {
        let headers: string[] = this.accessRequestLogcolumnMap.map((row) => {
            return row.header;
        });

        headers.unshift('Id');

        if (this.filteredAccessRequestLogs) {
            exportToExcel<UserAccessRequestLogTableModel>(
                this.getListWithFilteredColumns(this.filteredAccessRequestLogs),
                'Access Request Log',
                headers,
                this.matDialog
            );
        } else
            exportToExcel<UserAccessRequestLogTableModel>(
                this.getListWithFilteredColumns(this.accessRequestLogs),
                'Access Request Log',
                headers,
                this.matDialog
            );
    }

    getListWithFilteredColumns(accessRequestLogs: UserAccessRequestLogTableModel[]): UserAccessRequestLogTableModel[] {
        const filteredColumns = accessRequestLogs;

        filteredColumns.forEach((x) => {
            delete x.sourceIPV4Address;
            delete x.processedByIPV4Address;
        });

        return filteredColumns;
    }

    filterData(event) {
        this.filteredAccessRequestLogs = event;
    }

    getFilteredData(filteredData): void {
        this.filteredUsers = filteredData;
    }

    private _mapToUserTableModel(users: UserAdminViewModel[]): UserTableModel[] {
        let tableModel: UserTableModel[] = [];
        for (let i = 0; i < users.length; i++) {
            tableModel.push(
                new UserTableModel(<IUserTableModel>{
                    displayName: `${users[i]?.firstName} ${users[i]?.lastName}`,
                    emailAddress: users[i]?.emailAddress,
                    userStatus: users[i]?.isActive ? 'Active' : 'Disabled',
                    accessType: users[i]?.accessGroups[0]?.displayName,
                    assignedStudy: users[i]?.studies?.map((study) => study?.protocol).join(', '),
                    assignedSite: users[i]?.sites?.map((site) => site?.institutionName).join(', '),
                    loginTypes: users[i]?.hasLocalAuthentication ? 'Yes' : 'No',
                })
            );
        }
        return tableModel;
    }

    private _mapToAccessRequestTableModel(accessRequests: AccessRequestDto[]): AccessRequestTableModel[] {
        let tableModel: AccessRequestTableModel[] = [];
        for (let i = 0; i < accessRequests.length; i++) {
            tableModel.push({
                id: accessRequests[i]?.id,
                displayName: `${accessRequests[i]?.firstName} ${accessRequests[i]?.lastName}`,
                userEmail: accessRequests[i]?.userEmail,
                accessType: this.getAccessTypeLabel(accessRequests[i]),
                createDate: moment(accessRequests[i]?.requestTimestamp)
                    .utc(true)
                    .format(MomentFormat.dateTime24)
                    .toUpperCase(),
                expireDate: moment(accessRequests[i]?.expiredBy).utc(true).format(MomentFormat.dateOnly).toUpperCase(),
                assignedStudy: accessRequests[i]?.affectedStudiesProtocol,
                assignedSite: accessRequests[i]?.affectedSitesInstitutionName,
                justification: accessRequests[i]?.justification,
                phoneNumber: accessRequests[i]?.phoneNumber,
            });
        }
        return tableModel;
    }

    private getAccessTypeLabel(accessRequest: AccessRequestDto): string {
        switch (accessRequest.accessGroup.id) {
            case AccessLevelTypes.CRC:
                return this.getCrcLevelLabel(accessRequest?.isPrimary);
            case AccessLevelTypes.NoRole:
                return '';
            default:
                return accessRequest?.accessGroup?.displayName;
        }
    }

    private getCrcLevelLabel(isPrimary: boolean | null): string {
        return isPrimary != null ? (isPrimary ? 'Primary CRC' : 'Supporting CRC') : '';
    }

    private _mapToAccessRequestLogTableModel(
        accessRequestLogs: UserAccessRequestLogDto[]
    ): UserAccessRequestLogTableModel[] {
        let tableModel: UserAccessRequestLogTableModel[] = [];
        for (let i = 0; i < accessRequestLogs.length; i++) {
            tableModel.push({
                id: accessRequestLogs[i]?.id,
                userEmail: accessRequestLogs[i]?.userEmail,
                accessType: accessRequestLogs[i]?.accessLevel?.displayName,
                requestTimestamp: moment(accessRequestLogs[i]?.requestTimestamp)
                    .format(MomentFormat.dateTime24)
                    .toUpperCase(),
                assignedStudy: accessRequestLogs[i]?.affectedStudiesProtocol.join(', '),
                assignedSite: accessRequestLogs[i]?.affectedSitesNumber.join(', '),
                justification: accessRequestLogs[i]?.justification,
                reason: accessRequestLogs[i]?.reason,
                sourceIPV4Address: accessRequestLogs[i]?.sourceIPV4Address,
                processedByIPV4Address: accessRequestLogs[i]?.processedByIPV4Address,
                processedBy: accessRequestLogs[i]?.processedBy,
                status: accessRequestLogs[i]?.status,
            });
        }
        return tableModel;
    }

    public static createAnnouncementFormGroup(
        announcement: Announcement,
        studies: Study[],
        sites: Site[]
    ): CreateEditAnnouncementDialogData['formGroup'] {
        return new FormGroup({
            title: new FormControl<string>(announcement.title, [Validators.required, maxLengthValidator(200)]),
            description: new FormControl<string>(announcement.description, [
                Validators.required,
                maxLengthValidator(500),
            ]),
            status: new FormControl<AnnouncementStatus>(announcement.status, [Validators.required]),
            expirationDate: new PersistentFormControl<Moment>(announcement.expireTimestamp, [
                Validators.required,
                equalOrMoreThanTodayOnlyDateValidator,
            ]),
            filterOptions: new FormGroup({
                studies: new FormControl<Study[]>(studies),
                sites: new FormControl<Site[]>(sites),
            }),
        });
    }
    private studiesSubject = new ReplaySubject<Study[]>();

    private openAnnouncementSubject = new Subject<Announcement>();
    loadAnnoucementDataSubject = new BehaviorSubject<boolean>(false);

    protected openAnnouncement(announcement: Announcement) {
        this.openAnnouncementSubject.next(announcement);
    }
    protected createAnnouncement() {
        this.openAnnouncementSubject.next(<Announcement>{
            title: '',
            description: '',
            status: AnnouncementStatus.Active,
            expireTimestamp: utc().add(3, 'days'),
            id: 0,
            filterOptions: [],
        });
    }
    private openAnnouncement$ = this.openAnnouncementSubject.pipe(
        withLatestFrom(this.studiesSubject),
        switchMap(([announcement, allStudies]) => {
            const selectedSitesIds = announcement.filterOptions?.reduce(
                (acc, curr) => acc.concat(curr.siteIds),
                <number[]>[]
            );

            const selectedSites$ =
                selectedSitesIds && selectedSitesIds.length !== 0
                    ? this.siteService.getSites(selectedSitesIds)
                    : of(<Site[]>[]);
            return selectedSites$.pipe(map((selectedSites) => [announcement, allStudies, selectedSites] as const));
        }),
        switchMap(([announcement, allStudies, sites]) =>
            this.matDialog
                .open<
                    CreateEditAnnouncementDialogComponent,
                    CreateEditAnnouncementDialogData,
                    CreateEditAnnouncementDialogResult
                >(CreateEditAnnouncementDialogComponent, {
                    data: <CreateEditAnnouncementDialogData>{
                        announcement,
                        allStudies,
                        formGroup: MedpaceSuperAdminDashboardComponent.createAnnouncementFormGroup(
                            announcement,
                            allStudies.filter((study) =>
                                announcement.filterOptions.some((option) => option.studyId === study.id)
                            ),
                            sites
                        ),
                        isRemovable: announcement.id !== 0,
                    },
                })
                .afterClosed()
        ),
        filter((result) => !result.cancel),
        share() // share the source between createAnnouncement$, updateAnnouncement$ and removeAnnouncement$, without it 3 dialogs will be shown
    );
    private createAnnouncement$ = this.openAnnouncement$.pipe(
        filter((result) => !result.remove && result.announcement.id === 0),
        switchMap((result) => {
            result.announcement.title = result.value.title;
            result.announcement.description = result.value.description;
            result.announcement.status = result.value.status;
            result.announcement.expireTimestamp = result.value.expirationDate;
            result.announcement.filterOptions = result.value.filterOptions.studies.map(
                (study) =>
                    <AnnouncementFilterOption>{
                        studyId: study.id,
                        siteIds: result.value.filterOptions.sites
                            .filter((site) => site.studyId === study.id)
                            .map((site) => site.id),
                    }
            );
            return this.announcementService.create(result.announcement);
        }),
        tap((response) => {
            if (response.ok) this.snackbarService.openInfoSnackbar('Announcement Created');
        }),
        catchError((err: Error, source$: Observable<any>) => {
            console.error('Error while creating announcement:', err);
            return source$;
        })
    );
    private updateAnnouncement$ = this.openAnnouncement$.pipe(
        filter((result) => !result.remove && result.announcement.id > 0),
        filter((result) => {
            // prevent api call if nothing changed
            const existingAnnouncement = result.announcement;
            const formAnnouncement = <Announcement>{
                ...existingAnnouncement,
                title: result.value.title,
                description: result.value.description,
                status: result.value.status,
                expireTimestamp: result.value.expirationDate,
                filterOptions: result.value.filterOptions.studies.reduce(
                    (acc, study) => [
                        ...acc,
                        <AnnouncementFilterOption>{
                            studyId: study.id,
                            siteIds: result.value.filterOptions.sites
                                .filter((site) => site.studyId === study.id)
                                .map((site) => site.id),
                        },
                    ],
                    new Array<AnnouncementFilterOption>()
                ),
            };
            if (JSON.stringify(existingAnnouncement) === JSON.stringify(formAnnouncement)) {
                this.snackbarService.openInfoSnackbar('Nothing changed in the announcement');
                return false;
            } else return true;
        }),
        switchMap((result) => {
            result.announcement.title = result.value.title;
            result.announcement.description = result.value.description;
            result.announcement.status = result.value.status;
            result.announcement.expireTimestamp = result.value.expirationDate;
            result.announcement.filterOptions = result.value.filterOptions.studies.map(
                (study) =>
                    <AnnouncementFilterOption>{
                        studyId: study.id,
                        siteIds: result.value.filterOptions.sites
                            .filter((site) => site.studyId === study.id)
                            .map((site) => site.id),
                    }
            );
            return this.announcementService.update(result.announcement.id, result.announcement);
        }),
        tap((response) => {
            if (response.ok) this.snackbarService.openInfoSnackbar('Announcement Updated');
        }),
        catchError((err: Error, source$: Observable<any>) => {
            console.error('Error while updating announcement:', err);
            return source$;
        })
    );
    private removeAnnouncement$ = this.openAnnouncement$.pipe(
        filter((result) => result.remove),
        switchMap((result) => this.announcementService.delete(result.announcement.id)),
        tap((response) => {
            if (response.ok) this.snackbarService.openInfoSnackbar('Announcement Removed');
        }),
        catchError((err: Error, source$: Observable<any>) => {
            console.error('Error while removing announcement:', err);
            return source$;
        })
    );
    protected announcements$ = merge(this.createAnnouncement$, this.updateAnnouncement$, this.removeAnnouncement$).pipe(
        startWith(null), // will get mapped to getAll()
        switchMap((_) => this.announcementService.getAll())
    );

    loadTrainingMaterialsSubject = new BehaviorSubject<boolean>(false);

    trainingMaterials$ = this.pcsConfigurationService.getPcsConfigurationsByKeys([
        PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK,
        PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_REGISTERED_LINK_LABEL,
        PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK,
        PcsConfigurationKeyEnum.TRAINING_DOCUMENTS_SITE_ACTIVE_LINK_LABEL,
    ]);

    loadPreRegisteredSitesSubject = new BehaviorSubject<boolean>(false);
}
