import { MatSort } from '@angular/material/sort';

export function customSort(data: any[], sort: MatSort) {
    if (data?.length == 0 || data?.length === undefined) return data;
    if (sort.active === 'siteNumber' && sort.direction) {
        data = data.sort((a, b) => {
            const property = sort.active;
            const aValue = getSortingValue(a[property]);
            const bValue = getSortingValue(b[property]);

            const comparision = customSortComparator(aValue, bValue);
            return sort.direction === 'asc' ? comparision : -comparision;
        });
    } else if (sort.active?.toLowerCase().includes('date') || sort.active?.toLowerCase().includes('timestamp')) {
        data.sort((a, b) => {
            const property = sort.active;
            const direction = sort.direction === 'asc' ? 1 : -1;

            const aValue = a[property];
            const bValue = b[property];

            if (typeof aValue === 'string' || typeof bValue === 'string') {
                const aDate = aValue === 'N/A' ? new Date(0) : new Date(aValue);
                const bDate = bValue === 'N/A' ? new Date(0) : new Date(bValue);
                if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) return (aDate < bDate ? -1 : 1) * direction;
                else return getDefaultComparator(property, direction)(a, b);
            } else return getDefaultComparator(property, direction)(a, b);
        });
    } else {
        data.sort(getDefaultComparator(sort.active, sort.direction === 'asc' ? 1 : -1));
    }
    return data;
}
export function getDefaultComparator(sortByPropertyName: string, direction: 1 | -1) {
    return (a, b) => {
        const aValue = a[sortByPropertyName];
        const bValue = b[sortByPropertyName];

        return (aValue < bValue ? -1 : 1) * direction;
    };
}

export function customSortComparator(a: string, b: string) {
    const aIsNumber = !isNaN(Number(a));
    const bIsNumber = !isNaN(Number(b));

    if (aIsNumber && bIsNumber) {
        return Number(a) - Number(b);
    } else if (aIsNumber) {
        return -1;
    } else if (bIsNumber) return 1;
    else {
        return a < b ? -1 : 1;
    }
}

export function getSortingValue(item: string): string {
    return item.replace('-', '');
}
