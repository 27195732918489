import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportBugModalComponent } from '@app/admin/manage/modals/report-bug/report-bug.component';
import { ReportBugResultViewModel } from '@models/error-details';
import { EnvConfigurationService } from '@services/env/env-configuration.service';
import { ErrorService } from '@services/error-handling/error-service';
import { IdleTimeService } from '@services/idle-time/idle-time.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { filter, map, switchMap, tap } from 'rxjs';

@Component({
    selector: 'medpace-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class MedpacefooterComponent implements OnInit {
    logo = './../../../../assets/img/Medpace_Partner-logo_web.webp';
    currentYear: number;
    isMobile: boolean;
    appVersion: string;
    hashVersion: string;
    emailAddress: string = 'PCS@medpace.com';

    timer$ = this.idleTimeService.timer$.pipe(
        map((remainingTimeInSeconds) => {
            let minutes = Math.floor(remainingTimeInSeconds / 60)
                .toString()
                .padStart(2, '0');
            let seconds = (remainingTimeInSeconds % 60).toString().padStart(2, '0');
            return `${minutes}:${seconds}`;
        })
    );

    constructor(
        private breakpointObserver: BreakpointObserver,
        private envService: EnvConfigurationService,
        private idleTimeService: IdleTimeService,
        private reportErrorService: ErrorService,
        public dialog: MatDialog,
        public snackbarService: SnackbarService
    ) {}

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
        this.isMobile = this.breakpointObserver.isMatched(Breakpoints.Handset);
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
            this.isMobile = result.matches;
        });
        this.appVersion = this.envService.configuration.version;
        this.hashVersion = this.envService.configuration.hashVersion;
        if (!this.envService.configuration.version) {
            console.error(
                'Could not find application version in the config.json file. It might suggest that config.json contains unexpected data and is worth looking into.'
            );
        }
    }

    sendErrorsDetails() {
        const dialogRef = this.dialog.open<ReportBugModalComponent, any, ReportBugResultViewModel>(
            ReportBugModalComponent,
            {
                disableClose: true,
                width: '500px',
            }
        );

        dialogRef
            .afterClosed()
            .pipe(
                filter((errorDescription) => errorDescription.save),
                switchMap((errorDescription) =>
                    this.reportErrorService.reportBug({
                        description: errorDescription.description,
                        url: errorDescription.url,
                        errorDetails: this.reportErrorService.getErrors(),
                    })
                ),
                tap((result) => {
                    if (result.ok) {
                        this.snackbarService.openInfoSnackbar('Error report has been successfully sent.');
                        this.reportErrorService.clearErrorStateService();
                    } else {
                        this.snackbarService.openInfoSnackbar(
                            'An error occurred while sending the report. Please send information about the error using other forms of communication'
                        );
                    }
                })
            )
            .subscribe();
    }
}
