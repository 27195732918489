import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SiteStatusEnum } from '@app/enums/site-status.enum';
import { SiteRegisterModalComponent } from '@components/molecules/modals/site-register-modal/site-register-modal.component';
import { SiteSummaryViewModel } from '@models/site';
import { User } from '@models/user';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { UserService } from '@services/user/user.service';
import { MdsOptionGeneric } from '@utility/utility';
import { filter, map } from 'rxjs';
export interface SiteTableViewModel {
    siteNumber: string;
    displayName: string;
    name: string;
    pilastName: string;
    site: SiteSummaryViewModel;
    country: string;
    status: string;
    statusId: number;
    clintrakStatus: string;
    protocol: string;
}
export interface VM {
    sites: SiteTableViewModel[];
    countries: MdsOptionGeneric<string>[];
}
interface ColumnMap {
    name: string;
    header: string;
    field: string;
}
@Component({
    selector: 'medpace-site-table',
    templateUrl: './site-table.component.html',
})
export class MedpaceSiteTableComponent {
    constructor(
        private router: Router,
        private adminSites: AdminSiteServices,
        private siteStateService: SiteStateService,
        private userService: UserService,
        private dialog: MatDialog
    ) {
        this.siteStateService.setSites();
    }
    public columns: string[] = ['siteNumber', 'displayName', 'name', 'pilastName', 'clintrakStatus', 'status'];
    public columnMap: ColumnMap[] = [
        { name: 'siteNumber', header: 'Site #', field: 'siteNumber' },
        { name: 'pilastName', header: 'pi last name', field: 'pilastName' },
        { name: 'name', header: 'institution name', field: 'name' },
        { name: 'displayName', header: 'active primary crc', field: 'displayName' },
        { name: 'clintrakStatus', header: 'clinTrak status', field: 'clintrakStatus' },
        { name: 'status', header: 'status', field: 'status' },
        { name: 'country', header: 'COUNTRY', field: 'country' },
    ];
    private isAdmin: boolean = false;
    public currentUser$ = this.userService.getUser();
    private sitesVM$ = this.siteStateService.getSites().pipe(filter((x) => !!x));
    public adminVM$ = this.sitesVM$.pipe(
        map((siteSummaryVMs) => {
            this.isAdmin = true;
            return this.createVM(siteSummaryVMs, this.columns, this.columnMap);
        })
    );
    public crcVM$ = this.sitesVM$.pipe(
        map((siteSummaryVMs) => this.createVM(siteSummaryVMs, this.columns, this.columnMap))
    );

    public onRowClick(siteVM: SiteTableViewModel, user: User) {
        if (siteVM.statusId === SiteStatusEnum.PreRegistered && siteVM.site.primaryCRCId == user.id && !this.isAdmin) {
            this.dialog.open(SiteRegisterModalComponent, {
                width: '80%',
                maxWidth: 800,
                minWidth: 360,
                height: '80%',
                disableClose: true,
                data: [siteVM.site.id],
            });
        } else {
            this.router.navigate([`studies/${siteVM.site.studyId}/sites/${siteVM.site.id}`]);
        }
    }
    private createVM(siteSummaryVMs: SiteSummaryViewModel[], columns: string[], columnMap: ColumnMap[]) {
        let sites = new Array<SiteTableViewModel>();
        let countries = new Set<string>();
        siteSummaryVMs
            .filter((site) => !!site.country)
            .forEach((site) => {
                sites.push(<SiteTableViewModel>{
                    displayName: this.getPrimaryCrcLabel(site),
                    pilastName: site.piLastName,
                    name: site.institutionName,
                    siteNumber: site.siteNumber,
                    site: site,
                    country: site.country,
                    clintrakStatus: site.clinTrakStatus ? site.clinTrakStatus : 'N/A',
                    status: site.status.statusName,
                    statusId: site.status.statusId,
                    protocol: site.protocol,
                });
                countries.add(site.country);
            });
        return <VM>{
            sites: sites,
            countries: Array.from(countries).map(
                (country) =>
                    <MdsOptionGeneric<string>>{
                        value: country,
                        viewValue: country,
                    }
            ),
        };
    }
    // custom searchFn passed to table to enable searching by supportCRCs' names
    protected siteSearchFn(row: any, field: any, value: string) {
        // searched value is compared against all fields (column values) of every row of the table
        // it's difficult to tell from the implementation of table.component.ts how this works,
        // but fields here will be either string (column value) or SiteSummaryViewModel
        if (typeof field === 'string') return field.toString().toLowerCase().includes(value.toLowerCase());
        // SiteSummaryViewModel, but `instanceof SiteSummaryViewModel` returns false,
        // probably it's a anonymous object that looks the same, so I'm checking if
        // its characteristic properties exist, like primaryCRCFullName
        else if (field['primaryCRCFullName']) {
            const primaryCRCFullName: string = field['primaryCRCFullName'];
            const supportingCRCsFullNames: string[] = field['supportingCRCsFullNames'];
            // check if primaryCRC's or any supportingCRC's name contains search phrase
            return (
                [primaryCRCFullName, ...supportingCRCsFullNames].findIndex((crc) =>
                    crc.toLowerCase().includes(value.toLowerCase())
                ) !== -1
            );
        }
        // fallback string search
        else return field.toString().toLowerCase().includes(value.toLowerCase());
    }

    getPrimaryCrcLabel(site: SiteSummaryViewModel): string {
        return site.primaryCRCFullName ? site.primaryCRCFullName : 'N/A';
    }
}
