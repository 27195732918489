/* istanbul ignore file */
import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TravelType } from '@app/enums/travel-type.enum';
import { EditableCardMode } from '@components/atoms/medpace-editable-card/medpace-editable-card.component';
import { TrainHistoryData } from '@models/history-data/trainHistoryData';
import { Caregiver, getCaregiverFullName } from '@models/patient';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { FormGroupValueOf } from '@utility/utility';
import { SingleTrainTripFormGroup } from './train-details/train-details.component';

export class CaregiverTrainTripFormGroup extends PersistentFormGroup<{
    details: PersistentFormGroup<{
        bookIdenticalTrip: PersistentFormControl<boolean>;
        trainDetails: SingleTrainTripFormGroup;
    }>;
    caregiver: PersistentFormControl<Caregiver>; // helper property, not visible in UI
}> {
    public static create(value: FormGroupValueOf<CaregiverTrainTripFormGroup>) {
        return new CaregiverTrainTripFormGroup({
            caregiver: new PersistentFormControl(value.caregiver),
            details: new PersistentFormGroup({
                trainDetails: SingleTrainTripFormGroup.create(value.details.trainDetails, true),
                bookIdenticalTrip: new PersistentFormControl(value.details.bookIdenticalTrip),
            }),
        });
    }
}
export class TrainRequestDetailsFormGroup extends PersistentFormGroup<{
    patientTrainTripDetails: SingleTrainTripFormGroup;
    caregivers: FormArray<CaregiverTrainTripFormGroup>;
}> {
    public static create(value: FormGroupValueOf<TrainRequestDetailsFormGroup>): TrainRequestDetailsFormGroup {
        return new TrainRequestDetailsFormGroup({
            patientTrainTripDetails: SingleTrainTripFormGroup.create(value.patientTrainTripDetails, false),
            caregivers: new FormArray(
                value.caregivers.map((caregiverDetails) => CaregiverTrainTripFormGroup.create(caregiverDetails))
            ),
        });
    }
}
@Component({
    selector: 'medpace-train-request-details',
    templateUrl: './train-request-details.component.html',
    styleUrls: ['./train-request-details.component.scss'],
})
export class TrainRequestDetailsComponent {
    public TravelType = TravelType;
    @Input() mode: EditableCardMode;
    @Input() formGroup: TrainRequestDetailsFormGroup;
    @Input() accompanyingCaregivers: CaregiverTrainTripFormGroup[];
    @Input() historyData: TrainHistoryData[];
    getCaregiverFullName(caregiver: Caregiver) {
        return getCaregiverFullName(caregiver);
    }
    hasAnyNonDisabledCaregiverControls() {
        return (
            this.formGroup.controls.caregivers.controls.filter((caregiverControl) => !caregiverControl.disabled)
                .length !== 0
        );
    }
}
