import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SiteStatusEnum } from '@app/enums/site-status.enum';
import { dateFormatPipe } from '@app/pipes/datepipe';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { ClinTrakStudySiteViewModel } from '@models/clintrak';
import { DateFormat } from '@models/date-format';
import { StudyPatientViewModel } from '@models/patient';
import { getSiteSIVDate } from '@models/site';
import { StudyUtilizationData } from '@models/utilizationReporting/fullStudyUtilizationReportData';
import { ClinTrakDataService } from '@services/clintrak/clintrak-data.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import {
    createExcelSheet,
    dateArrayTransform,
    dateTransform,
    downloadExcelFile,
    fullNameSearchFn,
    prepareDialogForNoDataToExport,
} from '@utility/utility';
import { saveAs } from 'file-saver';
import { utc } from 'moment';
import { BehaviorSubject, forkJoin, map, Observable, of, Subject, switchMap, take, tap } from 'rxjs';
import { Site, SiteSummaryViewModel } from 'src/app/models/site';
import { GroupService } from 'src/app/services/group/group.service';
import { ContentMapping } from '../../models/event-objects/content-mapping';
import { Study } from '../../models/study';
import { AdminSiteServices } from '../../services/admin/admin-site.service';
import { AdminStudyServices } from '../../services/admin/admin-study.sevice';
import { ReportServices } from '../../services/reports/reports.service';
export interface PatientElement {
    id: number;
    firstName: string;
    lastName: string;
    patientNum: string;
    pcsId: string;
    siteId: number;
    site: Site;
    isPatientDeleted: boolean;
}

@Component({
    selector: '',
    templateUrl: './study-overview.component.html',
    styleUrls: ['./study-overview.component.scss'],
})
export class MedpaceStudyOverviewComponent implements OnInit, OnDestroy {
    study: Study;
    filteredSites: any[];
    pendingSites: ClinTrakStudySiteViewModel[];
    patients: MatTableDataSource<PatientElement, MatPaginator> = new MatTableDataSource([]);

    site: Site;
    isAdmin: boolean;

    tableConent: any[];
    countries: any[];
    clinTrakCountries: MdsOption[] = [];
    columns: string[] = [
        'siteNumber',
        'displayName',
        'name',
        'pilastName',
        'clinTrakStatus',
        'activationDate',
        'sivDate',
    ];

    columnsClinTrakSites: string[] = [
        'studySiteCenterId',
        'primaryCrc',
        'siteName',
        'primaryInvestigator',
        'statusType',
        'countryName',
    ];

    clinTrakSitesColumnMap: any[] = [
        { header: 'Site #', name: 'studySiteCenterId' },
        { header: 'pi last name', name: 'primaryInvestigator' },
        { header: 'Institution Name', name: 'siteName' },
        { header: 'primary crc', name: 'primaryCrc' },
        { header: 'country', name: 'countryName' },
        { header: 'clinTrak status ', name: 'statusType' },
    ];

    patientsColumnsToDisplay: string[] = [];
    columnMap: any[] = [
        { header: 'Site #', name: 'siteNumber' },
        { header: 'pi last name', name: 'pilastName' },
        { header: 'Institution Name', name: 'name' },
        { header: 'active primary crc', name: 'displayName' },
        { header: 'country', name: 'country' },
        { header: 'region', name: 'region' },
        { header: 'clinTrak status', name: 'clinTrakStatus' },
        { header: 'activation date ', name: 'activationDate' },
        { header: 'siv date ', name: 'sivDate' },
    ];
    infoColumnMap: ContentMapping[] = [
        { name: 'Sponsor', field: 'sponsor', isNested: false, nest: undefined },
        { name: 'Protocol', field: 'protocol', isNested: false, nest: undefined },
        { name: 'Project ID', field: 'studyCode', isNested: false, nest: undefined },
    ];

    items1 = [
        {
            label: 'I am an item in Menu 1',
            icon: '',
            disabled: false,
            disableRipple: false,
        },
        {
            label: 'I am a disabled item in Menu 1',
            icon: '',
            disabled: true,
            disableRipple: false,
        },
        {
            label: 'I am a disabled ripple item in Menu 1',
            icon: '',
            disabled: false,
            disableRipple: true,
        },
    ];

    patientColumns: string[] = ['id', 'pcsId', 'firstName', 'lastName', 'lastVisitDate', 'action'];
    private readonly formatOptions: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZoneName: 'short',
    });
    @ViewChild(MatSort) mySort = new MatSort();

    loadSitesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loadUtilizationDataSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loadPendingSitesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    pendingSites$ = this.clinTrakDataService.getPendingSitesByStudyId(this.active.snapshot.params.id).pipe(
        tap((result) => {
            result
                .map((x) => x.countryName)
                .forEach((country) => {
                    if (!this.clinTrakCountries.some((c) => c.value === country)) {
                        this.clinTrakCountries.push({
                            value: country,
                            viewValue: country,
                        });
                    }
                });
        }),
        take(1)
    );

    studyData$ = this.studyStateServices.getStudy().pipe(tap((study) => (this.study = study)));

    studySitesData$ = this.siteServices.getStudySites(this.active.snapshot.params.id).pipe(
        map((sites) => {
            let filterData = [];
            sites.forEach((site) => {
                site.clinTrakStatus = site.clinTrakStatus ?? 'N/A';
                site['name'] = site['institutionName'];
                site['pilastName'] = site['piLastName'];
                site['displayName'] = this.getPrimaryCrcLabel(site);
                site['activationDate'] =
                    site.activationDate && site.activationDate != 'N/A'
                        ? dateTransform(this.datePipe, site.activationDate)
                        : site.activationProjectedDates.length > 0
                          ? dateArrayTransform(this.datePipe, site.activationProjectedDates)?.join('\r\n')
                          : 'N/A';
                site['sivDate'] = getSiteSIVDate(this.datePipe, site);

                if (filterData.indexOf(site['country']) == -1) {
                    filterData.push(site['country']);
                    let x = {
                        value: site['country'],
                        viewValue: site['country'],
                    };
                    this.countries.push(x);
                }
            });

            return sites;
        })
    );

    studyUtilizationData$: Observable<StudyUtilizationData> = this.siteServices
        .getStudySitesWithtRequests(this.active.snapshot.params.id)
        .pipe(
            map((sites) => {
                let utilizationData = <StudyUtilizationData>{
                    sitesScoped: this.study.numSites,
                    sitesOnboarded: sites.length,
                    sitesUtilizingPCS: sites.filter((site) => site.numPatients > 0).length,
                    patientsScoped: this.study.numPatients,
                    patientProfilesCreated: sites.reduce((prev, curr) => prev + curr.numPatients, 0),
                    patientsUtilizingPCS: sites.reduce((prev, curr) => prev + curr.numPatientsUtilizingPCS, 0),
                };

                return utilizationData;
            })
        );

    constructor(
        private studyServices: AdminStudyServices,
        private studyStateServices: StudyStateService,
        private siteServices: AdminSiteServices,
        private reportServices: ReportServices,
        private datePipe: dateFormatPipe,
        private router: Router,
        private active: ActivatedRoute,
        private groups: GroupService,
        private dialog: MatDialog,
        private clinTrakDataService: ClinTrakDataService
    ) {}
    private componentDestroyed$: Subject<boolean> = new Subject();

    ngOnInit(): void {
        this.studyStateServices.setStudy(this.active.snapshot.params.id);
        this.countries = [];

        this.groups.getGroupsFromStorage().subscribe((result) => {
            this.isAdmin = this.groups.isUserAdmin(result);
        });

        if (!this.isAdmin) {
            forkJoin([
                this.studyServices.getStudy(this.active.snapshot.params.id),
                this.studyServices.getStudyPatients(this.active.snapshot.params.id),
                this.siteServices.getUserSiteByStudy(this.active.snapshot.params.id),
            ])
                .pipe(
                    switchMap(([study, patients, sites]) => {
                        let selectedSite = sites[0];
                        return forkJoin([
                            of(study),
                            of(selectedSite),
                            this.siteServices.getSitePatientRequestSummaries(selectedSite.id).pipe(
                                map((counts) => {
                                    const remapped = patients.map((i: StudyPatientViewModel) => {
                                        const p: PatientElement = {
                                            id: i.id,
                                            firstName: i.firstName,
                                            lastName: i.lastName,
                                            patientNum: i.patientNum,
                                            pcsId: i.patientPseudoId,
                                            siteId: i.siteId,
                                            isPatientDeleted: i.isPatientDeleted,
                                            site: sites.find((site) => site.id === i.siteId),
                                        };

                                        if (counts) {
                                            let countSummary = counts.find((c) => c.patientId === p.id);

                                            if (countSummary) {
                                                p['lastVisitDate'] = countSummary.lastVisitDate
                                                    ? this.datePipe
                                                          .transform(
                                                              utc(countSummary.lastVisitDate).toDate(),
                                                              DateFormat.dateOnly,
                                                              null
                                                          )
                                                          .toUpperCase()
                                                    : '';
                                            }
                                        }
                                        return p;
                                    });
                                    return remapped;
                                })
                            ),
                        ]);
                    }),
                    take(1)
                )
                .subscribe(([study, site, patients]) => {
                    this.study = study;
                    this.site = site;
                    this.patients = new MatTableDataSource(patients);
                    this.patients.sort = this.mySort;
                    this.patients.filterPredicate = (data: any, filterValue: string) => {
                        const keys = Object.keys(data);
                        let searchMatchFilter = [];
                        keys.forEach((d) => {
                            if (data[d]) {
                                searchMatchFilter.push(fullNameSearchFn(data, data[d], filterValue));
                            }
                        });
                        return searchMatchFilter.indexOf(true) != -1 ? true : false;
                    };
                });
        }
    }

    doClickSite(event: any): void {
        this.router.navigate([`studies/${this.study.id}/sites/${event.id}`], { state: event });
    }

    getPrimaryCrcLabel(site: SiteSummaryViewModel): string {
        if (site.primaryCRCFullName) return site.primaryCRCFullName;

        return 'N/A';
    }

    doClickPendingSite(event: any): void {
        let navigationExtras = <NavigationExtras>{
            state: {
                event: event,
            },
        };
        this.router.navigate([`studies/${this.study.id}/newsite`], navigationExtras);
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    addNewSite(): void {
        this.router.navigate([`studies/${this.study.id}/newsite`]);
    }

    downloadExpenseReport(): void {
        this.reportServices
            .getStudyExpenses(this.study.id)
            .pipe(take(1))
            .subscribe((data) => {
                if (data.byteLength == 0) {
                    prepareDialogForNoDataToExport(this.dialog);
                } else {
                    let blob = new Blob([data], { type: 'application/csv' });
                    saveAs(blob, 'export.csv');
                }
            });
    }

    goToDetails(): void {
        this.router.navigate([`studies/${this.study.id}/edit`]);
    }

    addNewPatient(): void {
        this.router.navigate([`studies/${this.study.id}/sites/${this.site.id}/patients/new`]);
    }

    isAddPatientButtonVisible(): boolean {
        return !this.isAdmin && this.site?.status?.statusId === SiteStatusEnum.Active;
    }

    goToPatient(event: any) {
        this.router.navigate([`studies/${this.study.id}/sites/${event.siteId}/patients/${event.id}`], { state: event });
    }
    newTravelRequest(event: any) {
        this.router.navigate([`studies/${this.study.id}/sites/${event.siteId}/patients/${event.id}/newrequest`], {
            state: event,
            queryParams: { 'payment-travel': 'travel' },
        });
    }
    newPaymentRequest(event: any) {
        this.router.navigate([`studies/${this.study.id}/sites/${event.siteId}/patients/${event.id}/newrequest`], {
            state: event,
            queryParams: { 'payment-travel': 'payment' },
        });
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;

        this.patients.filter = filterValue.trim().toLowerCase();
    }

    /** A hook allowing to override the `title` of the row (a tooltip displayed when user hovers the mouse over the row) in certain situations */
    GetTitle(row: PatientElement): string | undefined {
        if (row.isPatientDeleted) {
            return `This row cannot be accessed because related data was deleted`;
        }
        return undefined;
    }

    canCreatePaymentRequest(site: Site): boolean {
        return site.services.stipend || site.services.reimbursement;
    }
    canCreateTravelRequest(site: Site): boolean {
        return site.services.travel;
    }
    showNoDataModal() {
        prepareDialogForNoDataToExport(this.dialog);
    }
    downloadPatientLastRequestSubmittedAtReport() {
        this.reportServices
            .getPatientLastRequestSubmittedAtReportData(this.active.snapshot.params.id)
            .subscribe((data) => {
                if (!data || data.length === 0) {
                    this.showNoDataModal();
                    return;
                }
                const dataWithConvertedDates = data.map((d) => ({
                    ...d,
                    lastRequestSubmittedAt: d.lastRequestSubmittedAt
                        ? this.formatOptions.format(new Date(d.lastRequestSubmittedAt))
                        : '',
                }));
                const dataRenamed = dataWithConvertedDates.map((d) => ({
                    'Patient Number': d.patientNumber,
                    'Last Request Submitted Date': d.lastRequestSubmittedAt,
                }));
                const patientLastRequestSubmittedDateSheet = createExcelSheet(dataRenamed);
                downloadExcelFile(
                    [{ workSheet: patientLastRequestSubmittedDateSheet, name: 'Last Request Submitted Date' }],

                    'Patient Last Requests Report'
                );
            });
    }
    downloadCRCLastLoginReport() {
        this.reportServices.getCRCLastLoginReportData(this.active.snapshot.params.id).subscribe((data) => {
            if (!data || data.length === 0) {
                this.showNoDataModal();
                return;
            }
            const dataWithConvertedDates = data.map((d) => ({
                ...d,
                lastLoginDate: d.lastLoginDate ? this.formatOptions.format(new Date(d.lastLoginDate)) : '',
            }));
            const dataRenamed = dataWithConvertedDates.map((d) => ({
                'CRC Full Name': d.crcFullName,
                'Email Address': d.emailAddress,
                'Phone Number': d.phoneNumber,
                'Last Login Date': d.lastLoginDate,
            }));
            const crcLastLoginSheet = createExcelSheet(dataRenamed);
            downloadExcelFile(
                [{ workSheet: crcLastLoginSheet, name: 'CRC Last Login' }],

                'CRC Last Login Report'
            );
        });
    }
    downloadFullStudyUtilizationReport() {
        this.reportServices.getFullStudyUtilizationReportData(this.active.snapshot.params.id).subscribe((data) => {
            if (
                !data ||
                (data.patientProfilesCreatedData.length === 0 &&
                    data.sitesOnboardedData.length === 0 &&
                    !data.studyUtilizationData)
            ) {
                this.showNoDataModal();
                return;
            }
            const studyUtilizationData = [data.studyUtilizationData];
            const studyUtilizationDataRenamed = studyUtilizationData.map((d) => ({
                'Sites Scoped': d.sitesScoped,
                'Sites Onboarded': d.sitesOnboarded,
                'Sites Utilizing PCS': d.sitesUtilizingPCS,
                'Patients Scoped': d.patientsScoped,
                'Patient Profiles Created': d.patientProfilesCreated,
                'Patients Utilizing PCS': d.patientsUtilizingPCS,
            }));
            const utilizationSheet = createExcelSheet(studyUtilizationDataRenamed);

            const sitesOnboardedData = data.sitesOnboardedData;
            const sitesOnboardedDataRenamed = sitesOnboardedData.map((d) => ({
                'Site Number': d.siteNumber,
                'Primary CRC': d.primaryCRCFullName,
                Protocol: d.protocol,
                'Institution Name': d.institutionName,
                'PI Last Name': d.piLastName,
                'Has Patient Profiles?': d.hasPatientProfiles ? 'Yes' : 'No',
            }));
            const sitesOnboardedSheet = createExcelSheet(sitesOnboardedDataRenamed);

            const patientProfilesCreatedData = data.patientProfilesCreatedData;
            const patientProfilesCreatedDataRenamed = patientProfilesCreatedData.map((d) => ({
                'Patient Number': d.patientNumber,
                'Site Number': d.siteNumber,
                'Has Active Or Completed Requests?': d.hasActiveOrCompletedRequests ? 'Yes' : 'No',
            }));
            const patientProfilesCreatedSheet = createExcelSheet(patientProfilesCreatedDataRenamed);

            downloadExcelFile(
                [
                    { workSheet: utilizationSheet, name: 'Study Utilization' },
                    { workSheet: sitesOnboardedSheet, name: 'Sites Onboarded' },
                    { workSheet: patientProfilesCreatedSheet, name: 'Patient Profiles Created' },
                ],

                'Full Study Utilization Report'
            );
        });
    }
    downloadSiteRequestCountReport() {
        this.reportServices.getSiteRequestCountReportData(this.active.snapshot.params.id).subscribe((data) => {
            if (!data || data.length === 0) {
                this.showNoDataModal();
                return;
            }
            const dataRenamed = data.map((d) => ({
                'Site Number': d.siteNumber,
                'In Process Requests': d.inProcessRequestCount,
                'Pending Approval Requests': d.pendingApprovalRequestCount,
                'Pending Site Review Requests': d.siteReviewRequestCount,
                'Total Requests': d.totalCount,
            }));
            const siteRequestCountSheet = createExcelSheet(dataRenamed);
            downloadExcelFile(
                [{ workSheet: siteRequestCountSheet, name: 'Site Request Count' }],

                'Open Requests Report'
            );
        });
    }
    downloadPatientProfileCreationReport() {
        this.reportServices.getPatientProfileCreationReportData(this.active.snapshot.params.id).subscribe((data) => {
            if (!data || data.length === 0) {
                this.showNoDataModal();
                return;
            }
            const dataWithConvertedDates = data.map((d) => ({
                ...d,
                profileCreatedAt: d.profileCreatedAt ? this.formatOptions.format(new Date(d.profileCreatedAt)) : '',
            }));
            const dataRenamed = dataWithConvertedDates.map((d) => ({
                'Patient Number': d.patientNum,
                'Profile Created At': d.profileCreatedAt,
            }));
            const patientProfileCreation = createExcelSheet(dataRenamed);
            downloadExcelFile(
                [{ workSheet: patientProfileCreation, name: 'Patient Profiles Creation' }],

                'Patient Profiles Creation Report'
            );
        });
    }
}
