import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { utc } from 'moment';
import { BehaviorSubject, distinctUntilChanged, filter, Observable, Subject } from 'rxjs';
import { ErrorDetails, ErrorObject } from '../../models/error-details';
import { ErrorReportRepository } from '../../repositories/error/error-report.repository';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    private readonly errorDetails: BehaviorSubject<ErrorDetails[]> = new BehaviorSubject<ErrorDetails[]>([]);
    private readonly httpErrors = new Subject<HttpErrorResponse>();
    private readonly versionError: Subject<string | null> = new Subject<string | null>();

    constructor(private errorRepository: ErrorReportRepository) {}
    pushHttpError(httpError: HttpErrorResponse) {
        this.httpErrors.next(httpError);
    }
    getHttpErrors() {
        return this.httpErrors.asObservable();
    }
    getErrors(): ErrorDetails[] {
        return this.errorDetails.getValue();
    }

    addError(errorMessage: string, callStack: string) {
        let error: ErrorDetails = {
            createDatetime: utc(Date.now()),
            errorTitle: errorMessage,
            errorCallStack: callStack,
            url: window.location.href,
        };

        this.errorDetails.next([...this.errorDetails.getValue(), error]);
    }

    clearErrorStateService() {
        this.errorDetails.next([]);
    }

    reportBug(error: ErrorObject) {
        return this.errorRepository.sendError(error);
    }

    throwVersionError(errorMessage: string) {
        this.versionError.next(errorMessage);
    }

    clearVersionError() {
        this.versionError.next(null);
    }

    handleVersionError(): Observable<string> {
        return this.versionError.asObservable().pipe(distinctUntilChanged(), filter(Boolean));
    }
}
