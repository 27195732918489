import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedpaceAccessRequestActionModalComponent } from '@app/admin/manage/modals/access-request-action-modal/access-request-action-modal.component';
import { AccessRequestTableModel } from '@models/accessRequest';
import { DateFormat } from '@models/date-format';
import { IAccessActionDto } from '@models/interfaces/iAccessRequest';
import { SiteContact } from '@models/siteContact';
import { AccessRequestService } from '@services/access-request/access-request.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { SiteStateService } from '@services/state-management/site-state.service';
import { UserService } from '@services/user/user.service';
import { maxLengthValidator } from '@utility/utility.validators';
import { EMPTY, finalize, map, switchMap, take, tap } from 'rxjs';
import { DisplayErrorModalComponent } from '../display-error-modal/display-error-modal.component';

@Component({
    selector: 'site-contact-action-modal',
    templateUrl: './site-contact-action-modal.component.html',
})
export class SiteContactActionModalComponent {
    bodyText: string;
    DateFormat = DateFormat;
    reasonControl: FormControl<string> = new FormControl<string>('', [Validators.required, maxLengthValidator(150)]);

    constructor(
        private dialogRef: MatDialogRef<SiteContactActionModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SiteContact,
        private userService: UserService,
        private accessRequestService: AccessRequestService,
        private dialog: MatDialog,
        private snackbarService: SnackbarService,
        private siteStateService: SiteStateService
    ) {}

    user$ = this.userService.getUser();

    close() {
        this.dialogRef.close(true);
    }

    reinvite() {
        if (this.isReasonFieldValid()) {
            this.accessRequestService
                .reInvite(this.data.invitation?.id, this.reasonControl.value)
                .pipe(
                    take(1),
                    finalize(() => {
                        this.close();
                    })
                )
                .subscribe({
                    complete: () =>
                        this.snackbarService.openInfoSnackbar('Invitation message has been sent successfully.'),
                });
        }
    }

    isReasonFieldValid(): boolean {
        if (this.reasonControl.valid) return true;
        else {
            this.reasonControl.markAsTouched();
            return false;
        }
    }

    revokeInvitation() {
        if (this.isReasonFieldValid()) {
            this.accessRequestService
                .revokeInvitation(this.data.invitation?.id, this.reasonControl.value)
                .pipe(
                    take(1),
                    finalize(() => {
                        this.close();
                    })
                )
                .subscribe({
                    complete: () => this.snackbarService.openInfoSnackbar('Invitation has been revoked successfully.'),
                });
        }
    }

    revokeAccessRequest() {
        if (!this.reasonControl.valid) {
            this.reasonControl.markAsTouched();
            return;
        }

        let action = <IAccessActionDto>{
            reason: this.reasonControl.value,
        };

        this.accessRequestService
            .rejectAccess(this.data.accessRequest?.id, action)
            .pipe(
                take(1),
                tap({
                    complete: () => this.dialogRef.close(this.data.accessRequest?.id),
                })
            )
            .subscribe({
                complete: () => this.snackbarService.openInfoSnackbar('Access Request has been rejected.'),
            });
    }

    grantRejectAccessRequest() {
        this.dialogRef.close(false);
        this.dialog
            .open(MedpaceAccessRequestActionModalComponent, {
                autoFocus: false,
                width: '500px',
                disableClose: false,
                data: <AccessRequestTableModel>{
                    id: this.data.accessRequest?.id,
                    accessType: this.data.isPrimary ? 'Primary CRC' : 'Supporting CRC',
                    displayName: this.data.name,
                    userEmail: this.data.emailAddress,
                },
            })
            .afterClosed()
            .pipe(
                take(1),
                switchMap((res) => {
                    if (!res) return EMPTY;
                    return this.siteStateService.getSite();
                }),
                take(1),
                map((site) => site.id),
                tap((siteId) => {
                    this.siteStateService.setSite(siteId);
                    this.siteStateService.setSiteContacts();
                })
            )
            .subscribe();
    }

    revokeAccessGrant() {
        if (this.isReasonFieldValid()) {
            this.accessRequestService
                .revokeAccessGrant(this.data.siteCrc.siteId, this.data.siteCrc.userId, this.reasonControl.value)
                .pipe(take(1))
                .subscribe({
                    error: (e) => {
                        this.dialog.open(DisplayErrorModalComponent, {
                            autoFocus: false,
                            width: '500px',
                            disableClose: false,
                            data: e.error.ExceptionMessage,
                        });
                    },
                    complete: () => {
                        this.snackbarService.openInfoSnackbar('Access Grant has been revoked successfully.');
                        this.dialogRef.close(true);
                    },
                });
        }
    }
}
