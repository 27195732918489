import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { dateFormatPipe } from '@app/pipes/datepipe';
import { dateTransform } from '@utility/utility';

export interface SendReminderResult {
    send: boolean;
}

@Component({
    selector: 'medpace-send-reminder-modal',
    templateUrl: './send-reminder-modal.component.html',
    styleUrls: ['../delete-patient-modal.component.scss'],
})
export class SendReminderModalComponent {
    constructor(
        public dialogRef: MatDialogRef<SendReminderModalComponent, SendReminderResult>,
        @Inject(MAT_DIALOG_DATA) public data: string,
        public datePipe: dateFormatPipe
    ) {}

    dateTransform = dateTransform;

    closeDialog(): void {
        this.dialogRef.close({ send: false });
    }

    sendAndCloseDialog(): void {
        this.dialogRef.close({ send: true });
    }
}
