import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PcsConfiguration } from '@models/pcsConfiguration';
import { SiteService } from '@services/site/site.service';
import { SaveStatusResponse } from '@utility/utility';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PcsConfigurationRepository {
    constructor(
        private client: HttpClient,
        private siteService: SiteService
    ) {}

    getPcsConfigurationsByKeys: (keys: string[]) => Observable<PcsConfiguration[]> = (keys: string[]) => {
        return this.client.get<PcsConfiguration[]>(`${this.siteService.getSiteBaseUrl()}/api/pcsconfigurations`, {
            params: { ids: keys.join(',') },
        });
    };

    createOrUpdatePcsConfigurations: (pcsConfigurations: PcsConfiguration[]) => Observable<SaveStatusResponse> = (
        pcsConfigurations: PcsConfiguration[]
    ) => {
        return this.client.post<SaveStatusResponse>(
            `${this.siteService.getSiteBaseUrl()}/api/pcsconfigurations`,
            pcsConfigurations
        );
    };
}
