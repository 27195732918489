import { Injectable } from '@angular/core';
import { CrcStatusType } from '@app/enums/CrcStatusType';
import { AccessRequestDto, InvitationDto } from '@models/accessRequest';
import { Site, SiteSummaryViewModel } from '@models/site';
import { SiteContact } from '@models/siteContact';
import { User } from '@models/user';
import { AccessRequestService } from '@services/access-request/access-request.service';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import {
    BehaviorSubject,
    Observable,
    ReplaySubject,
    combineLatest,
    filter,
    forkJoin,
    map,
    of,
    switchMap,
    take,
    tap,
} from 'rxjs';

@Injectable()
export class SiteStateService {
    constructor(
        private studyService: AdminStudyServices,
        private siteService: AdminSiteServices,
        private accessRequestService: AccessRequestService
    ) {}

    private readonly site = new BehaviorSubject<Site>(null);
    public readonly site$ = this.site.asObservable();

    private readonly sites = new BehaviorSubject<SiteSummaryViewModel[]>(null);
    public readonly sites$ = this.sites.asObservable();

    private readonly users = new ReplaySubject<User[]>();
    public readonly users$ = this.users.asObservable();

    private readonly siteContacts = new ReplaySubject<SiteContact[]>();
    public readonly siteContacts$ = this.siteContacts.asObservable();

    private getSiteAndCRCs(id: number) {
        return forkJoin({ site: this.siteService.getSite(id), crcs: this.siteService.getSiteCRCs(id) });
    }
    public setSite(id: number) {
        this.getSiteAndCRCs(id)
            .pipe(take(1))
            .subscribe((result) => {
                result.site.siteCRCs = result.crcs;
                this.site.next(result.site);
            });
    }
    public setSiteAndStudyUsers(id: number): void {
        this.getSiteAndCRCs(id)
            .pipe(take(1))
            .subscribe((result) => {
                let siteResult = result.site;
                siteResult.siteCRCs = result.crcs;
                this.studyService.getStudyUsers(siteResult.studyId).subscribe((results) => {
                    results.forEach((u) => {
                        u['displayName'] = `${u.firstName} ${u.lastName}`;
                    });
                    this.users.next(results);
                });

                return this.site.next(siteResult);
            });
    }

    public getSite(): Observable<Site> {
        return this.site$;
    }

    public setNewSite(newSite: Site): void {
        this.site.next(newSite);
    }

    public clearStore() {
        this.site.next(null);
    }

    public setUsers(id: number): void {
        this.studyService
            .getStudyUsers(id)
            .pipe(take(1))
            .subscribe((res) => {
                res.forEach((u) => {
                    u['displayName'] = `${u.firstName} ${u.lastName}`;
                });
                return this.users.next(res);
            });
    }
    public setNewUsers(users: User[]) {
        this.users.next(users);
    }

    public getUsers(): Observable<User[]> {
        return this.users$;
    }

    public setSiteContacts() {
        combineLatest([this.site$, this.baseLogic$])
            .pipe(
                take(1),
                filter((site) => !!site),
                tap(([site, baseLogic]) => {
                    this.siteContacts.next(baseLogic);
                })
            )
            .subscribe();
    }

    public getSiteContacts(): Observable<SiteContact[]> {
        return this.siteContacts$;
    }

    public removeSiteContact(invitationGuid: string) {
        this.siteContacts.pipe(take(1)).subscribe((siteContacts) => {
            let filteredData = siteContacts.filter((x) => x.invitation.id !== invitationGuid);
            this.siteContacts.next(filteredData);
        });
    }

    invitedCrcs$: Observable<InvitationDto[]> = this.site$.pipe(
        filter((site) => !!site),
        switchMap((site) => this.accessRequestService.getInvitationsBySiteId(site.id))
    );

    requestedCrcs$: Observable<AccessRequestDto[]> = this.site$.pipe(
        filter((site) => !!site),
        switchMap((site) => combineLatest([of(site), this.accessRequestService.getAccessRequestsBySiteId(site.id)])),
        filter((ar) => !!ar),
        map(([site, accessRequests]) => accessRequests.filter((ar) => ar.affectedSiteIds?.includes(site.id)))
    );

    activeSiteCrcs$ = this.site$.pipe(
        filter((site) => !!site),
        switchMap((s) => this.siteService.getSiteCRCs(s.id))
    );

    baseLogic$: Observable<SiteContact[]> = combineLatest([
        this.invitedCrcs$,
        this.requestedCrcs$,
        this.activeSiteCrcs$,
    ]).pipe(
        map(([invitedCrcs, requestedCrcs, activeSiteCrcs]) => {
            let siteContacts: SiteContact[] = [];

            if (activeSiteCrcs.length > 0) {
                activeSiteCrcs.forEach((activeCrc) => {
                    siteContacts.push(<SiteContact>{
                        name: `${activeCrc.user.firstName} ${activeCrc.user.lastName}`,
                        emailAddress: activeCrc.user.emailAddress,
                        isPrimary: activeCrc.isPrimary,
                        status: CrcStatusType.Active,
                        siteCrc: activeCrc,
                    });
                });
            }

            if (requestedCrcs.length > 0) {
                requestedCrcs.forEach((requestedCrcs) => {
                    siteContacts.push(<SiteContact>{
                        name: `${requestedCrcs.firstName} ${requestedCrcs.lastName}`,
                        emailAddress: requestedCrcs.userEmail,
                        isPrimary: requestedCrcs.isPrimary,
                        status: CrcStatusType.RequestedApproval,
                        accessRequest: requestedCrcs,
                    });
                });
            }

            if (invitedCrcs.length > 0) {
                invitedCrcs.forEach((invitedCrc) => {
                    siteContacts.push(<SiteContact>{
                        name: `N/A`,
                        emailAddress: invitedCrc.userEmail,
                        isPrimary: invitedCrc.isPrimary,
                        status: CrcStatusType.Invited,
                        invitation: invitedCrc,
                    });
                });
            }
            siteContacts.sort((a, b) => (a.isPrimary ? -1 : 1));
            return siteContacts;
        })
    );

    public setSites(): void {
        this.siteService
            .getSitesVM()
            .pipe(
                take(1),
                tap((sites) => {
                    this.sites.next(sites);
                })
            )
            .subscribe();
    }

    public getSites(): Observable<SiteSummaryViewModel[]> {
        return this.sites$;
    }
}
