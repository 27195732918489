<div>
    <div class="o-grid content">
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h4 class="header">Send reminder</h4>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                Last registration reminder sent to CRC User on {{ data ? dateTransform(datePipe, data) : 'N/A' }}, are
                you sure you want to re-send?
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="flex start">
                    <mds-button
                        id="send-reminder-confirm-button"
                        label="Send"
                        (clickEvent)="sendAndCloseDialog()"
                        variant="primary"
                    >
                    </mds-button>
                    <mds-button [id]="'cancel-btn'" label="Cancel" (clickEvent)="closeDialog()" variant="outline">
                    </mds-button>
                </div>
            </div>
        </div>
    </div>
</div>
