import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReportBugResultViewModel } from '@models/error-details';
import { maxLengthValidator } from '@utility/utility.validators';

@Component({
    selector: 'medpace-report-bug',
    templateUrl: './report-bug.component.html',
    styleUrls: ['../delete-patient-modal.component.scss'],
})
export class ReportBugModalComponent {
    reportBugFromGroup = new FormGroup({
        bugDetails: new FormControl<string>('', [maxLengthValidator(500)]),
    });
    router = inject(Router);

    constructor(public dialogRef: MatDialogRef<ReportBugModalComponent, ReportBugResultViewModel>) {}

    closeDialog(): void {
        this.dialogRef.close({ save: false, description: undefined, url: undefined });
    }

    saveDialog(): void {
        if (this.reportBugFromGroup.valid) {
            const url = window.location.origin + this.router.url;
            this.dialogRef.close({
                save: true,
                description: this.reportBugFromGroup.controls.bugDetails.value,
                url,
            });
        } else this.reportBugFromGroup.markAllAsTouched();
    }
}
