import { FilterBase } from './filter-base';

export class PreRegisteredSitesFilter extends FilterBase {
    protocol: string[];

    constructor() {
        super();
        this.protocol = [];
    }
}
