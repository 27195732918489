import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { dateFormatPipe } from '@app/pipes/datepipe';
import { sortRequestByStatus } from '@app/sorting-helpers/utilities';
import { DateFormat } from '@models/date-format';
import { RequestSummary } from '@models/request';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { exportToCsv } from '@utility/utility';
import { utc } from 'moment';
import { take } from 'rxjs';

@Component({
    selector: 'medpace-request-table',
    templateUrl: './request-table.component.html',
})
export class MedpaceRequestTableViewComponent implements OnInit {
    requestColumns: string[] = [
        'patientNum',
        'patientPseudoId',
        'requestPseudoId',
        'patientName',
        'protocol',
        'visitName',
        'visitDate',
        'requestDate',
        'requestType',
        'status',
        'readyForPayment',
    ];
    tableColumnMap: any[] = [
        { header: 'Patient ID', name: 'patientNum' },
        { header: 'PCS ID', name: 'patientPseudoId' },
        { header: 'Request ID', name: 'requestPseudoId' },
        { header: 'Patient Name', name: 'patientName' },
        { header: 'Protocol', name: 'protocol' },
        { header: 'Visit Name', name: 'visitName' },
        { header: 'Visit Date', name: 'visitDate' },
        { header: 'Request Date', name: 'requestDate' },
        { header: 'Request Type', name: 'requestType' },
        { header: 'Status', name: 'status' },
        { header: 'Payment Ready', name: 'readyForPayment' },
    ];
    requests: RequestSummary[];
    filteredRequests: RequestSummary[];

    fileName: string = 'all_requests';

    csvColumns: string[] = [
        'patientNum',
        'patientPseudoId',
        'requestPseudoId',
        'patientName',
        'protocol',
        'visitName',
        'visitDate',
        'requestDate',
        'requestType',
        'status',
        'readyForPayment',
    ];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private router: Router,
        private requestServices: AdminRequestServices,
        private dialog: MatDialog,
        private datePipe: dateFormatPipe
    ) {}

    ngOnInit(): void {
        this.requestServices
            .getAllRequestSummaries()
            .pipe(take(1))
            .subscribe((result) => {
                result.forEach((r) => {
                    r.visitDate = this.datePipe
                        .transform(utc(r.visitDate).toDate(), DateFormat.dateOnly)
                        ?.toUpperCase();
                    r.requestDate = this.datePipe
                        .transform(utc(r.requestDate).toDate(), DateFormat.dateOnly, null)
                        ?.toUpperCase();
                });

                this.requests = result;
                this.filteredRequests = this.requests;
                sortRequestByStatus(this.requests);
            });
    }

    doClickRow(event: any): void {
        switch (event.requestType) {
            case 'Stipend':
                this.router.navigate(
                    [
                        `studies/${event.studyId}/sites/${event.siteId}/patients/${event.patientId}/requests/${event.requestId}/edit`,
                    ],
                    { state: event, queryParams: { 'payment-travel': 'payment', 'stipend-oop': 'stipend' } }
                );
                break;
            case 'Reimbursement':
                this.router.navigate(
                    [
                        `studies/${event.studyId}/sites/${event.siteId}/patients/${event.patientId}/requests/${event.requestId}/edit`,
                    ],
                    { state: event, queryParams: { 'payment-travel': 'payment', 'stipend-oop': 'out-of-pocket' } }
                );
                break;
            default:
                this.router.navigate(
                    [
                        `studies/${event.studyId}/sites/${event.siteId}/patients/${event.patientId}/requests/${event.requestId}/edit`,
                    ],
                    { state: event, queryParams: { 'payment-travel': 'travel', 'stipend-oop': 'NA' } }
                );
                break;
        }
    }
    filterData(event) {
        this.filteredRequests = event;
    }

    saveAsCsv() {
        if (this.filteredRequests) exportToCsv(this.filteredRequests, this.fileName, this.csvColumns, this.dialog);
        else exportToCsv(this.requests, this.fileName, this.csvColumns, this.dialog);
    }
}
